<template>
    <div>
        <vue-word-cloud
            :words="weightedWords"
            :font-family="fontFamily"
            :color="setWordColor"
            :spacing="letterSpacing"
            :animation-duration="duration"
            :font-size-ratio="letterRatio"/>
    </div>
</template>

<script>
import VueWordCloud from 'vuewordcloud';
export default {
    components: {
        [VueWordCloud.name]: VueWordCloud,
    },
    props: {

        weightedWords: {
            type: Array,
            default: function() {
                return [] 
            }
        }

    },
    
    data() {
        return {
            autoDisplay: {"display": "block"},
            duration:0,
            letterSpacing: Number(0.8),
            letterRatio: Number(1.5),
            fontFamily: "Righteous", //"Quicksand",
            wordColors: ['#FF9845', '#945FB9', '#6DC8EC', '#5B8FF9', '#FF9845','#E86452']

        } 
    },

    methods: {
        setWordColor: function(weight, index) {
            var maxWeight = this.weightedWords[0][1] + 0.1; //make sure maxWeight and minWeight are different 
            var minWeight = this.weightedWords[this.weightedWords.length - 1][1]; 
            var colorIndex = 0; 

            if (maxWeight - minWeight <= 0.1001)
                colorIndex = Math.floor(Math.random() * 5)
            else
                colorIndex = Math.floor( ((weight[1] - minWeight)/ (maxWeight - minWeight)) * 5 )

            return this.wordColors[colorIndex]; 
        }
    }
}
</script>
