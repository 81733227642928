<template>
    <a-col :span="24" style="height: 100%; background-color: white;">
        <a-row style="margin: auto; padding: 8px; max-width: 320px">
            <a-tabs tabPosition="top" defaultActiveKey="phone" @change="generateQrcode">
                <a-tab-pane tab="短信登录" key="phone">
                    <a-form :form="formp" class="login-form">
                        <a-form-item>
                            <a-input placeholder="请输入手机号码" v-decorator="['phone',{ rules: [{ required: true, message: '请输入手机号码！' }]}]">
                                <a-icon slot="prefix" type="phone" />
                            </a-input>
                        </a-form-item>
                        <a-form-item style="margin-bottom: 0px">
                            <a-row>
                                <a-col :span="16">
                                    <a-input placeholder="请输入验证码" v-decorator="['valcode',{ rules: [{ required: true, message: '请输入验证码！' }]}]">
                                    </a-input>
                                </a-col>
                                <a-col :span="8">
                                    <a-button type="primary" @click="sendsms($event)" :disabled="valbutton" style="width: 102px;">获取验证码</a-button>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a @click="" style="color: #1890ff; float: left">
                                    没有收到验证码？
                                </a>
                                <a @click="Register()" style="color: #1890ff; float: right">
                                    现在注册
                                </a>
                            </a-row>
                        </a-form-item>
                        <a-form-item>
                            <a-input placeholder="请输入您收到的邀请码，如果有的话" v-decorator="['invite_code',{ rules: [{ required: false, message: '请输入您收到的邀请码，如果有的话' }]}]">
                            </a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" @click="phoneLogin" class="login-form-button" :disabled="delayLogin">
                                登录
                            </a-button>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
                <a-tab-pane tab="账号登录" key="account">
                    <a-form :form="form" class="login-form">
  
                        <a-form-item>
                            <a-input placeholder="用户名" 
                                v-decorator="[ 'username', {rules: [{ required: true, message: '请输入用户名！' } ]} ]">
                                <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                        </a-form-item>
                        <a-form-item style="margin-bottom: 0px">
                            <a-input type="password" placeholder="密码" 
                                v-decorator="['password', {rules: [{ required: true, message: '请输入密码！'}]} ]">
                                <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                            <a @click="Register()" style="color: #1890ff; float: left">现在注册</a>
                            <a @click="" style="color: #1890ff; float: right">忘记密码</a>
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" @click="Login" class="login-form-button">
                                登录
                            </a-button>
                        </a-form-item>
                        <a-alert :showIcon="false" type="error" message="登录失败，请检查用户名/密码/网络连接" banner :style="{ display: 'none'}" id="Logerror"/>
                    </a-form>
                </a-tab-pane>
                <a-tab-pane tab="扫码登录"  key="qrcode" id="qwe">
                </a-tab-pane>
            </a-tabs>
        </a-row>
    </a-col>
</template>

<script>

export default {
  name: "Login",
  components: {
  },
  data() {
    return {
      Item: "",
      form: this.$form.createForm(this),
      formp: this.$form.createForm(this),
      val: "",
      valbutton: false,
      delayLogin: false
    }
  },
  methods: {
    generateQrcode: function(key) {
      if (key == "qrcode") {
        let b = document;
        let d = b.createElement("iframe");
        let appid = "wxd525f3df2d19dda8";
        let secret = "823325f7ea2749c7ffd1775d7022825a";
        let redirect_uri = "https%3a%2f%2fwww.alonger.cn%2ftag%2fwechat%2flogin";
        let scope = "snsapi_login";
        let random = parseInt(Math.random(100000000) * 1000000).toString();
        let now = (new Date()).valueOf().toString();
        let random_str = now + random;
        let state = this.$crypto.SHA1(random_str).toString();
        let href = "https://www.alonger.cn/static/css/qrlogin.css";
        let e = "https://open.weixin.qq.com/connect/qrconnect?appid=" 
                + appid + "&redirect_uri=" 
                + redirect_uri + "&response_type=code&scope="
                + scope + "&state="
                + state + "&href=" 
                + href + "#wechat_redirect";
        d.src=e,
        d.frameBorder="0",
        d.allowTransparency="true",
        d.scrolling="no",
        d.width="300px",
        d.height="400px";
        var f=b.getElementById('qwe');
        f.innerHTML="",
        f.appendChild(d);
      }
    },

    Register: function () {
      let that = this;
      that.$router.push('/reg');
    },

    sendsms: function(e) {
        let phone = this.formp.getFieldValue('phone');
        if (!phone) {
           this.$message.info("请输入手机号！");
           return;
        }
        if (phone.split("").length != 11) {
            this.$message.info("请输入正确的手机号！");
            return;
        }
        try{
            let d = parseInt(phone);
        } catch(e) {
            this.$message.info("请输入正确的手机号！");
            return;
        }
        let postdata = {
            phone: phone
        }

        this.valbutton = true;
        let that = this;

        let time = 60;
        let timer = setInterval(function(){
            e.target.innerText = time + 's';
            time = time - 1;
            if (time == 0) {
                clearInterval(timer);
                e.target.innerText = "获取验证码";
                that.valbutton = false;
            }
        }, 1000);

        that.post('sms/login', postdata)
         .then(function (data) {
             if (data.ret == 200) {
                 that.val = data.data.code;
                 that.$message.info("验证码发送成功！");
             } else {
                 that.$message.error("验证码发送失败！");
                 that.valbutton = false;
             }
         }).catch(function (e) {
             that.$message.error("验证码发送失败！");
             that.valbutton = false;
         });
    },
    phoneLogin: function() {
        localStorage.clear()
        this.formp.validateFields((err, values) => {
        });
        let phone = this.formp.getFieldValue('phone');
        if (!phone) {
           this.$message.info("请输入手机号！");
           return;
        }
        if (phone.split("").length != 11) {
            this.$message.info("请输入正确的手机号！");
            return;
        }
        try{
            let d = parseInt(phone);
        } catch(e) {
            this.$message.info("请输入正确的手机号！");
            return;
        }

        let valcode = this.formp.getFieldValue('valcode');
        if (!valcode) {
          his.$message.info("请输入验证码！");
        }
        let invite_code = this.formp.getFieldValue('invite_code');
        let username = phone;
        valcode = this.$crypto.SHA256(valcode).toString();
        if (this.val == valcode) {
          let that = this;
          let data = {
            phone: phone,
            verify: valcode, 
            task: "LOGIN_PHONE",
            source: "web"
          }
          if (invite_code) {
            data["invite_code"] = invite_code;
          }
          if (username != that.$store.getters.getUsername()) {
            that.$store.commit('clear');
          }
          that.delayLogin = true;
          let timer = setInterval(function(){
                clearInterval(timer);
                that.delayLogin = false;
          }, 500);

          that.post('token', data)
            .then(function (data) {
              if (data.ret == 200) {
                that.$store.commit('settoken', data.data.access_token);
                if (data.data.username) {
                  that.$store.commit('setusername', data.data.username);
                } else {
                  that.$store.commit('setusername', phone);
                }
                that.$store.commit('setexpired_time', data.data.expired_time);
                that.$store.commit('setid', data.data.agent_key);
                that.$store.commit('setusertype', data.data.type);
                that.$store.commit('setstart', parseInt((new Date()).valueOf()/1000));
                that.$store.commit('setlogin', true);
                that.$store.commit('setagentkey', data.data.agent_key);
                that.$router.push('/redirect');
                that.$emit('login', true);
              } else {
                that.$message.error("认证失败！");
                that.$emit('login', false);
              }
            }).catch(function (e) {
              console.log(e);
                that.$emit('login', false);
              that.$message.error("认证失败！");
            });
        } else {
            this.$message.error("验证码验证失败！");
        }
    },
    Login: function () {
      let that = this;
      this.form.validateFields((err, values) => {
      });
      let username = this.form.getFieldValue('username');
      let password = this.form.getFieldValue('password');
      if (!username || username === '' || !password || password === '') {
        return;
      }
      let data = {
        username: username,
        password: this.$crypto.SHA1(password).toString(),
        task: "LOGIN_ACCT"
      }
      if (username != that.$store.getters.getUsername()) {
        that.$store.commit('clear');
      }
      that.post('token', data)
        .then(function (response) {
          let data = response;
          console.log("Login response:"); 
          console.log(data); 
          if (data.ret == 200) {
            that.$store.commit('settoken', data.data.access_token);
            that.$store.commit('setusername', username);
            if (data.data.img_url) {
              that.$store.commit('setuicon', data.data.img_url);
            }
            that.$store.commit('setexpired_time', data.data.expired_time);
            that.$store.commit('setusertype', data.data.type);
            that.$store.commit('setstart', parseInt((new Date()).valueOf()/1000));
            that.$store.commit('setlogin', true);
            that.$store.commit('setagentkey', data.data.agent_key);
            that.$router.push('/redirect');
            that.$emit('login', true);
          } else {
            that.$emit('login', false);
            document.getElementById("Logerror").style.display ='block';
          }
        }).catch(function (e) {
          that.$message.error("登录失败！");
            that.$emit('login', false);
        });
     }
   }
 }
</script>

<style scoped>
    .login-form-button {
        width: 100%;
    }

    /deep/ .ant-tabs-nav .ant-tabs-tab { 
        margin-right: 0px; 
    }

    .impowerBox .qrcode {width: 200px;}
    .impowerBox .title {display: none;}
    .impowerBox .info {width: 200px;}
    .status_icon {display: none}
    .impowerBox .status {text-align: center;}
</style>
