<template>
    <a-row>
        <a-row v-if="detailTheme == 'default'" :class="'topic-' + theme + '-theme-item-desc'">
            <a-tag v-for="(tag, i) in article.tags" :key="i" 
                   :style="'border: 1px solid rgba(65, 104, 255, ' + (Number(tag.s) > 0.5 ? tag.s : '0.5') + ')'">
                {{tag.t}}
            </a-tag>
            <span style="float: right;">{{article.data_source_name}}</span>
            <span style="margin-right: 5px; margin-left: 5px; float: right;">来自</span>
            <span style="float: right;">
                <time-ago :refresh="1" :datetime="article.pub_time" locale="zh_CN" :long="true"></time-ago>
            </span>
        </a-row>
        <a-row v-if="detailTheme == 'fullScreen'" :class="'topic-' + theme + '-theme-item-desc'">
            <a-tag v-for="(tag, i) in article.tags" :key="i" v-if="i < 5"
                   :style="'font-size: 12px; border: 1px solid rgba(65, 104, 255, ' + (Number(tag.s) > 0.5 ? tag.s : '0.5') + ')'">
                {{tag.t}}
            </a-tag>
        </a-row>
        <!--a-row v-if="detailTheme == 'fullScreen'" :class="'topic-' + theme + '-theme-item-desc'">
            <span style="float: right;">{{article.data_source_name}}</span>
            <span style="margin-right: 5px; margin-left: 5px; float: right;">来自</span>
            <span style="float: right;">
                <time-ago :refresh="1" :datetime="article.pub_time" locale="zh_CN" :long="true"></time-ago>
            </span>
        </a-row-->
        <a v-if="!showDetail" :href="article.url" target="_blank" :class="'topic-' + theme + '-theme-article-desc'">
            <a-row :class="'topic-' + theme + '-theme-article-desc'">
                <p v-if="detailTheme == 'default'" @click="showDetailData" title="显示更多" class="text-hidden" v-html="article.content"></p>
                <p v-if="detailTheme == 'fullScreen'" style="font-size: 14px;" @click="showDetailData" title="显示更多" class="text-hidden" v-html="article.content"></p>
            </a-row>
        </a>
        <a v-else="" :href="article.url" target="_blank" :class="'topic-' + theme + '-theme-article-desc'">
            <a-row :class="'topic-' + theme + '-theme-article-desc'">
                <p v-if="detailTheme == 'default'" @click="showDetailData" title="显示部分" class="text-all" v-html="article.content"></p>
                <p v-else="detailTheme == 'fullScreen'" style="font-size: 14px;" @click="showDetailData" title="显示部分" class="text-all" v-html="article.content"></p>
            </a-row>
            <a-row v-if="article.user_data.length > 0" :class="'topic-' + theme + '-theme-article-ext'">
                <a-row v-if="detailTheme == 'default'" v-for="(user_data, order) in article.user_data" :key="order">
                    <b>{{user_data.name}}：</b>{{user_data.value}}
                </a-row>
                <a-row v-else="detailTheme == 'fullScreen'" style="font-size: 14px;" v-for="(user_data, order) in article.user_data" :key="order">
                    <b>{{user_data.name}}：</b>{{user_data.value}}
                </a-row>
            </a-row>
        </a>
        <a-row v-if="detailTheme == 'fullScreen'" :class="'topic-' + theme + '-theme-item-desc'">
            <span style="float: right;">{{article.data_source_name}}</span>
            <span style="margin-right: 5px; margin-left: 5px; float: right;">来自</span>
            <span style="float: right;">
                <time-ago :refresh="1" :datetime="article.pub_time" locale="zh_CN" :long="true"></time-ago>
            </span>
        </a-row>
    </a-row>
</template>

<script>
import TimeAgo from "vue2-timeago";

export default {
    components: {
        TimeAgo
    },
    props: {
        article: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    
    data() {
        return {
            showDetail: false,
            theme: "default",
            detailTheme: "default"
        } 
    },

    methods: {
        showDetailData: function() {
            this.showDetail = !this.showDetail;
        }
    },

    created() {
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        this.theme = theme;
        var detailTheme =  this.$store.getters.getDetailTheme() 
        this.detailTheme = detailTheme;
    }

}
</script>

<style type="css">

.text-hidden {
    word-break: break-all;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: 0px;
    padding-top: 8px; 
    font-size: 16px;
}

.text-all {
    word-break: break-all;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 0px;
    padding-top: 8px; 
    font-size: 16px;
}

</style>
