<template>
  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <a-select :mode="mode" :placeholder="holder" 
        :defaultValue="defaultvalue" notFoundContent="" @change="Change" @mouseenter="focusEvent" style="width: 100%">
      <a-select-option v-for="(item, index) in items" :key="index" :value="item.value">
        {{item.name}}
      </a-select-option>
    </a-select>
  </a-col>
</template>
<script>

export default {
  name: 'FlatSelector',
  props: {
    span: {
      type: Number,
      default: function() {
        return 12
      }
    },
    xs: {
      type: Number,
      default: function() {
        return 12
      }
    },
    sm: {
      type: Number,
      default: function() {
        return 12
      }
    },
    md: {
      type: Number,
      default: function() {
        return 12
      }
    },
    lg: {
      type: Number,
      default: function() {
        return 12
      }
    },
    xl: {
      type: Number,
      default: function() {
        return 12
      }
    },
    mode: {
      type: String,
      default: function() {
        return 'default'
      },
    },
    holder: {
      type: String,
      default: function() {
        return "请选择"
      }
    },

    defaultvalue: {
      type: Array,
      default: function() {
        return []
      }
    },

    items: {
      type: Array,
      default: function() {
        return []
      }
    },

    field: {
      type: String,
      default: function() {
        return "data"
      }
    }
  },

  methods: {
    focusEvent: function() {
      this.$emit('focus');
    },
    Change: function(value) {
      let result = {};
      if (value && value != '不限') {
        result[this.field] = value
      } else {
        result[this.field] = false
      }
      this.$emit('selected', result, this.field);
    }
  }
}
</script>

<style>
</style>
