<template>
</template>

<script>
  export default {
    name: "Redirect",
    components: {
    },
    data() {
      return {
      }
    },
    methods: {
    },
    created() {
        let isLogin = this.$store.getters.isLogin();
        let global = this.$store.getters.getGlobal();
        console.log('global is ', global);
        console.log('isLogin is ', isLogin);
        let postdata = {
            "task": "GET_TOPIC_CONFIG"
        };
        var that = this;
        document.body.style.width = "100%";
        that.post('topic', postdata)
        .then(function(data) {
            if(data.ret ==200) {
                let dataSource = data.data.data_source;
                that.$store.commit('updateDataSource', data.data.data_source);
                setTimeout(function() {
                    that.$router.push("/");
                }, 200);
            } else {
            }
        }).catch(function(e) {
            console.log(e);
        });
    }
  }
</script>

<style scoped>
</style>
