<template>
  <a-col :span="span" class="mul-line">
    <a-cascader :options="items" :placeholder="holder" expandTrigger="hover" @change="change" class="mul-cas"/>
    <a-tag v-for="(item, index) in select_list" class="mul-tags" color="#108ee9" :key="index" closable @close="changeTags($event, index)">{{item.name}}</a-tag>
  </a-col>
</template>
<script>

export default {
  name: 'ProvinceSelector',
  props: {
    items: { 
      type: Array,
      default: function() {
        return []
      }
    },
    span: {
      type: Number,
      default: function() {
        return 12
      }
    },
    holder: {
      type: String,
      default: function() {
        return ""
      }
    },
    defaultvalue: {
      type: Array,
      default: function() {
        return []
      }
    },
    fields: {
      type: Array,
      default: function() {
        return ["data"]
      }
    }
  },
  data() {
    return {
      select_list: [],
      data: [],
      refresh: 1
    }
  },
  methods: {
    changeTags: function(value, index) {
      console.log(index)
      this.select_list[index].use = false
      let td = []
      for( let j = 0; j < this.select_list.length; j++) {
        if (this.select_list[j].use != false) {
          td.push(this.data[j])
        }
      }

      this.$emit('selected', td, this.fields[0])
      this.refresh += 1
    },
    change: function(value, selected) {
      let result = {};

      for ( let i = 0; i < this.fields.length; i++) {
        if (value[i] && value[i] != '不限') {
          result[this.fields[i]] = value[i];
        }
        if (parseInt(value[i]) >= 0) {
          result[this.fields[i]] = "";
        }
      }

      for ( let i = 0; i < this.fields.length; i++) {
        if (!result[this.fields[i]]) {
          result[this.fields[i]] = false;
        }
      }

      let name = "";
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].label != '不限')
          name = name + selected[i].label + "/"
      }
      let l = name.length
      name = name.substring(0, (l - 1))
      if (name != "") {
        this.data.push(result);
        this.select_list.push({name: name, use: true});
      }

      let td = []
      for( let j = 0; j < this.select_list.length; j++) {
        if (this.select_list[j].use != false) {
          td.push(this.data[j])
        }
      }

      this.refresh += 1
      this.$emit('selected', td, this.fields[0]);
    }
  },
  created() {
    let dt = this.defaultvalue[0]
    try{
      for (let i = 0; i < dt.length; i++) {
        let item = dt[i]
        this.data.push(item)
        let items = this.items
        let name = ""
        for (let k = 0; k < this.fields.length; k++) {
          if (item[this.fields[k]] != false) {
            for (let j = 0; j < items.length; j++) {
               if (item[this.fields[k]] == items[j].value) {
                 name = name + items[j].label + "/"
                 items = items[j].children
                 break
               }
            }
          }
        }
        let l = name.length
        name = name.substring(0, (l - 1))
        if (name != "") {
          this.select_list.push({name: name, use: true});
        }
      }
    } catch (e) {
    }
  }
}
</script>

<style scoped>
  .mul-line {
    text-align: left;
  }
  .mul-cas {
    margin-right: 8px;
  }
  .mul-tags {
    font-size: 13px;
    font-weight: 600;
    margin-top: 4px;
  }
</style>
