<template>
</template>

<script>

export default {
  name: "Wechat",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
    let state = this.$route.query.state;
    if (!state) {
      that.$router.push('/');
      return;
    }
    let that = this;
    let data = {
      task: "GET_WECHAT_USER_TOKEN",
      state: state
    }
    that.post('token', data)
      .then(function (response) {
        let data = response;
        if (data.ret == 200) {
          if (data.data.username != that.$store.getters.getUsername()) {
            that.$store.commit('clear');
          }
          that.$store.commit('settoken', data.data.access_token);
          that.$store.commit('setusername', data.data.username);
          that.$store.commit('setid', data.data.agent_key);
          that.$store.commit('setusertype', data.data.type);
          if (data.data.img_url) {
            that.$store.commit('setuicon', data.data.img_url);
          }
          that.$store.commit('setexpired_time', data.data.expired_time);
          that.$store.commit('setstart', parseInt((new Date()).valueOf()/1000));
          that.$store.commit('setlogin', true);
          that.$store.commit('setagentkey', data.data.agent_key);
          that.$router.push('/uc');
        } else {
          that.$message.error("登录失败！");
          that.$router.push('/login');
        }
      }).catch(function (e) {
        that.$message.error("登录失败！");
        that.$router.push('/login');
    });
   }
 }
</script>

<style>
    .login-form-button {
        width: 100%;
    }
</style>
