<template>
    <a-row>
        <a-col :span="24" style="text-align: center; padding: 0px 8px;">
            <span><b>通知内容</b></span>
        </a-col>
        <a-col :span="24" style="text-align: left; padding: 0px 8px;">
            <a-form-item label="链接地址" style="margin-bottom: 0px;" 
                :labelCol="{ span: 4 }" 
                :wrapperCol="{ span: 20 }" 
            >
                <a :href="article.url" target="_blank">{{article.url}}</a>
            </a-form-item>
        </a-col>
        <a-col :span="24" style="text-align: left; padding: 0px 8px;">
            <a-form-item label="标题" style="margin-bottom: 0px;" 
                :labelCol="{ span: 4 }" 
                :wrapperCol="{ span: 20 }" 
            >
                {{article.title}}
            </a-form-item>
        </a-col>
        <a-col :span="24" style="text-align: left; padding: 0px 8px;">
            <a-form-item label="内容" style="margin-bottom: 0px;" 
                :labelCol="{ span: 4 }" 
                :wrapperCol="{ span: 20 }" 
            >
                <p style="line-height: 32px; max-height: 96px; overflow: hidden;" :title="article.content">{{article.content}}</p>
            </a-form-item>
        </a-col>
        <a-col :span="24" style="text-align: left; padding: 0px 8px;">
            <a-form-item label="接收人" 
                :labelCol="{ span: 4 }" 
                :wrapperCol="{ span: 20 }" 
            >
            <a-input placeholder="请输入联系人邮箱" v-model="email" @change="inputChange"/>
            </a-form-item>
        </a-col>
        <a-col v-if="inputError" :span="24" style="text-align: center; padding: 0px; color: #f5222d;">
            <span>请输入正确的邮箱地址！</span>
        </a-col>
        <a-col :span="24" style="position:absolute;top:-45px;right:0px;">
            <a-form-item label=" " 
                :labelCol="{ span: 4 }" 
                :colon="false"
                :wrapperCol="{ span: 20 }" 
            >
            <a-button type="primary" @click="sendMsg" style="float:right;margin-right:30px;">通知</a-button>
            <a-button type="danger" @click="cancel" style="float: right;margin-right:15px;">取消</a-button>
            </a-form-item>
        </a-col>
    </a-row>
</template>

<script>

export default {
    name: 'Contact',
    props: {
        article: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    data() {
        return {
            email: "",
            inputError: false
        }
    },
    watch: {
        article: function(newvalue, oldvalue) {
            this.article = newvalue;
        }
    },
    methods: {

        sendMsg: function() {
            var myRegExp = /[a-z0-9-]{1,30}@[a-z0-9-]{1,65}.[a-z]{3}/;
            if (!myRegExp.test(this.email)) {
                this.inputError = true;
                return;
            }
            let postdata = {
                task: "SEND_EMAIL",
                url: this.article.url,
                title: this.article.title,
                content: this.article.content,
                email: this.email.trim()
            };
            this.loading = true;
            let that = this;
            that.post("topic", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        that.$message.info("通知发送成功");
                        that.$emit("cancel");
                    } else {
                        that.$message.error("通知发送失败，请重试...");
                    }
                }).catch(function() {
                    that.$message.error("通知发送失败，请重试...");
                });
        },

        inputChange: function() {
            this.inputError = false;
        },

        cancel: function() {
            this.$emit("cancel");
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
