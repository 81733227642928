<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <router-view/>
        </div>
    </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
    name: "app",
    data() {
        return {
            locale: zhCN,
        }
    }
};
</script>

<style>
    #app {
      font-family: 'Avenir', Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      width: 100%;
    }
    ::-webkit-scrollbar{width: 6px;height:6px;background-color: #F1F1F1;}
    ::-webkit-scrollbar-thumb{border-radius:6px;background-color: #C1C1C1;}
    ::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);border-radius:6px;}
</style1>
