<template>
    <div id="articleTrend" :style="autoDisplay"></div>
</template>

<script>
import echarts from 'echarts'

export default {
    name: 'Charts',
    
    props: {

        articleAnalysis: {
            type: Object,
            default: function() {
                return {
                    data: [],
                    time: []
                }
            }
        },

        width:{
            type: Number,
            default: function(){
                return 0;
            }
        }

    },
    
    data() {
        return {
            echarts: echarts,
            autoDisplay: {"display": "block"},
            datatag: [],
            axisLabel: [],
            seriesData: [],
            startPos: 0,
            endPos: 100,
            timeLength: 0
        } 
    },
    mounted() {
        if (this.articleAnalysis.time) {
            this.timeLength = this.articleAnalysis.time.length;
        } else {
            this.timeLength = 1;
        }
        if(this.timeLength>13){
            this.startPos =(1- 13/this.timeLength)*100
        }
        var myChartContainer = document.querySelector('#articleTrend')
        let that = this
        var resizeMyChartContainer = function() {
            //myChartContainer.style.height = window.innerHeight * 0.40 + 'px';
            if (that.width > 500) {
                myChartContainer.style.height = window.innerHeight * 0.40 + 'px';
                myChartContainer.style.width = (that.width * 0.7 - 10) + 'px';
            } else {
                myChartContainer.style.height = window.innerHeight * 0.18 + 'px';
                myChartContainer.style.width = (that.width * 0.99 - 10) + 'px';
            }
        };
        resizeMyChartContainer();
        var myChart = echarts.init(myChartContainer);
        let option = {};
        myChart.setOption(option);
        window.onresize = function () {
            resizeMyChartContainer();
            myChart.resize();
        }
        this.drawTrending();
    },
    watch: {
        articleAnalysis: function(newvalue, oldvalue) {
            this.articleAnalysis = newvalue;
            this.drawTrending();
        }
    },
    methods: {
        drawTrending: function() {
            let that = this;
            if (this.articleAnalysis.time && this.articleAnalysis.time.length > 0) {
                let dom = document.getElementById("articleTrend");
                dom.style.display = "block";
                let myChart = this.echarts.init(document.getElementById("articleTrend"), "light");
                myChart.setOption({              
                    title: {
                        text: "文章数量",
                        x: "center", 
                        y: "5", 
                        textStyle: {
                            fontSize: 18,
                            fontWeight: 'normal',
                            //color: '#666'
                            color: '#aaaaaa'
                        }
                    },
                    color: ['#4168FF','#63DAAB','#657798','#EFBA1C'],
                    dataZoom: [{
                        type: 'inside',
                        xAxisIndex: 0,
                        start: that.startPos,
                        end: 100
                    }],
                    tooltip: {
                        tigger: 'axis',
                        axisPointer: {
                            type: 'line'
                        }
                    },
                    legend: {
                        data: that.articleAnalysis.legend,
                        y: 'bottom',
                        top: "20",
                        right: "20",
                        orient: 'horizontal',
                        textStyle: {
                            color: '#aaaaaa'
                        }
                    },
                    grid: {
                        left: '8%',
                        right: '6%',
                        top: '45',
                        bottom: '15%' 
                    },
                    yAxis: {
                        type: 'value',
                        minInterval: 1,
                        axisLine: {
                            lineStyle: {
                                color: "#aaaaaa"
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data:  that.articleAnalysis.time,
                        axisLabel: {
                            interval: 0,
                            rotate:  20,
                            textStyle: {
                                color: '#aaaaaa'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#aaaaaa"
                            }
                        }
                    },
                    series: that.articleAnalysis.data
                });
            } else {
                let dom = document.getElementById("articleTrend");
                dom.style.display = "none";
            }
        }
    }
}
</script>
