<template>
    <div class="topicEditor">
        <loading :active.sync="loading" :can-cancel="true" color="rgb(47, 185, 248)" :opacity="0.1" :is-full-page="true"/>
        <div id="helpPanel" class="topicEditorHelp">
            <div class="helpTitle">
                <h3>话题编辑器使用说明</h3>
                <img src="../assets/img/return.png" @click="toggleHelp()"/>
            </div>
            <p>话题编辑器可用于完成编辑话题的分类定义以及设置各话题节点的各种属性，如说明、白名单词、黑名单词、样例文档等。</p>
            <p>除下面所列的快捷键外，鼠标右击话题节点可以显示快捷菜单进行更多的操作。鼠标拖动画布可改变脑图的位置，拖动话题节点或改变其层级关系，滚轮缩放可改变显示比例。</p> 
            <h3>脑图导航</h3>
            <table class="helpNavi"></table>
            <h3>话题操作</h3>
            <table class="helpMani"></table>
            <h3>话题编辑</h3>
            <table class="helpEdit"></table>
        </div>
        <div id="editorLayoutSelect" @click="hideSelect" style="display:none;" :class="'layout'+' topic-'+theme+'-theme-topic-bg'"
            @mouseleave="hideSelect">
            <button value="map" @click="changeLayout('map')">
                <p :class="'topic-' + theme + '-theme-item-title'">经典脑图</p>
            </button>
            <span></span>
            <div class="genericName">
                <p :class="'topic-' + theme + '-theme-item-title'">图状</p>
            </div>
            <button value="graph-right" @click="changeLayout('graph-right')">
                <p :class="'topic-' + theme + '-theme-item-title'">子节点在右</p>
            </button>
            <button value="graph-left" @click="changeLayout('graph-left')">
                <p :class="'topic-' + theme + '-theme-item-title'">子节点在左</p>
            </button>
            <button value="graph-bottom" @click="changeLayout('graph-bottom')">
                <p :class="'topic-' + theme + '-theme-item-title'">子节点在下</p>
            </button>
            <button value="graph-top" @click="changeLayout('graph-top')">
                <p :class="'topic-' + theme + '-theme-item-title'">子节点在上</p>
            </button>
            <span></span>
            <div label="树状" class="genericName">
                <p :class="'topic-' + theme + '-theme-item-title'">树状</p>
            </div>
            <button value="tree-right" @click="changeLayout('tree-right')">
                <p :class="'topic-' + theme + '-theme-item-title'">子节点向右摆</p>
            </button>
            <button value="tree-left" @click="changeLayout('tree-left')" class="bottom" >
                <p :class="'topic-' + theme + '-theme-item-title'">子节点向左摆</p>
            </button>
            <span></span>
            <div label="主题颜色" class="genericName">
                <p :class="'topic-' + theme + '-theme-item-title'">脑图主题颜色</p>
            </div>
            <button @click="changeMindColor('default')">
                <p :class="'topic-' + theme + '-theme-item-title'">蓝色系</p>
            </button>
            <button @click="changeMindColor('gold')">
                <p :class="'topic-' + theme + '-theme-item-title'">金色系</p>
            </button>
            <button @click="changeMindColor('green')">
                <p :class="'topic-' + theme + '-theme-item-title'">绿色系</p>
            </button>
            <button @click="changeMindColor('red')">
                <p :class="'topic-' + theme + '-theme-item-title'">红色系</p>
            </button>
            <button @click="changeMindColor('purple')">
                <p :class="'topic-' + theme + '-theme-item-title'">紫色系</p>
            </button>
            <button @click="changeMindColor('pink')">
                <p :class="'topic-' + theme + '-theme-item-title'">粉色系</p>
            </button>
        </div>
        <div :style="editorContainer()">
            <div class="topicMindContainer" @mouseover="makeEditable(true)" @mouseleave="makeEditable(false)" :style="topicMindWidth">
                <topic-mind :topic="topicMind" :width="mindWidth" :height="mindHeight" :editorWidth="editorWidth" 
                    :editable="editable" :draggable="true" :editorShow=true :selectId="nodeId" :preCommand="command" :text="text"
                    @command-cancel="clearCommand" @change="genTopic" @mouseleave="getTmpData" @cancel="ungenTopic" @help="toggleHelp" 
                    :layout="layout" @topicMindLayout="topicMindLayout" :key="editable" :globalMode = "globalMode" :theme = "mindTheme" />
            </div>
            <template>
            <div :class="'selectContainer topic-' + theme + '-theme-editor-tabs'">
            <a-tabs :default-active-key="UIState" :key="UIState" @change="changeUIState">
                <a-tab-pane key="topic" tab="话题属性" @change="changeUIState" style="color: red;">
                <div :style="propertyContainer">
                <div class="topicName">
                    <p :class="'optionLabel topic-' + theme + '-theme-item-title'">话题名称</p>
                    <div :class="'input topic-' + theme + '-theme-input'">
                        <a-input placeholder="请输入话题名称" 
                            v-model="topicName"/>
                        </div>
                </div>
                <div class="topicContent">
                    <p :class="'optionLabel topic-' + theme + '-theme-item-title'">话题说明</p>
                    <div :class="'input topic-' + theme + '-theme-input'" :style="inputSize()">
                        <a-input placeholder="请输入话题说明" v-model="topicContent" type="textarea"/>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="dataContainer">
                    <p :class="'optionLabel topic-' + theme + '-theme-item-title'">数据频道</p>
                    <flat-selector style="padding: 0px 0px 4px 0px; width:75%; height: auto; margin-top: 0px;" 
                        mode="tags"
                        holder="数据频道过滤" 
                        :items="dataSource" 
                        :defaultvalue="topicDataSource"
                        :class="'topic-' + theme + '-theme-input'"
                        field="tds" 
                        :key="refreshSelector"
                        @selected='dataSourceFilterChanged'/>
                    <div class="clear"></div>
                </div>
                <div class="dataContainer" v-if="showClassifyField">
                    <p :class="'optionLabel topic-' + theme + '-theme-item-title'">分类字段</p>
                    <div :class="'dataSelect topic-' + theme + '-theme-item-title'" style="float: left;">
                        <div v-for="(field, index) in classifyField" :key="index">
                            <input type="radio" :id="field" :value="field" v-model="selectedField" 
                                @change="genTopicMind(classifyFieldDic[selectedField])">
                            <label >{{field}}</label> 
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="timeContainer">
                    <p :class="'optionLabel topic-' + theme + '-theme-item-title'">时间范围</p>
                    <div :class="'dataSelect topic-' + theme + '-theme-item-title'">
                        <div class="twoSelect">
                            <input type="radio" id="1w" value="1w" v-model="topicTimeSpan">
                            <label for="1w">一周内</label> 
                            <input type="radio" id="2w" value="2w" v-model="topicTimeSpan">
                            <label for="2w" style="margin-right:91px;">两周内</label>
                        </div>
                        <div class="twoSelect">
                            <input type="radio" id="1m" value="1m" v-model="topicTimeSpan">
                            <label for="1m">一个月</label>
                            <input type="radio" id="all" value="all" v-model="topicTimeSpan">
                            <label for="all">不限</label>
                        </div>
                    </div>
                </div>
                <div class="simContainer">
                    <p :class="'optionLabel topic-' + theme + '-theme-item-title'">相关度</p>
                    <div :class="'shortSelect topic-' + theme + '-theme-item-title'">
                        <input type="radio" id="low" value="0.8" v-model="topicSimScore">
                        <label for="low">一般</label>
                        <input type="radio" id="middle" value="0.9" v-model="topicSimScore">
                        <label for="middle">较高</label>
                        <input type="radio" id="high" value="0.95" v-model="topicSimScore">
                        <label for="high">高</label>
                    </div>
                </div>
                <div class="mapsContainer">
                    <p :class="'optionLabel topic-' + theme + '-theme-item-title'">地图</p>
                    <div :class="'shortSelect topic-' + theme + '-theme-item-title'">
                        <input type="checkbox" id="doc" value="doc" v-model="topicMaps">
                        <label for="doc">文档</label>
                        <input type="checkbox" id="tag" value="tag" v-model="topicMaps">
                        <label for="tag">标签</label>
                        <!--input type="checkbox" id="maps" value="maps" v-model="topicMaps">
                        <label for="maps">词云</label-->
                    </div>
                </div>
                </div>
                </a-tab-pane>
                <a-tab-pane key="node" tab="当前节点">
                <template>
                    <div :class="'topic-' + theme + '-theme-editor-collapse'">
                        <a-collapse accordion @change="panelKey" :activeKey="currentPanelKey">
                            <a-collapse-panel key="desc" header="节点说明">
                                <div :class="'input topic-' + theme + '-theme-input'" :style="inputSize()"><a-input placeholder="请输入一段说明或一组关键词以将选定数据频道中的文档通过语义相关性分类到当前节点" 
                                    v-model="desc" type="textarea" @change="changeNode"/>
                                </div>
                            </a-collapse-panel>
                            <a-collapse-panel key="stopWords" header="黑名单词" v-if="globalMode=='analysis'">
                                <div :class="'input topic-' + theme + '-theme-input'" :style="inputSize()"><a-input placeholder="请输入不希望在地图或词云中出现的标签，多个标签用空格隔开" 
                                    v-model="stopWords" type="textarea" @change="changeNode" />
                                </div>
                                <!--div class="shortSelect"-->
                                <div :class="'shortSelect topic-' + theme + '-theme-item-title'">
                                    <input type="radio" id="all" value="all" v-model="blc" @change="changeNode" style="margin-left:0px;">
                                    <label for="all">全部</label>
                                    <input type="radio" id="sep" value="sep" v-model="blc" @change="changeNode">
                                    <label for="sep">任一</label>
                                </div>
                            </a-collapse-panel>
                            <a-collapse-panel key="keepWords" header="白名单词" v-if="globalMode=='analysis'">
                                <div :class="'input topic-' + theme + '-theme-input'" :style="inputSize()"><a-input placeholder="请输入希望出现在地图或词云中的标签，多个标签用空格隔开" 
                                    v-model="keepWords" type="textarea" @change="changeNode" />
                                </div>
                                <!--div class="shortSelect"-->
                                <div :class="'shortSelect topic-' + theme + '-theme-item-title'">
                                    <input type="radio" id="all" value="all" v-model="wlc" @change="changeNode" style="margin-left:0px;">
                                    <label for="all">全部</label>
                                    <input type="radio" id="sep" value="sep" v-model="wlc" @change="changeNode">
                                    <label for="sep">任一</label>
                                </div>
                            </a-collapse-panel>
                            <a-collapse-panel key="uploadFiles" header="样例文档" v-if="globalMode=='modeling'">
                                <div class="upload">
                                    <div id="new" class="new">
                                            选择文件
                                        <input id="uploadInput" type="file" style="width:100%;" :key="refreshInput" 
                                        class="old" @click="changeFileName" title="">
                                    </div>
                                    <p id="fileName" class="fileName" :key="refreshUpload">未选择任何文件</p>
                                </div>
                                <p>上传文档格式要求为纯文本，文档中的一行将被视为一条样本数据</p>
                                <!--div class="shortSelect"-->
                                <div :class="'shortSelect topic-' + theme + '-theme-item-title'">
                                    <a-tooltip placement="right">
                                        <template slot="title">
                                            <span>替换当前节点的样例文档</span>
                                        </template>
                                        <input type="radio" value="replace" id="replace" v-model="upType">
                                        <label for="replace" style="margin-right:0px;">替换</label>
                                    </a-tooltip>
                                    <input type="radio" value="append" id="append" v-model="upType" style="margin-left:33px;">
                                    <label for="append">添加</label>
                                </div>
                                <p id="msgPanel" :key="refreshUpload"></p>
                                <div class="loadButton">
                                    <button class="ant-btn ant-btn-primary" @click="uploadFile" 
                                        style="border-radius:4px;float:left;">上传</button>
                                    <button v-if="showDownload" class="ant-btn ant-btn-primary" @click="downloadFile" 
                                        style="border-radius:4px;float:left;margin-left:30px;">下载</button>
                                    <a-popconfirm title="你确定删除当前节点的样例文档么？" 
                                            placement="bottom" ok-text="确认" cancel-text="取消" 
                                            @confirm="deleteFile" 
                                            @cancel=""
                                        >
                                        <button v-if="showDownload" class="ant-btn ant-btn-primary"
                                        style="border-radius:4px;float:left;margin-left:30px;">删除</button>
                                    </a-popconfirm>
                                </div>
                            </a-collapse-panel>
                        </a-collapse>
                    </div>
                </template>
                </a-tab-pane>
            </a-tabs>
            </div>
            </template>
        </div>
    </div>
</template>

<script>
import TopicMind from './TopicMind.js'
import { MM } from '@/my-mind/my-mind'
import Loading from 'vue-loading-overlay';
import {FlatSelector} from './Selector'
import 'vue-loading-overlay/dist/vue-loading.css';

export default(
{
    components : {
        TopicMind,
        FlatSelector, 
        Loading
    },

    props: {

        topicId: {
            type: String,
            default : function(){
                return "";
            }
        },

        selectId: {
            type: String,
            default: function() {
                return "";
            }
        },

        preCommand: {
            type: String,
            default: function() {
                return "";
            }
        },

        text: {
            type: String,
            default: function() {
                return "";
            }
        },

        editorWidth:{
            type: Number,
            default: function(){
                return 1920;
            }
        },

        editorHeight:{
            type: Number,
            default: function(){
                return 1920;
            }
        }
    },

    watch: {

        topicId: function(newValue, oldValue) {
            this.topicId = newValue;
        },

        topicMind: function(newValue, oldValue) {
            console.log(newValue)
            /*
            this.topicMind = newValue
            */
        },
/*
        desc: function(){
            var topicMind = this.topicMind 
            this.findNode(topicMind)
        },

        keepWords: function(){
            var topicMind = this.topicMind 
            this.findNode(topicMind)
        },

        stopWords: function(){
            var topicMind = this.topicMind 
            this.findNode(topicMind)
        }
*/
    },

    created(){
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        this.theme = theme;
        this.globalMode = this.$store.getters.getMode()
        this.init(); 
        var width=this.editorWidth
        this.topicMindWidth = {"width": (width - 400) + "px"}
        var height = this.editorHeight-88
        this.propertyContainer = {"width":"100%","height":height+"px","overflow":"auto"}
        this.mindHeight = this.editorHeight - 10;
        this.mindWidth = width-400
        let that = this
        window.addEventListener("message", function(e) {
            if (e.data && e.data.action) {
                switch (e.data.action) {
                    case "clickInEditor":
                        that.changeNodeData(e)
                    break;
                }
            }

        });
        window.addEventListener("message", function(e) {
            if (e.data && e.data.action) {
                switch (e.data.action) {
                    case "removeItem":
                        that.changeSelectId()
                    break;
                }
            }

        });
    },

    data(){
        return{
            showEditorHelp: false, 
            mmHelp: null, 
            topic:{},
            command: this.preCommand,
            topicMind: null,
            topicName: "",
            dataSource: [], 
            topicContent: "",
            topicDataSource: [],
            topicTimeSpan: "1w",
            topicSimScore: "0.8",
            topicMaps: ["tag","doc"],
            editable: false,
            refreshSelector: 1, 
            topicMindWidth: {},
            propertyContainer: {},
            mindWidth: 0,
            mindHeight: 0,
            nodeId: "xbxoeegy",
            UIState: "topic",
            layout: "",
            desc:"",
            keepWords:"",
            stopWords:"",
            wlc:"sep",
            blc:"sep",
            upType: "append",
            currentPanelKey:"",
            showDownload: "",
            uid:"",
            timer: null,
            refreshUpload: 1,
            refreshInput: 1,
            globalMode: "",
            refreshTopicMind: 0,
            loading: false,
            mindTextList: [],
            classifyField: [],
            selectedField: "",
            classifyFieldDic: {},
            showClassifyField: false,
            cancelUpdate: false,
            mindTheme: "default",
            theme: "default"
        }
    },

    methods: {

        changeMindColor: function(color){
            this.mindTheme = color
            var topicMind = this.topicMind
            topicMind.theme = color
            this.topicMind = topicMind            
            if(this.editable){
                this.editable = false
            }
            else{
                this.editable = true
            }
        },

        changeSelectId: function(){
            this.nodeId = ""  
        },

        generateId: function() {
            var str = "";
            for (var i=0;i<8;i++) {
                var code = Math.floor(Math.random()*26);
                str += String.fromCharCode("a".charCodeAt(0) + code);
            }
            return str;
        },

        genTopicMind: function(e){
            var topicMind = {}
            topicMind.text = this.selectedField
            topicMind.id = "xbxoeegy"
            topicMind.layout = "map"
            var children = []
            for(var i=0;i<e.length;i++){
                var mindNode = {}
                mindNode.text = e[i]
                mindNode.id = this.generateId()
                if(i%2){
                    mindNode.side = "left"
                }
                else{
                    mindNode.side = "right"
                }
                children.push(mindNode)
                var mindNode = {}
            }
            topicMind.children = children
            this.topicMind = topicMind
            if(this.editable){
                this.editable = false
            }
            else{
                this.editable = true
            }
        },

        changeNodeData: function(e){
            console.log(e)
            this.UIState = "node"
            var data = e.data.value
            data = JSON.parse(data);
            this.showDownload = false
            if(data.docCount>0){
                this.showDownload = true
            }
            this.nodeId = data.id
            this.desc = data.desc
            this.keepWords = data.keepWords
            this.stopWords = data.stopWords
            this.upType = "append"
            if(data.blc){
                this.blc = data.blc
            }
            if(data.wlc){
                this.wlc = data.wlc
            }
            
            this.findNode(this.topic,data.id)
            this.uid = this.topicId + "-"+ data.id
            this.refreshUpload += 1
        },

        findNode: function(topicMind){
            var node_id = this.nodeId
            if(topicMind.id == node_id){
                topicMind.desc = this.desc
                topicMind.stopWords = this.stopWords
                topicMind.keepWords = this.keepWords
                topicMind.blc = this.blc
                topicMind.wlc = this.wlc
            } 
            if(topicMind.children){
                for(var ch = 0;ch < topicMind.children.length;ch++){
                    this.findNode(topicMind.children[ch],node_id)
                }
            }
            this.topicMind = topicMind
        },

        changeNode: function(){
            this.findNode(this.topicMind)
        },

        changeFileName: function(){
            this.timer=setInterval(()=> {
               var  uploadInput = document.querySelector("#uploadInput");
               if(uploadInput.files[0].name){
                    document.querySelector("#fileName").innerText = uploadInput.files[0].name
                    clearInterval(this.timer)
                    this.timer = null 
               }
            },1000);
        },

        changeUIState: function(key){
            this.UIState = key
        },

        findNodeId: function(topic,node_id,count){
            if(topic.id == node_id){
                topic.doc_count = count
            } 
            if(topic.children){
                for(var ch = 0;ch < topic.children.length;ch++){
                    this.findNodeId(topic.children[ch],node_id,count)
                }
            }
        },

        uploadFile: function(){
            this.loading = true
            var item = MM.App.current;
            var oldDesc = item.getDesc();
            var formData = new FormData();
            var uploadInput = document.querySelector('#uploadInput')
            var msgPanel = document.querySelector("#msgPanel") 
            if (!uploadInput.files || uploadInput.files.length == 0) {
                msgPanel.innerText = "请选择文件";
                msgPanel.style.color = "red";
                msgPanel.style.display = "block";
            }
            
            msgPanel.innerText = "文件" + uploadInput.files[0].name + "上传中...";
            msgPanel.style.display = "block";
            msgPanel.style.color = "#1890ff";
            formData.append('file', uploadInput.files[0]);
            if(this.nodeId){
                formData.append('node_id', this.nodeId);
            }
            else{
                this.nodeId = "xbxoeegy"
                formData.append('node_id',"xbxoeegy");
            }
            formData.append('up_type', this.upType);
            let agent_key = localStorage.getItem('agent_key')
            formData.append('agent_key', agent_key);
            if (MM.topicId) {
                formData.append('topic_id', MM.topicId);
            }
            var topicData = MM.App.map.toJSON()
            topicData = topicData.root
            formData.append('topic', JSON.stringify(topicData));
            let that=this
            
            that.post("uploadfile", formData).then(function(res) {
                if (res && res.ret == 200 && res.msg == "ok"){
                    var count = res.data.doc_count
                    if (that.upType == "append") {
                        var topic = that.topicMind
                        that.findNodeId(topic,that.nodeId,count)
                        that.topicMind = topic
                        that.editable = true
                    } 
                    else if(that.upType == "replace"){
                        var topic = that.topicMind
                        that.findNodeId(topic,that.nodeId,count)
                        that.topicMind = topic
                        that.editable = true
                    }
                    let changeTopic = {
                        "topic_id" : that.topicId,
                        "topic" : that.topicMind,
                        "task" : "UPD_TOPIC"
                    }
                    that.post("topic",changeTopic).then(function(res){
                        that.cancelUpdate = true
                        that.loading = false
                        if (res && res.ret == 200 && res.msg == "ok"){
                            that.refreshInput += 1
                            msgPanel.innerText = "文件" + uploadInput.files[0].name + "上传成功";
                            msgPanel.style.display = "block";
                            msgPanel.style.color = "green";
                            that.showDownload = true
                            
                        }
                        else{
                            msgPanel.style.color = "red";
                            msgPanel.innerText = "文件" + uploadInput.files[0].name + "上传失败请重试";
                            msgPanel.style.display = "block";
                        }
                    }).catch(function(e) {
                        that.$message.error("error")
                    });
                } 
                else{
                    msgPanel.style.color = "red";
                    msgPanel.innerText = "文件" + uploadInput.files[0].name + "上传失败请重试";
                    msgPanel.style.display = "block";
                }
            }).catch(function(e){
                console.log(e) 
            });
        },

        downloadFile: function(){
             if(this.uid==""){
                var uid = this.topicId +'-'+"xbxoeegy"
                window.location.href = this.BACKEND_API_PREFIX + "file/" + uid;
             }
             else{
                window.location.href = this.BACKEND_API_PREFIX + "file/" + this.uid;
             }
        },

        deleteFile: function(){
            this.loading = true
            this.editable = false
            var uid=this.topicId+"-"+this.nodeId
            let deleteFileData={
                "topic_id": this.topicId,
                "node_id": this.nodeId,
                "uid": uid,
               "topic": this.topicMind,
                "task": "DEL_UPLOAD_DATA"
            }

            let that = this
            that.post("topic", deleteFileData).then(function(res) {
                if (res && res.ret == 200 && res.msg == "ok"){
                    that.loading = false
                    that.$message.warning("删除样例文档成功") 
                    var topic = that.topicMind
                    that.findNodeId(topic,that.nodeId,0)
                    that.topicMind = topic
                    that.showDownload = false
                    that.refreshUpload += 1
                    that.cancelUpdate = true
                    if(that.editable){
                        that.editable = false
                    }
                    else{
                        that.editable = true
                    }
                }
                else{
                    that.$message.warning("删除样例文档失败")    
                }
            }).catch(function(e) {
                that.$message.error("error")
            });
        },

        panelKey: function(e){
          this.currentPanelKey = e
        },

        editorContainer: function(){
            let w=this.editorWidth
            let h=this.editorHeight-10
            let style="width:"+w+"px;"+"height:"+h+"px;"+"padding:15px;"
            return style;
        },

        inputSize: function(){
            let h = this.editorHeight*0.50
            let style = "height:"+h+"px;"
            return style;
        },

        clearCommand: function(data) {
            this.command = "";
            this.topicMind = data;
            this.$emit("command-cancel");
        },

        toggleHelp: function(onoff) 
        {

            if (!this.mmHelp)
                this.mmHelp = new MM.UI.Help(); 
            var helpNode = document.querySelector('#helpPanel'); 

            if ( helpNode.style.display == 'block') 
                helpNode.style.display = 'none'; 
            else
                helpNode.style.display = 'block'; 

        },

        topicMindLayout: function(onfff){
        
            var layoutNode = document.querySelector('#editorLayoutSelect'); 
            layoutNode.style.right = "-"+(this.editorWidth*0.94-260)+"px"; 

            if ( layoutNode.style.display == 'block') 
                layoutNode.style.display = 'none'; 
            else
                layoutNode.style.display = 'block'; 
               
        },

        hideSelect: function(){
            var layoutNode = document.querySelector('#editorLayoutSelect'); 
            layoutNode.style.display = 'none'; 
        },

        changeLayout: function(e){
            this.layout = e
            this.editable = true

        },

        findNodeDesc: function(topicMind,selectId){
            if(topicMind.id == selectId){
                if(topicMind.desc){
                    this.desc = topicMind.desc + this.text
                }
                else{
                    this.desc = this.text
                }
                this.stopWords = topicMind.stopWords
                this.keepWords = topicMind.keepWords
                this.blc = topicMind.blc
                this.wlc = topic.wlc
            } 
            if(topicMind.children){
                for(var ch = 0;ch < topicMind.children.length;ch++){
                    this.findNodeDesc(topicMind.children[ch],selectId)
                }
            }
        },

        init: function() 
        {
            this.nodeId = this.selectId
            if(this.text!==""){
                this.currentPanelKey = "desc"
                this.UIState = "node"
            } 

            this.dataSource = this.$store.getters.getDataSource();
            if(this.topicId.length > 0){
                this.editable = true;
                let agentKey = this.$store.getters.getAgentkey();
                let postdata = {
                    "task": "GET_DETAIL_TOPIC",
                    "agent_key": agentKey,
                    "topic_id": this.topicId
                }

                let that=this
                that.$emit("loading", true)
                that.post("topic", postdata).then(function(res) {
                    that.$emit("loading", false)
                    if (res && res.ret == 200 && res.msg == "ok"){
                        var data=res.data
                        that.topic = data
                        that.topicName = data.title
                        that.topicContent = data.content

                        that.topicDataSource = data.data_source
                        that.genClassifyField(that.topicDataSource)
                        that.refreshSelector += 1; 
                        that.topicTimeSpan = data.time_span[0]
                        that.topicSimScore = data.min_sim_score[0]
                        that.topicMind = data.topic
                        if(data.get_data_type == 'es'){
                            that.selectedField = data.topic.text
                        }
                        
                        if(data.topic.doc_count>0){
                            that.showDownload = true
                        }
                        if (!data.maps){
                            that.topicMaps = ["tag", "doc"]
                        }
                        else{
                            that.topicMaps = data.maps
                        }
                        if(that.editable){
                            that.editable = false
                        }
                        else{
                            that.editable = true
                        }
                        
                        if(that.text!==""){
                            that.nodeId = that.selectId
                            that.currentPanelKey = "desc"
                            that.UIState = "node"
                            var topicMind = that.topicMind
                            var text = String(that.text)
                            that.findNodeDesc(topicMind,that.selectId)
                        } 
                    }
                })
            }
            else{
                this.topicMind = {"id": "xbxoeegy", "text": "话题分类定义", "notes": null, "layout": "map"},
                this.topicName = "",
                this.topicContent = "",
                this.topicDataSource = ["weibo"],
                this.topicTimeSpan = "1m",
                this.topicSimScore = "0.8",
                this.topicMaps = ["tag", "doc"]
            }
        },

        genClassifyField: function(selectedField){
            this.showClassifyField = false
            this.classifyField = []
            if(this.dataSource){
            for(var i=0;i<selectedField.length;i++){
                for(var d=0;d<this.dataSource.length;d++){             
                    if(selectedField[i] == this.dataSource[d].value){
                        if(this.dataSource[d].fields.length>0){
                            for(var f=0;f<this.dataSource[d].fields.length;f++){
                                var filedName = "("+this.dataSource[d].name+")"+this.dataSource[d].fields[f].name
                                this.classifyField.push(filedName)
                                this.classifyFieldDic[filedName] = this.dataSource[d].fields[f].values
                                this.showClassifyField = true
                            }
                        }
                    }
                }
            }
            }

        },

        dataSourceFilterChanged: function(items) { 
            this.topicDataSource = items.tds;
            this.genClassifyField(items.tds)
        }, 

        makeEditable: function(state) {
            if (state == true) {
                this.editable = state;
                return;
            }
             
            let uiPanel = document.getElementsByClassName("ui uiPanel");
            if (uiPanel.length > 0) {
                var display = false;
                for (var i = 0; i < uiPanel.length; i++) {
                    display = (uiPanel[i].style.display == "block" ? true : false) || display;
                }
                this.editable = display;
            } else {
                this.editable = state;
            }
        },

        getTmpData: function(data) {
            console.log(data)
            this.topicMind = data;
        },

        genTopic : function(data){
            var mind = JSON.stringify(data)
            var dfltMind = {"id":"xbxoeegy","text":"话题分类定义","notes": null,"wlc":"sep","blc":"sep","up_type":"append","layout":"map"}
            var dfltMind = JSON.stringify(dfltMind)
            if (this.topicName == ""){
                this.$message.warning("请输入话题名称")    
            }
            else if(this.topicContent == ""){
                this.$message.warning("请输入话题说明")
            }
            else if( mind == dfltMind){
                this.$message.warning("请编辑脑图")
            }
            else if(this.topicDataSource.length == 0){
                this.$message.warning("请对数据频道进行选择")    
            }
            else if(this.topicMaps.length == 0){
                this.$message.warning("请对话题地图进行选择")
            }
            else{
                var postData = {}
                this.topicMind.id = "xbxoeegy"
                console.log("upload")
                console.log(this.topicMind)
                postData["topic"] = this.topicMind
                var topicId = this.topicId;
                if (data.topicId) {
                    topicId = data.topicId;
                }
                if (topicId) {
                    postData["task"] = "UPD_TOPIC"
                    postData["topic_id"] = topicId
                } else {
                    postData["task"] = "ADD_TOPIC"
                }
                let agentKey = this.$store.getters.getAgentkey();
                if(this.selectedField !== ""){
                    postData["get_data_type"] = "es"
                    postData["field"] = "type"
                }
                else{
                    postData["get_data_type"] = "ts"
                    postData["field"] = "post"
                }

                postData["agent_key"] = agentKey;
                postData["title"] = this.topicName
                postData["content"] = this.topicContent
                postData["data_source"] = this.topicDataSource
                postData["create_time"] = this.topic.create_time
                postData["update_time"] = new Date().getTime()
                postData["refresh_time"] = this.topic.refresh_time
                var topicTimeSpan = []
                topicTimeSpan.push(this.topicTimeSpan)
                postData["time_span"] = topicTimeSpan
                var topicSimScore = [] 
                topicSimScore.push(this.topicSimScore)
                postData["min_sim_score"] = topicSimScore
                postData["maps"] = this.topicMaps
                let that = this;
                that.$emit("loading", true); 
                that.post("topic",postData).then(function (res){
                    that.$emit("loading", false); 
                    if (res && res.ret == 200 && res.msg == "ok"){
                        var topicInfo = postData
                        topicInfo["topic_id"] = res.data.topic_id;
                        if (that.topicId!= ""){
                            topicInfo["list_task"] = "change"
                            topicInfo["enabled"] = that.topic.enabled
                            var topicShare = res.data.share
                            topicInfo["share"] = topicShare
                        }
                        else{
                            topicInfo["list_task"]="add"
                            topicInfo["enabled"] = 1
                            var topicShare = res.data.share
                            topicInfo["share"] = topicShare
                        }
                        that.$emit("updateTopic", topicInfo)
                    }
                })
            }
        },

        ungenTopic : function(){
            let that = this
            this.$emit("dropTopic",that.cancelUpdate)
        }
    }

})
</script>

<style scoped>

.layout{
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    position: fixed;
    z-index: 999;
    margin: auto;
    left: 0px;
    right: 0px;
    top: 96px;
    width:110px;
    overflow: hidden;
    border:1px solid #B5B5B5;
    box-shadow:0px 4px 12px 0px rgba(0,0,0,0.24); 
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.layout span{
    display: block;
    border-top: 1px solid #E9E9E9;
    width: 90px;
    margin-top: 8px;
    margin-left: 10px;
}

.layout button{
    display:block;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 3px 6px;
    font-size: 15px;
    width: 110px;
    text-align: left;
    height: 22px;
    color: #FFFFFF;
}

.layout button:focus{
    outline: 0;
}

.layout button:hover{
    background:#4168FF;
}

.layout button:hover p {
    color: #FFFFFF;
}

.layout p{
    float: left;
    height: 11px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 11px;
    margin-top: 2px;
    margin-left: 8px;
}

.genericName{
    background-color: transparent;
	border: none;
	margin: 0;
	padding: 3px 6px;
	font-size: 15px;
	width: 140px;
	text-align: left;
    height: 22px;
}

.genericName p{
    float: left;
    height: 11px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 700;
    line-height: 11px;
    margin-top: 8px;
    margin-left: 8px;
    margin-bottom: 4px;
}

/deep/ .ant-select-selection--multiple .ant-select-selection__placeholder{ 
    margin-top: -10px;
}

.topicEditorHelp { 
    position: fixed;
    z-index: 9999; 
    right: 0;
    top: 0;
    display: none; /*initially hidden */ 
    height: 100%;
    background-color: white;
    padding-left: 20px; 
    padding-right: 20px; 
    width: 300px;
    max-width: 600px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transition: all 200ms ease;
    transition-property: box-shadow, transform;
}

.helpTitle{
    height: 40px;
}

.helpTitle h3{
    float: left;
}

h3 { 
    font-size: 14px; 
    font-weight: 600; 
    padding-top: 10px; 
}

.helpTitle img{
    height: 25px;
    width: 25px;
    margin-top: 8px;
    float: right;
    cursor: pointer;
}

.helpNavi { 
    font-size: 11px;
    height: 80px;
}

.helpNavi tbody{
    width:500px;
}

.helpMani { 
    font-size: 11px;
    height: 91px;
}

.helpEdit { 
    font-size: 11px;
    height: 91px;
}

.selectContainer{
    width:350px;
    height:100%;
    float:right;
}

/deep/ .ant-tabs-nav .ant-tabs-tab{
    margin: 0 32px 10px 0;
    padding: 0px 16px; 
}

.dataContainer {
    width: 100%;
    margin-bottom:15px;
}

.clear{
    clear:both;
}

.timeContainer{
    height: 48px;
    width: 100%;
    margin-bottom:15px;
}

.simContainer{
    height: 25px;
    width: 100%px;
    margin-bottom:15px;
}

.mapsContainer{
    height: 25px;
    width: 100%;
    margin-bottom:15px;
}

.topicName{
    width: 100%;
    height: 35px;
    margin-bottom: 15px;
}

.topicName .input{
    width: 75%;
    height: 35px;
    float: left;
}

.topicContent{
    width:100%;
    margin-bottom:15px;
}

.topicContent .input{
    width: 75%;
    float: left;
}

/deep/ textarea.ant-input {
    height:100%;
}

.optionLabel{
    float: left;
    height: 16px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 800;
    line-height: 14px;
    margin-top: 8px;
    width: 22%;
    margin-right: 2%;
}
.dataSelect{
    float: left;
    width: 75%;

}
.dataSelect input{
    margin-right:7px; 
    margin-top: 8px;
}

.dataSelect label{
    margin-top: 8px;
    margin-right: 9px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    line-height: 14px;
    margin-right:19px;
}

.twoSelect{
    height: 50%;
    width: 100%;
}

.menuSelect{
    height:40px;
}

.shortSelect input{
    margin-right:7px; 
    margin-top: 8px;
}

.shortSelect label{
    margin-top: 8px;
    margin-right: 9px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    line-height: 14px;
    margin-right: 33px;
}

.loadButton{
    height:30px;
    margin-bottom:0px;
}

.upload{
    position: relative;
    display: inline-block;
    overflow: hidden;
    border:1px solid #1890FF;
    border-radius: 4px;
    height: 30px;
    width: 100%;
    cursor: pointer;
}

.upload .old{
    position: absolute;
    top:0;
    right:0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.upload .new{
    width:90px;
    height:30px;
    line-height:30px;
    text-align: center;
    color:#fff;
    background: #1890FF;
    float: left;
    cursor: pointer;
}

.fileName{
    float:left;
    margin-left: 5px;
    margin-top: 5px;
}

.topicMindContainer{
    overflow: hidden;
    position: relative;
    border-radius: 4px;
    width:80%;
    height:100%;
    float:left;
}

.mindEditor{
   width: 80%;
   margin-top: 10px;
   height: 95%;
}
</style>
