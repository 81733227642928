<template>
<div class="container" v-if = "detailTheme == 'default'">
    <div :class="'pageHeader topic-' + theme + '-theme-head' " style="top: 0px;"><div class="headerPanel"> 
        <table padding=0 cellspacing=0 width="100%"><tr>
            <td width="50px">
                <router-link :to="'/'">
                    <div :class="'logoIcon topic-' + theme + '-theme-logo'"></div>
                </router-link>
            </td> 
            <td width="130px">
                <router-link :to="'/'">
                    <div :class="'topicLetterIcon topic-' + theme + '-theme-topic'"></div>
                </router-link>
            </td> 
            <td width="410px" align="left"> 
                <a-input-search
                  type='input'
                  :maxLength=60
                  :class="'topic-' + theme + '-theme-input'"
                  style="padding: 5px; width: 400px; margin-top: 5px; "
                  placeholder="请输入关键词进行文档搜索"
                  v-model="searchWords"
                  @change="searchChange"
                  @search="searchArticle"
                  id="search"/>
            </td>
            <td width="240" align="left" :key="refreshTimeSpan">
                <a-range-picker 
                    :class="'topic-' + theme + '-theme-input'"
                    style="width: 230px; margin-top: 5px;" @change="selectTime"/>
            </td> 
            <td width="260" align="left">
                <flat-selector style="padding: 0px 0px 4px 0px; width: 250px; height: 32px; margin-top: 5px;" 
                    :xs="24" :sm="24" :md="24" :lg="12" :xl="12" 
                    mode="tags"
                    holder="数据频道过滤" 
                    :class="'topic-' + theme + '-theme-input'"
                    :items="dataSource" 
                    field="data_source" 
                    @selected='dataSourceFilterChanged'/>
            </td> 
            <td width="148" align="left">
                <flat-selector style="padding: 0px 0px 4px 0px; width: 150px; height: 32px; margin-top: 5px;" 
                    :xs="24" :sm="24" :md="24" :lg="12" :xl="12" 
                    holder="相关性过滤" 
                    :class="'topic-' + theme + '-theme-input'"
                    :items="simScores" 
                    field="min_sim_score" 
                    @selected='simScoreFilterChanged'/>
            </td>
            <td width="*" align="left">
                <a-tooltip placement="top">
                    <template slot="title">
                        <span v-if="showAnalysis">收起分析图表</span>
                        <span v-else>展开分析图表</span>
                    </template>
                    <div v-if="showAnalysis" class="hideAnalysis" @click="changeAnalysisState"></div>
                    <div v-else class="showAnalysis" @click="changeAnalysisState"></div>
                </a-tooltip>
            </td>
            <td width="60px">
                <router-link :to="'/'">
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>话题目录</span>
                        </template>
                        <div class="returnTopicList"/>
                    </a-tooltip>
                </router-link>
            </td>
            <td width="60px">
                <user-menu v-if="isLogin"/>
                <img v-else="" class="user" src="../assets/img/user.png">
            </td>
        </tr></table> 
    </div></div>
	<a-row :style="autoStyle" :class="'topic-' + theme + '-theme-body'">
    <grid-layout
        :layout="layout"
        :is-draggable="true"
        :is-resizable="true"
        :col-num="12"
        :margin="[5,5]"
        :row-height="rowHeight"
        :vertical-compact="true"
        :use-css-transforms="true"
    >
        <grid-item 
            v-for="(item, index) in layout" 
            :static="item.static" 
            :x="item.x"
            :y="item.y" 
            :w="item.w" 
            :h="item.h" 
            :i="item.i" 
            drag-allow-from=".drag-bar"
            drag-ignore-from=".drag-content"
            :class="'grid-item topic-' + theme + '-theme-item-bg'"
            @resized="sizeChanged(item.type)"
            :key="index"
            style="padding:0px;"
        >
            <topic-mind-wrapper 
                v-if="item.type == 'mind' && topicMind " 
                :topic="topicMind" 
                :height="mindHeight"
                :width="mindWidth"
                @changeMap="switchMap" 
                @openEditor="changeEditorState"
                @openShare="changeShareState"
                :class="'drag-content topic-' + theme +'-theme-detail-item'"
                :key="refreshTopic"
                :enabled = "topicEnabled"
                :Id = "topicId"
                :selectId="selectId"
            >
            </topic-mind-wrapper>
            <topic-map 
                v-if="item.type == 'map' && globalMode == 'analysis'"
                :class="'topic-' + theme +'-theme-detail-item'"
                :mapId="mapId"
                :topicMaps="topicMaps"
                :width="mapWidth"
                :height="mapHeight"
                @select="showArticleSummary"
                @addTag="showAddDesc"
                @changeToFullScreen="changeDetailTheme" 
                class="drag-content"
                :key="refreshMap" 
            >
            </topic-map>
            <topic-scatter 
                v-if="item.type == 'map'&& globalMode =='modeling'"
                :mapId="mapId"
                :height = "articleHeight-mindHeight"
                :width = "mindWidth"
                :trainTime = "trainTime"
                @changeClusterId = "changeClusterId"
                :key = "refreshScatter"
            >
            </topic-scatter>
			<topic-article 
                v-if="item.type == 'article'&& globalMode == 'analysis'"
                :adcode="adcode"
                :topicTree="topicTree"
                :mapId="mapId"
                :time="timestamp"
                :topicMind = "topicMind"
                :filters="articleFilters"
                :height="articleHeight"
                :width="articleWidth"
                :force="refreshArticle"
                class="drag-content"
                @changeTopicMind="changeTopicMind"
                :analysisState = "showAnalysis"
            >
            </topic-article>
            <topic-sample 
                v-else-if="item.type == 'article'&& globalMode =='modeling' "
                :height="articleHeight"
                :width="articleWidth"
                :mapId="mapId"
                :topicMind="topicMind"
                :adcode="adcode"
                :topicTree="topicTree"
                :time="timestamp"
                :clusterId = "clusterId"
                @changeTopicMind="changeTopicMind"
                :refreshSampleId = "refreshSample"
            >
            </topic-sample>
        </grid-item>
    </grid-layout>
	</a-row>
    <a-modal v-model="showTopicEditor" title="话题编辑器" 
        :class="'topic-' + theme + '-theme-editor'"
        :bodyStyle="{'padding': '0px'}" :footer="null" :width="editorModalWidth" :closable="closable" @cancel="closeTopicEditor">
        <topic-editor style="position:fixed;" :editorWidth="editorModalWidth" :editorHeight="editorModalHeight"
            :class="'topic-' + theme + '-theme-editor-body'"
            @loading="toggleLoading" @updateTopic="updateTopic" @dropTopic="closeTopicEditor" @command-cancel="clearCommand"
            :topicId="topicId" :selectId="selectId" :preCommand="preCommand" :text="currentTags" :key="refreshEditor">
        </topic-editor>
    </a-modal>
    <a-modal v-model="showTopicShare" title="分享设置" :bodyStyle="{'padding': '0px'}" :footer="null" :width="720" :closable="closable">
        <topic-share @closeShare="closeTopicShare" :key="refreshTopicShare" :topicId="topicId"></topic-share>
    </a-modal>
    <loading :active.sync="showLoading" 
        :can-cancel="false"
        color="rgb(47, 185, 248)"
        :opacity="0.1"
        :is-full-page="true"
        :z-index = "10000">
    </loading>

</div> 
<div v-else :style="fullScreenContainer">
    <topic-map 
        :class="'topic-' + theme +'-theme-detail-item'"
        :mapId="mapId"
        :topicMaps="topicMaps"
        @select="showArticleSummary"
        @addTag="showAddDesc"
        @changeToFullScreen="changeDetailTheme" 
        class="drag-content"
        :key="refreshMap" 
    >
    </topic-map>
    <div style="position: fixed; right: 96px; top: 64px;">
        <a-row>
            <a-col :span="12">
                <div style="height: 32px; width: 32px; box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1); margin-top: 8px;">
                <router-link :to="'/'">
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>话题目录</span>
                        </template>
                        <div class="returnTopicList" style="margin: 1px;"/>
                    </a-tooltip>
                </router-link>
            </div>
            </a-col>
            <a-col :span="12" style="padding-left: 8px;">
                <user-menu v-if="isLogin"/>
                <img v-else="" class="user" src="../assets/img/user.png">
            </a-col>
        </a-row>
    </div>
    <div class="tagContainer">
        <topic-mind-wrapper 
            :topic="topicMind" 
            :height="fsMindHeight"
            :width="fsMindWidth"
            @changeMap="switchMap" 
            @openEditor="changeEditorState"
            @openShare="changeShareState"
            :class="'drag-content topic-' + theme +'-theme-detail-item'"
            :key="refreshTopic"
            :enabled = "topicEnabled"
            :Id = "topicId"
            :selectId="selectId"
        >
        </topic-mind-wrapper>
        <div style="border-top: 1px solid #D0D0D0; border-bottom: 1px solid #D0D0D0;">
            <table padding=0 cellspacing=0 width="100%">
                <tr>
                    <td :class="'topic-' + theme +'-theme-detail-item'">
                        <a-input-search
                          type='input'
                          :maxLength=60
                          :class="'topic-' + theme + '-theme-input'"
                          style="padding: 5px; width: 140px; margin-top: 5px;"
                          placeholder="请输入关键词进行文档搜索"
                          v-model="searchWords"
                          @change="searchChange"
                          @search="searchArticle"
                          id="search"/>
                    </td>
                    <td :class="'topic-' + theme +'-theme-detail-item'">
                        <flat-selector style="padding: 0px 0px 4px 0px; width: 120px; height: 32px; margin-top: 5px;" 
                            :xs="24" :sm="24" :md="24" :lg="12" :xl="12" 
                            mode="tags"
                            holder="数据频道过滤" 
                            :class="'topic-' + theme + '-theme-input'"
                            :items="dataSource" 
                            field="data_source" 
                            @selected='dataSourceFilterChanged'/>
                    </td>
                    <td :class="'topic-' + theme +'-theme-detail-item'">
                        <flat-selector style="padding: 0px 0px 4px 0px; width: 68px; height: 32px; margin-top: 5px;" 
                            :xs="24" :sm="24" :md="24" :lg="12" :xl="12" 
                            holder="相关性过滤" 
                            :class="'topic-' + theme + '-theme-input'"
                            :items="simScores" 
                            field="min_sim_score" 
                            @selected='simScoreFilterChanged'/>
                    </td>
                </tr>
            </table>
        </div>
        <topic-article 
            :adcode="adcode"
            :topicTree="topicTree"
            :mapId="mapId"
            :time="timestamp"
            :topicMind = "topicMind"
            :filters="articleFilters"
            :height="fsArticleHeight"
            :width="fsMindWidth"
            :force="refreshArticle"
            class="drag-content"
            @changeTopicMind="changeTopicMind"
            :analysisState = "showAnalysis"
        >
        </topic-article>
    </div>
</div>
</template>

<script>

import VueGridLayout from 'vue-grid-layout';
import TopicMindWrapper from './TopicMindWrapper.vue'
import {FlatSelector} from './Selector'
import TopicMap from './TopicMap.vue'
import TopicScatter from './TopicScatter.vue'
import TopicArticle from './TopicArticle.vue'
import TopicSample from './TopicSample.vue'
import TopicEditor from './TopicEditor.vue'
import TopicShare from './TopicShare.vue'
import UserMenu from './UserMenu.vue'
import Loading from 'vue-loading-overlay'

export default ({
    name: "topic-detail-panel",
    components:{
		TopicMap,
		TopicArticle,
		TopicMindWrapper,
        FlatSelector, 
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        TopicEditor,
        TopicShare,
        UserMenu,
        Loading,
        TopicSample,
        TopicScatter
	},

    data() {
        return {
            showLoading : false,
            closable : false,
            layout: [
                {
                    "x": 0, "y": 0, 
                    "w": 4, "h": 3, 
                    "i": "0", static: false,
                    "type": "mind"
                },
                {
                    "x": 0, "y": 0, 
                    "w": 4, "h": 3, 
                    "i": "1", static: false,
                    "type": "map"
                },
                {
                    "x": 4, "y": 0, 
                    "w": 8, "h": 6, 
                    "i": "2", static: false,
                    "type": "article"
                }
            ],
            topicId: "",
            topicMind: null,
            topicEnabled: 1,
            topicMaps: ["tag", "doc"],
            mindHeight: 0,
            mindWidth: 0,
            mapWidth: 0,
            mapHeight: 0,
            FsMindHeight: 0,
            FsMindWidth: 0,
            articleHeight: 0,
            articleWidth: 0,
            timestamp: 0,
            topicData: {},
            topicTree: [],
            title: "",
            mapId: "",
            adcode: "",
            searchWords: "",
            timeSpan: [],
            dataSource: [],
            selectDataSource: [],
            simScoreTable: {
                "0.95": "很高", 
                "0.9": "较高",
                "0.8": "一般",
            },
            simScores: [],
            selectSimScore: 0.0,
            articleFilters: {},
            selectId: "xbxoeegy",
            preCommand: "",
            currentTags: "",
            width: 0,
            height: 0,
            autoStyle: {},
            autoHeight: {},
            isLogin: false,
            refreshEditor: 1,
            refreshTopic: 1,
            refreshMap: 1,
            refreshArticle: 1,
            refreshTimeSpan: 1,
            refreshTopicShare: 1,
            refreshSample: 1,
            refreshScatter: 1,
            showTopicEditor: false,
            showTopicShare: false,
            editorModalWidth:0,
            editorModalHeight:0,
            showAnalysis: true,
            globalMode:"",
            trainTime:0,
            clusterId: "",
            theme: "default",
            detailTheme: "default",
            tagContainer:{},
            fullScreenContainer:{}
        }
    },
    methods: {

        changeAnalysisState: function(){
            if(this.showAnalysis){
                this.showAnalysis = false
            }
            else{
                this.showAnalysis = true
            }
        },

        changeTopicMind: function(data){
            this.topicMind = data
            this.refreshTopic += 1;
        },

        changeClusterId: function(clusterId){
            this.clusterId = clusterId
        },

        searchChange: function(e) {
            this.searchWords = e.target.value;
        },

        dataSourceFilterChanged: function(data, field) {
            this.selectDataSource = data[field];
            delete this.articleFilters[field];
            this.$set(this.articleFilters, field, data[field]);
        },

        simScoreFilterChanged: function(data, field) {
            this.selectSimScore = Number(data[field]);
            delete this.articleFilters[field];
            this.$set(this.articleFilters, field, data[field]);
        },

        searchArticle: function() {
            if (this.searchWords.trim().length > 0) {
                delete this.articleFilters["keywords"];
                this.$set(this.articleFilters, "keywords", this.searchWords.trim());
            } else {
                delete this.articleFilters["keywords"];
                this.$set(this.articleFilters, "keywords", this.searchWords.trim());
            }
        },

        selectTime: function(time, timeStr) {
            this.timeSpan = [
                (new Date(timeStr[0] + " 00:00:00")).valueOf(),
                (new Date(timeStr[1] + " 00:00:00")).valueOf()
            ];
            delete this.articleFilters["time_span"];
            this.$set(this.articleFilters, "time_span", this.timeSpan);
        },

        showAddDesc: function(tag) {
            this.showTopicEditor = true 
            this.editorModalWidth = document.body.clientWidth*0.95;
            this.editorModalHeight = document.body.clientHeight-120;            
            this.refreshEditor += 1;
            this.currentTags = tag;
        },
        
        changeDetailTheme: function(){
            this.detailTheme = this.detailTheme === "fullScreen" ? "default" : "fullScreen";
            let that = this
            this.$store.commit('changeDetailTheme',that.detailTheme);
            //this.getTopicData();
            this.refreshMap += 1
            //this.refreshTopic += 1
        },

        clearCommand: function() {
            this.preCommand = "";
        },

        showArticleSummary: function(adcode, timestamp) {
            console.log("in showArticleSummary function", adcode, timestamp);
            this.adcode = adcode;
            this.timestamp = timestamp;
            this.selectDataSource = [];
            this.selectSimScore = 0;
            this.searchWords = "";
            this.timeSpan= [];
            this.articleFilter = {};
            this.refreshArticle += 1;
        },

        switchMap: function (data) {
            data = JSON.parse(data);
            this.mapId = this.topicId + "-" + data.id;
            this.selectId = data.id;
            this.selectDataSource = [];
            this.selectSimScore = 0;
            this.searchWords = "";
            this.timeSpan = [];
            this.title = data.text;
            this.topicMaps = this.topicData.maps.sort();
            this.refreshSample += 1
            this.refreshScatter += 1
            this.refreshMap += 1;   
            this.refreshTimeSpan += 1;
        },

        sizeChanged: function(type) {
            if (type == "mind") {
                this.refreshTopic += 1;
            }
            if (type == "map") {
                this.refreshMap += 1;
            }
        },

        getTopicData: function() {
            var route = this.$route;
            if (route.params["topicId"]) {
                this.topicId = route.params["topicId"];
            } 
            let agentKey = this.$store.getters.getAgentkey();
            if (!agentKey) {
            }

            let postdata = {
                "task": "GET_DETAIL_TOPIC",
                "agent_key": agentKey,
                "topic_id": this.topicId
            };
            var that = this;
            that.post('topic', postdata)
            .then(function(data) {
                if(data.ret ==200) {
                    that.topicData =data.data;
                    that.title = that.topicData.title;
                    that.dataSource = that.topicData.data_source; 
                    that.topicMind = that.topicData.topic;
                    that.trainTime = that.topicData.train_time
                    that.topicEnabled = that.topicData.enabled;
                    that.topicId = that.topicData.topic_id;
                    that.topicMaps = that.topicData.maps.sort();
                    that.mapId = that.topicId + "-" + data.data.topic.id;
                    that.parseTopic(that.topicMind);
                    that.parseTopicData();
                    that.switchMap(JSON.stringify({id: data.data.topic.id}));
                    that.refreshTopic += 1;
                }
            }).catch(function(e){
                console.log(e);
            });
        },

        parseTopic: function(topic) {
            let data = {};
            let tree = JSON.parse(JSON.stringify(topic));

            let topicId = this.topicId;

            let createTree = function(node, copy) {
                let title = node.text;
                let value = node.text;
                let key = node.text;
                copy["title"] = value;
                copy["key"] = topicId + "-" + node.id;
                copy["value"] = topicId + "-" + node.id;

                if (node.children && node.children.length > 0) {
                    copy["children"] = [];
                    for (let i = 0; i < node.children.length; i++) {
                        copy["children"].push({});
                        createTree(node.children[i], copy["children"][i]);
                    }
                }
            }

            createTree(topic, data);
            this.topicTree = [data];
        },

        parseDataSource: function() {
            var dataSource = [];

            let sourceList = this.$store.getters.getDataSource();
            let sourceTable = {};
            for (var i = 0; i < sourceList.length; i++) {
                sourceTable[sourceList[i].value] = sourceList[i].name;
            }

            if (this.dataSource) {
                for (var i = 0; i < this.dataSource.length; i++) {
                    dataSource.push({
                        name: sourceTable[this.dataSource[i]],
                        value: this.dataSource[i]
                    });
                }
            }
            this.dataSource = dataSource;
        },

        parseSimScore: function() {
            let min = 1000;
            for (var i = 0; i < this.topicData.min_sim_score.length; i++) {
                if (Number(this.topicData.min_sim_score[i]) < min) {
                    min = Number(this.topicData.min_sim_score[i]);
                }
            }
            this.simScores = [];
            for (var score in this.simScoreTable) {
                if (Number(score) >= min) {
                    this.simScores.push({
                        name: this.simScoreTable[score],
                        value: score
                    });
                }
            }
        },

        parseTopicData: function(data) {
            this.parseDataSource();
            this.parseSimScore();
        },

        changeEditorState : function(){
            this.showTopicEditor = true
            this.currentTags = ""
            this.selectId = "xbxoeegy"
            this.editorModalWidth = document.body.clientWidth*0.95;
            this.editorModalHeight = document.body.clientHeight-120;
            this.refreshEditor += 1;
        },

        changeShareState : function(){
            this.showTopicShare = true
            this.refreshTopicShare += 1;
        },

        updateTopic : function(msg){
            this.topicMind = msg.topic
            this.topicEnabled = msg.enabled
            this.topicMaps = msg.maps
            this.showTopicEditor = false
            this.getTopicData();
        },

        closeTopicEditor : function(){
            this.showTopicEditor = false
            this.getTopicData();
        },

        closeTopicShare : function(){
            this.showTopicShare = false
        },

        toggleLoading: function(onoff){
            this.showLoading  = onoff; 
        }
    },

    created() { 
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        this.theme = theme;
        
        /*var detailTheme =  this.$store.getters.getDetailTheme();
        if (!detailTheme) {
            detailTheme = "default";
        }
        */
        this.$store.commit('changeDetailTheme','default')
        var detailTheme =  this.$store.getters.getDetailTheme() 
        this.detailTheme = detailTheme;

        var globalMode = this.$store.getters.getMode()
        
        if(globalMode==''){
            this.globalMode = "analysis"
        }
        else{
            this.globalMode = globalMode
        }
        document.title = "话题详情";
        this.isLogin = this.$store.getters.isLogin();
        var width = document.body.clientWidth;
        var height = document.body.clientHeight;
        this.width = width;
        this.height = height;
        this.fullScreenContainer = {"height": height + "px","width": width + "px","overflow":"hidden","position": "relative"};
        //this.tagContainer = {"height": height*0.86 + "px", "width": width*0.25 + "px", "top": height*0.091 + "px", "left": width*0.033 + "px"}
        this.rowHeight = (height - 35 - 55)/ 6.0;
        this.autoStyle = {"height": (height - 55) + "px", "width": width + "px", "overflow-x": "hidden", "top": "55px"};
        this.mindWidth = width / 12 * 4 - 16;
        this.mindHeight = this.rowHeight * 3 - 30;
        this.mapWidth = width / 12 * 4 - 16;
        this.mapHeight = this.rowHeight * 3 - 30;
        this.fsMindHeight = this.height * 0.24;
        //this.fsMindWidth = this.width * 0.25;
        this.fsMindWidth = 368;
        this.fsArticleHeight = this.height * 0.6;
        this.articleHeight = this.rowHeight * 6 + 25;
        this.articleWidth = width / 12 * 8 - 16;
        this.autoHeight = {
            "height": (height / 2.0) + "px", 
            "border-right": "solid 1px #8c8c8c",
            "border-bottom": "solid 1px #8c8c8c"
        };
        this.getTopicData()
    }
});

</script>

<style lang="less" scoped>
@import "../assets/css/topic.less";
</style>

<style>
@import "../my-mind/css/style.css";
@import "../assets/css/topic.css";

.hideAnalysis{
    background-image:url(../assets/img/hideAnalysis.png);
    height:30px;
    width:30px;
    left:0px;
    top:4px;
    margin-left:7px;
    margin-top: 3px;
    background-size:contain;
    background-repeat: no-repeat;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
    cursor:pointer;
}

.hideAnalysis:hover{
    background-image:url(../assets/img/hover/hideAnalysis.png);
}

.showAnalysis{
    background-image:url(../assets/img/showAnalysis.png);
    height:30px;
    width:30px;
    left:0px;
    top:4px;
    margin-left: 7px;
    margin-top: 3px;
    background-size:contain;
    background-repeat: no-repeat;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
    cursor:pointer;
}

.showAnalysis:hover{
    background-image:url(../assets/img/hover/showAnalysis.png);
}

.returnTopicList{
    width: 30px;
    height: 30px;
    margin-top: 8px; 
    float: right;
    cursor:pointer;
    background-image:url(../assets/img/list.png);
    background-size:contain;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
}

.returnTopicList:hover{
    background-image:url(../assets/img/hover/list.png);
}

.grid-item {
    border-radius:5px;
    background-color:#FFFFFF;
    position:relative;
    padding: 5px; 
    overflow: hidden; 
}

.grid-item:.cssTransforms {
    transition: all 200ms ease;
    transition-property: box-shadow, transform;
}

.drag-bar {
    border-bottom: 1px solid #dcdcdc;
    height: 32px;
    width: 100%;
}

.drag-content{
    overflow: hidden; 
}

.mindBgc{
    opacity:0.75;
    background-color:#000000;
}

.tagContainer{
    height: 86%;
    /*width: 25%; */
    width: 368px; 
    top: 9.1%;
    left: 3.3%;
    position: absolute;
    /*border: 1px solid #4168FF; */
    border: 1px solid #DCDCDC; 
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
}

</style>
<style scoped>

/deep/ .ant-modal{
    top:55px;
}

/deep/ .ant-select-tree-switcher .ant-select-tree-switcher-noop{
    width:10px;
}

/deep/ .grid-item {
    background-color: none;
}

</style>
