<template>
    <div class="map" @mouseenter="showToolTip()" @mouseleave="hideToolTip()">
        <loading :active.sync="loading" :can-cancel="true" color="rgb(47, 185, 248)" :opacity="0.1" :is-full-page="true"/>
        <div v-if="showIcon" class="allIcon">
            <div v-if="showTag">   
                <a-tooltip place="top">
                    <template slot="title">
                        <span>标签地图</span>
                    </template>
                    <div v-if ="chosenTag" class="icon tagIcon" @click="changeToTag"/>
                    <div v-else class="icon tagHover"/>
                </a-tooltip>
            </div>
            <div v-if="showDoc">
                <a-tooltip place="top">
                    <template slot="title">
                        <span>文档地图</span>
                    </template>
                    <div v-if ="chosenDoc" class="icon docIcon" @click="changeToDoc"/>
                    <div v-else class="icon docHover"/>
                </a-tooltip>
            </div>
            <div>
                <a-tooltip place="top">
                    <template slot="title">
                        <span v-if="detailTheme == 'default'">全屏模式</span>
                        <span v-if="detailTheme == 'fullScreen'">默认模式</span>
                    </template>
                    <div v-if="detailTheme == 'default'" class="icon fullScreenIcon" @click="changeToFullScreen"></div>
                    <div v-if="detailTheme == 'fullScreen'" class="icon defaultScreenIcon" @click="changeToFullScreen"></div>
                </a-tooltip>
            </div>
        </div>
        <div id="map">
        </div>
        <div class="no-loading" v-if="noData">
            <div style="background-color: white; max-width: 200px; margin: auto;">
                <span>没有加载到数据，请稍后重试。</span>
            </div>
        </div>
        <div class="data-stats" v-if="timestamp > 0"> 
            <!--span style="color: rgba(255, 255, 255, 0.65);">数据更新于 {{timestamp | TimeChange}}</span-->
            <span style="color: rgba(0, 0, 0, 0.65);">数据更新于 {{timestamp | TimeChange}}</span>
        </div>
        <div class="tag-menu" :style="tagMenuPos">
            <div class="tag-menu-item" @click="showAddTags">
                添加到主题说明
            </div>
        </div>
    </div>
</template>

<script>
var d3 = require("d3");
d3["tile"] = require("d3-tile").tile;
import Loading from 'vue-loading-overlay';

export default {
    name: 'TagMap',
    components: {
        Loading
    },
    props: {

        mapId: {
            type: String,
            default: function () {
                return "";
            }
        },

        topicMaps:{
            type: Array,
            default: function(){
                return ["doc", "tag"];
            }
        },

        width: {
            type: Number,
            default: function() {
                return 0;
            }
        },

        height: {
            type: Number,
            default: function() {
                return 0;
            }
        }

    },
    data() {
        return {
            showIcon:false,
            showTag: true,
            showDoc: true,
            showCloud: true,
            chosenTag: true,
            chosenDoc: true,
            chosenCloud: true,
            parentTags: [],
            lastSelectPaths: [],
            lastSelectCircle: {"style": {"display": ""}},
            tags: [],
            tag: "",
            mode: "doc",
            tagLevel: 1,
            sumPos: {},
            lastScale: 1,
            timestamp: 0,
            svg: null,
            zoom: null,
            uid: this.mapId,
            tile: null,
            //width: 0,
            //height: 0,
            mapWidth: 1200,
            mapHeight: 800,
            startX: 0,
            startY: 0,
            loading: false,
            lastAdcode: "",
            currentLevel: 1,
            timer: {},
            showChart: false,
            trending: {},
            currentTag: "",
            projection: null,
            tagMenuPos: {},
            iconSize: 40,
            currentScale: 1,
            noData: false,
            isLogin: false,
            refresh: 1,
            levelFeatures: {},
            globalFeature: {},
            detailTheme: ""
        }
    },
    watch: {
        topicMaps() 
        {
            this.autoShowIcon()
        }    
    },

    mounted() {
        if(this.topicMaps.length == 3){
            this.mode = "doc"
        }
        else{
            this.mode = this.topicMaps[0]
        }
        this.autoShowIcon()
        this.init(this.$el);
    },
    filters: {
        TimeChange(value) {
           var date = new Date(value);
           var y = date.getFullYear();
           var MM = date.getMonth() + 1;
           MM = MM < 10 ? "0" + MM : MM;
           var d = date.getDate();
           d = d < 10 ? "0" + d : d;
           var h = date.getHours();
           h = h < 10 ? "0" + h : h;
           var m = date.getMinutes();
           m = m < 10 ? "0" + m : m;
           var s = date.getSeconds();
           s = s < 10 ? "0" + s : s;

           return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
        }
    },
    methods: {

        autoShowIcon: function(){
            if(this.topicMaps.length == 3){
                this.showTag = true
                this.showDoc = true
                this.showCloud = true
                this.chosenTag = true
                this.chosenCloud = true
                this.chosenDoc = false 
            }
            else if(this.topicMaps.length == 2){
                if(this.topicMaps.indexOf("maps")>-1){
                    this.showCloud=false
                    this.showTag = false
                    this.showDoc = false
                }
                else{
                    this.showCloud=false
                    this.chosenDoc=false
                    this.showTag = true
                    this.showDoc = true
                    this.chosenTag = true
                }
            }
            else{
                this.showTag=false
                this.showDoc=false
                this.showCloud=false
            }
        },

        showToolTip: function(){
            this.showIcon = true
        },

        hideToolTip: function(){
            this.showIcon = false
        },

        switchable: function(){
            if (this.topicMaps.length == 2){
                if (this.showIcon){
                    return true;
                }
                else{
                    return false;
                }
            }
        },

        showAddTags: function() {
            this.$emit("addTag", this.currentTag);
            this.tagMenuPos = {"display": "none"};
        },

        changeToFullScreen: function(){
            this.$emit("changeToFullScreen")
        },

        changeToTag: function(){
            this.chosenTag = false
            this.chosenDoc = true
            this.chosenCloud = true
            this.mode = "tag"
            this.levelFeatures = {};
            this.init(this.$el)
        },

        changeToDoc: function(){
            this.chosenTag = true
            this.chosenDoc = false
            this.chosenCloud = true
            this.mode = "doc"
            this.levelFeatures = {};
            this.init(this.$el)
        },

        changeMode: function(type) {
            this.mode = type;
            localStorage.setItem('mode', type);
            this.levelFeatures = {};
            this.init(this.$el);
        },

        showModal: function(type) {
            if (type == "userInfo") {
                this.userInfo = true;
            } 
            if (type == "logout") {
                localStorage.clear()
                this.isLogin = false;
            }
            if (type == "uc") {
                this.addTheme = true;
            }
        },

        zoomed: function() {
            var tiles = this.tile(d3.event.transform);
            var nowScale = d3.event.transform.k;
            
            var maxX = (-tiles.translate[0] + this.width + this.startX) / (2.0);
            var maxY = (-tiles.translate[1] + this.height + this.startY) / (2.0);
            var minX = maxX - this.width / 2;
            var minY = maxY - this.height / 2;

            //console.log("maxX is ", maxX);
            //console.log("maxY is ", maxY);
            //console.log("minX is ", minX);
            //console.log("minY is ", minY);
            //console.log("zoom is ", nowScale);
            if (this.lastScale < nowScale) {
                var level = Math.round(nowScale);
                var parentTags = [];
                var adcodes = [];
                for(var i = 0; i < this.tags.length; i++) {
                    var tag = this.tags[i];
                    if (!tag.used) {
                        if (tag.level <= (level - 1)) {
                            adcodes.push(tag.adcode);
                            this.tags[i]["used"] = true;
                        }
                    }
                    parentTags.push(tag);
                }
                this.getChild(adcodes, parentTags, level);
            }



            //if (nowScale == 2 || nowScale == 3) {
            //    var adcodes = [];
            //    for(var i = 0; i < this.tags.length; i++) {
            //        var tag = this.tags[i];
                    //if ((tag.maxX < maxX && tag.maxX > minX && !tag.used && tag.maxY < maxY && tag.maxY > minY && tag.id != "")
                    //    ||(tag.maxX < maxX && tag.maxX > minX && !tag.used && tag.minY < maxY && tag.minY > minY && tag.id != "")
                    //    ||(tag.minX < maxX && tag.minX > minX && !tag.used && tag.minY < maxY && tag.minY > minY && tag.id != "")
                    //    ||(tag.minX < maxX && tag.minX > minX && !tag.used && tag.maxY < maxY && tag.maxY > minY && tag.id != "")
                    //    ||(tag.x < maxX && tag.x > minX && !tag.used && tag.y < maxY && tag.y > minY && tag.id != "")
                    //) {
            //        if (!tag.used) {
                        //console.log("tag level is ", tag.level);
                        //if (tag.adcode != "padding" && tag.level == (nowScale - 1)) {
            //            if (tag.level <= (nowScale - 1)) {
            //                adcodes.push(tag.adcode);
                            //parentTags.push(tag);
            //                this.tags[i]["used"] = true;
            //            }
            //        }
            //        parentTags.push(tag);
            //    }
            //    this.getChild(adcodes, parentTags, nowScale);
            //}
            if (this.lastScale > nowScale) {
	            var tempTags = [];
                var nameTable = {};
                var level = Math.round(nowScale) + 1;
                if (level > 1) {
                    var paths = d3.selectAll("path.child" + level);
                    for (var i = 0; i < paths._groups[0].length; i++) {
                        paths._groups[0][i].remove();
                    }
                    var tags = d3.selectAll("text.child" + level);
                    for (var i = 0; i < tags._groups[0].length; i++) {
                        var name = tags._groups[0][i].innerHTML;
                        nameTable[name] = true;
                        tags._groups[0][i].remove();
                    }
                    var circles = d3.selectAll("circle.child" + level);
                    for (var i = 0; i < circles._groups[0].length; i++) {
                        circles._groups[0][i].remove();
                    }
                }

                var ptags = d3.selectAll("text.child1");
                for (var i = 0; i < ptags._groups[0].length; i++) {
                    ptags._groups[0][i].style.display = "block";
                }
                for(var j = 0; j < this.tags.length; j++) {
                    if (!nameTable[this.tags[j].name]) {
                        this.tags[j]["used"] = false;
                        tempTags.push(this.tags[j]);
                    }
                }

                ptags = d3.selectAll("#highlight");
                for (var i = 0; i < ptags._groups[0].length; i++) {
                    ptags._groups[0][i].remove();
                }
                this.tags = tempTags;
            }
            this.svg.attr("transform", d3.event.transform);
            //this.svg.transition().call(this.zoom.scaleBy, 2);
            this.lastScale = nowScale;
        },

        showSelectMenu: function(tag, adcode, x, y) {
            this.tagMenuPos = {"left": (x + 15) + "px", "top": (y + 15) + "px"};
            this.currentTag = tag;
        },

        showArticleSummary: function(tag, adcode, x, y) {
            this.$emit("select", adcode, this.timestamp);
        },

        getChild: function(adcodes, parentTags, level) {
            var that = this;
            //console.log(adcodes.length, scale);
            if (adcodes.length == 0) {
                return;
            }
            if (this.levelFeatures[level]) {
                that.drawMap(this.levelFeatures[level], level, parentTags);
            } else {
                var postdata = {
                    task: "GET_TOPIC_MAP",
                    adcodes: adcodes,
                    uid: that.uid,
                    mode: this.mode,
                    width: that.mapWidth,
                    height: that.mapHeight,
                    level: level,
                    timestamp: that.timestamp
                };
                this.loading = true;
                that.post("topic", postdata)
                    .then(function(data) {
                        if (data.ret == 200) {
                            that.loading = false;
                            that.drawMap(data.data, level, parentTags);
                        } else {
                        }
                    }).catch(function (e) {
                        console.log(e);
                    })
            }
        },

        drawMap: function(features, level, parentTags) {
            //console.log("current level is ", level);
            var that = this;
            var fillColor = [
                    "#1E97F4", "#2F9EF4", "#3FA5F3", "#50ABF3", "#60B2F2",
                    "#71B9F2", "#81C0F1", "#A8D4F8", "#C3E1F8", "#DDEEFC",
                    "#B3D4F0", "#AFD2DC", "#ACD0C9", "#A8CEB5", "#A4CCA1", 
                    "#A1C98E", "#9DC77A", "#99C566", "#96C353", "#81B02F",
                    "#92C13F", "#9DC64B", "#A8CC56", "#B3D162", "#BED66D",
                    "#C8DC79", "#D3E184", "#DEE690", "#F7F9B4", "#FFFDD1",
                    "#FCE3C8", "#F1D3A9", "#EEB787", "#DF9C6A", "#DC8E5A",
                    "#D97F4A", "#D6713A", "#D3632A", "#D0541A", "#B64613"
            ];
            var fontColor = [
                    "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", 
                    "#FFFFFF", "#FFFFFF", "#FFFFFF", "#333333", "#333333",
                    "#333333", "#333333", "#333333", "#333333", "#333333", 
                    "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF",
                    "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF",
                    "#333333", "#333333", "#333333", "#333333", "#333333", 
                    "#333333", "#333333", "#FFFFFF", "#FFFFFF", "#FFFFFF", 
                    "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"
                ];
            var borderColor = [
                    "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",
                    "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",
                    "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",
                    "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",
                    "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",
                    "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", 
                    "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", 
                    "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"
            ];
            var fontSize = [0, 10, 6, 4, 3, 2];
            var borderColor = ["black", "red", "green", "blue"];
            var textList = [];
            this.levelFeatures[level] = features;
            for (var i = 0; i < features.length; i++) {
                var feature = features[i];
                var coordinates = feature.geometry.coordinates[0];
                var path = "";
                var center = feature.properties.center;
                center = [that.projection(center)[0], that.projection(center)[1]];
                var circleRadius = 1000000;
                var adcode = feature.properties.adcode;
                if (adcode == "padding") {
                    continue;
                }
                if (feature.properties.unique) {
                    continue;
                }
                var maxX = -100;
                var minX = 10000000;
                var maxY = -100;
                var minY = 10000000;
                for (var j = 0; j < coordinates.length; j++) {
                    var points = coordinates[j];
                    var start = that.projection(points[0]);
                    var len = Math.sqrt((start[0] - center[0]) * (start[0] - center[0]) + (start[1] - center[1]) * (start[1] - center[1]));
                    if (len < circleRadius) {
                        circleRadius = len;
                    }
                    if (start[0] > maxX) {
                        maxX = start[0];
                    }
                    if (start[0] < minX) {
                        minX = start[0];
                    }
                    if (start[1] > maxY) {
                        maxY = start[1];
                    }
                    if (start[1] < minY) {
                        minY = start[1];
                    }
                    path += "M" + start[0] + "," + start[1];
                    for (var k = 1; k < points.length; k++) {
                        var td = that.projection(points[k]);
                        path += "L" + td[0] + "," + td[1];
                        var len = Math.sqrt((td[0] - center[0]) * (td[0] - center[0]) + (td[1] - center[1]) * (td[1] - center[1]));
                        if (len < circleRadius) {
                            circleRadius = len;
                        }
                        if (td[0] > maxX) {
                            maxX = td[0];
                        }
                        if (td[0] < minX) {
                            minX = td[0];
                        }
                        if (td[1] > maxY) {
                            maxY = td[1];
                        }
                        if (td[1] < minY) {
                            minY = td[1];
                        }
                    }
                    path += "Z";
                }

                var strokeWidth = 0.6 / level;

                var colorIndex = parseInt((feature.score * 100) / 2.7);

                that.tags.push({
                    name: feature.properties.name,
                    count: feature.properties.count,
                    adcode: feature.properties.adcode,
                    path: path,
                    color: colorIndex,
                    type: feature.type,
                    level: level,
                    r: circleRadius,
                    maxX: maxX,
                    minX: minX,
                    maxY: maxY,
                    minY: minY,
                    x: center[0],
                    y: center[1]
                });

                textList.push({
                    name: feature.properties.name.trim(),
                    adcode: feature.properties.adcode,
                    count: feature.properties.count,
                    color: colorIndex,
                    type: feature.type,
                    level: level,
                    r: circleRadius,
                    x: center[0],
                    y: center[1]
                });

                that.svg.append("path")
                    .attr("d", path)
                    .attr("name", fillColor[colorIndex])
                    .attr("id", "path" + feature.properties.adcode)
                    .attr("class", "child" + level + " " + feature.properties.adcode)
                    .style("fill", function() {
                        return fillColor[colorIndex];
                    })
                    .style("stroke-width", "" + strokeWidth)
                    .style("stroke-linejoin", "round")
                    .style("stroke-linecap", "round")
                    .style("fill-rule", "evenodd")
                    .style("stroke", "rgb(255, 255, 255, 0.65)")
                    //.style("stroke", borderColor[level])


                if (adcode != "padding") {
                    var gdefs = that.svg.append("defs").append("radialGradient").attr("id", adcode); 
                    gdefs.append("stop").attr("offset", "0%").attr("stop-color", fillColor[colorIndex + 2]); 
                    gdefs.append("stop").attr("offset", "40%").attr("stop-color", fillColor[colorIndex + 1]); 
                    gdefs.append("stop").attr("offset", "100%").attr("stop-color", fillColor[colorIndex]); 
                
                    var circle = that.svg.append("circle")
                        .attr("r", circleRadius)
                        .attr("id", "circle" + adcode)
                        .attr("fill", "url(#" + adcode + ")")
                        .attr("cx", center[0])
                        .attr("cy", center[1])
                        .attr("class", "child" + level)
                }
            }


            for (var i = 0; i < parentTags.length; i++) {
                for (var j = 1; j <= (level - 1); j++) {
                    if (parentTags[i].level === (level - j)) {
                        that.svg.append("path")
                            .attr("d", parentTags[i].path)
                            .attr("name", fillColor[parentTags.color])
                            .attr("class", "child" + level + " " + parentTags[i].adcode)
                            .attr("id", parentTags[i].adcode)
                            .style("fill-opacity", 0)
                            .style("stroke-width", "" + (0.5 + j * 0.5))
                            .style("stroke-linejoin", "round")
                            .style("stroke-linecap", "round")
                            .style("fill-rule", "evenodd")
                            .style("stroke", "rgb(255, 255, 255, 0.65)")
                            if (parentTags[i].type == "post") {
                                textList.push(parentTags[i]);
                            }
                    }
                }
            }


            for (var i = 0; i < textList.length; i++) {
                var text = that.svg.append("text")
                    .style("font-size", (fontSize[textList[i].level]) + "px")
                    .style("font-weight", "600")
                    .style("cursor", "pointer")
                    .style("text-anchor", "middle")
                    .attr("fill", fontColor[textList[i].color])
                    .attr("x", textList[i].x)
                    .attr("y", textList[i].y + (textList[i].type == "tag" ? -8 + 2*level : 8))
                    .attr("id", textList[i].adcode)
                    .attr("title", textList[i].name)
                    .attr("type", textList[i].type)
                    .attr("class", "child" + level)

                text.on("click", function() {
                    that.showArticle = false;
                    var e = event || window.event;
                    var x = e.offsetX;
                    var y = e.offsetY;
                    var type = this.getAttribute("type");

                    var ptags = d3.selectAll("#highlight");
                    for (var i = 0; i < ptags._groups[0].length; i++) {
                        ptags._groups[0][i].remove();
                    }

                    var selectPaths = document.getElementsByClassName(this.id);
                    var fillColor = selectPaths[0].style.fill;
                    fillColor = fillColor.replace("r", "").replace("g", "").replace("b", "").replace("(", "").replace(")", "");
                    var colors = fillColor.split(",");
                    var path = "";
                    for (var k = 0; k < selectPaths.length; k++) {
                        path = selectPaths[k].getAttribute("d");
                    }
                    that.svg.append("path")
                        .attr("d", path)
                        .attr("id", "highlight")
                        .attr("stroke-dasharray", "2 1")
                        .style("fill-opacity", 0)
                        .style("stroke-width", "1")
                        //.style("stroke", "rgba(255, 0, 0, 1)")
                        .style("stroke", "rgb(" + (255 - Number(colors[0])) + ", " + (255 - Number(colors[1])) + ", " + (255 - Number(colors[2])) + ")")
                        .style("pointer-events", "none")

                    that.lastSelectPaths = selectPaths;
                    that.showArticleSummary(this.getAttribute("title"), this.getAttribute("id"), x, y);
                })
                text.on("contextmenu", function() {
                    var e = event || window.event;
                    e.preventDefault();
                    var x = e.offsetX;
                    var y = e.offsetY;
                    that.showSelectMenu(this.getAttribute("title"), this.getAttribute("id"), x, y);
                })
                var docCount = textList[i].count;
                var r = textList[i].r;
                var x = textList[i].x;
                var y = textList[i].y;
                var type = textList[i].type
                if(type !== "tag"){
                    var textStr = textList[i].name;
                    textStr = textStr.replace(/\s/g,"#")
                    var layoutArray = this.genLayoutArray(textStr, r > 35 ? 35 : r, fontSize[textList[i].level]);
                    if(layoutArray.length >= 1){   
                        var layoutArrayEnd = layoutArray.slice(-1)[0]
                        var endStrArray = layoutArrayEnd.split(/#/)
                        endStrArray.pop()
                        var endStr = endStrArray.join("#")
                        layoutArray.pop()
                        layoutArray.push(endStr) 
                    }
                    var firstLayer = Math.ceil(layoutArray.length/2);
                    var fontSizeLevel = fontSize[textList[i].level] 
                    if (docCount) {
                        var dy = "-" + (fontSizeLevel * (firstLayer+1))+ "px"
                        text.append("tspan")
                            .text("(" + docCount + ")")
                            .attr("x", textList[i].x)
                            .attr("dy", dy);
                    }
                    for(var j = 0; j < layoutArray.length; j++) {
                        if(layoutArray[j]!==""){
                            if(docCount){
                                var dy = (fontSizeLevel +2) + "px";
                                text.append("tspan")
                                    .text(layoutArray[j])
                                    .attr("x", textList[i].x)
                                    .attr("dy", dy);
                            }
                            else{
                                var dy = "";
                                if (j==0) {
                                    dy = "-" + (fontSizeLevel * firstLayer) + "px";
                                }
                                else {
                                    dy = (fontSizeLevel +2) + "px";
                                }
                                text.append("tspan")
                                    .text(layoutArray[j])
                                    .attr("x", textList[i].x)
                                    .attr("dy", dy);
                            }   
                        }
                    }
                }
                else{
                    var textStr = textList[i].name   
                    var dy =  fontSize[textList[i].level] + "px" 
                    text.append("tspan")
                        .text(textStr)
                        .attr("x",textList[i].x)
                        .attr("dy",dy);
                }
            }
            //var ptags = d3.selectAll("text.child" + (level - 1));
            //for (var i = 0; i < ptags._groups[0].length; i++) {
            //    ptags._groups[0][i].style.display = "none";
            //}
            if (this.svg && d3.event) {
                this.svg.attr("transform", d3.event.transform);
            }
        },
        
        genLayoutArray: function(str, radius, fontSize) {
            var letterCount = 0;
            for(var i = 0; i < str.length; i++) {
                if (str.charCodeAt(i) > 255) { 
                    letterCount += 1;    
                }
                else {
                    letterCount += 0.5;   
                }
            }
            var layer = Math.floor(radius/fontSize) ;
            var perLayerLetterCount = [];
            for (var i = 0; i < layer; i++) {
                var layerLengthSquare = Math.pow(radius, 2) - Math.pow(fontSize * i, 2);
                var layerLength = Math.sqrt(layerLengthSquare) * 2;
                var layerLetterCount = Math.floor(layerLength / fontSize);
                perLayerLetterCount.push(layerLetterCount);
                perLayerLetterCount.push(layerLetterCount);
            }
            var addLetterCount = 0;
            var needLayerNumber = 0;
            var needLayerArray = [];
            for (var d = 0; d < perLayerLetterCount.length; d++) {
                addLetterCount += perLayerLetterCount[d];
                needLayerArray.push(perLayerLetterCount[d]);
                if (addLetterCount > (letterCount)) {
                    needLayerNumber = d;
                    break;
                }
            }
            var evenArray = []
            var oddArray = []
            for(var q = 0; q < needLayerArray.length; q++) {
                if (q % 2) {
                    evenArray.push(needLayerArray[q]);
                }
                else {
                    oddArray.push(needLayerArray[q]);
                }
            }
            oddArray.reverse();
            var newLayerArray = oddArray.concat(evenArray);
            var layersContent = [];
            var startNumber = 0;
            for (var n = 0; n < newLayerArray.length; n++) {
                var layerContent = "";
                layerContent = str.substr(startNumber, newLayerArray[n]);
                startNumber += newLayerArray[n];
                layersContent.push(layerContent);
            }
            var circleLetterCount = 0
            for(var j = 0; j < needLayerArray.length; j++){
                circleLetterCount += needLayerArray[j]
            }
            if(layersContent.length > 0){
                var layoutContentEnd = layersContent.slice(-1)[0]
                var endStrArray = layoutContentEnd.split(/#/)
                if(endStrArray.length<=1){
                    layersContent.pop() 
                }
            }
            return layersContent;
        },
 
        getQueryVariable: function(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
            }
            return(false);
        },

        init: function(el) {
            var that = this;

            if (this.detailTheme == "default"){
                //that.width = Number(el.parentNode.style.width.split("px")[0]);
                //that.height = Number(el.parentNode.style.height.split("px")[0]);
                //that.width = Number(el.offsetWidth);
                //that.height = Number(el.offsetHeight);
            }
            else{
                that.width = document.body.clientWidth
                that.height = document.body.clientHeight
            }

            console.log(this.width, this.height);
            

            that.parentTags = [];
            that.tags = [];

            /*var zoom = d3.zoom()
                .extent([[0, 0], [that.width, that.height]])
                .scaleExtent([0.6, 4])
                .on("zoom", that.zoomed);
            that.zoom = zoom;
            */

            that.tile = d3.tile()
                .extent([[0, 0], [that.width, that.height]])
                .tileSize(1);

            var size = 1.8;
            var height = 0, width = 0;
                width = that.height * 2 * size;
                height = that.height * size;


            that.mapWidth = width;
            that.mapHeight = height;

            if (that.uid.trim().length == 0) {
                return;
            }
            var postdata = {
                task: "GET_TOPIC_MAP",
                mode: this.mode,
                uid: that.uid,
                width: width,
                height: height
            };
            this.noData = false;
            this.loading = true;
            that.post("topic", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        that.loading = false;
                        var features = {
                            type: "FeatureCollection",
                            features: [
                                data.global
                            ]
                        }
                        that.globalFeature = features;
                        that.timestamp = data.timestamp;
                        if (data.data.length == 0) {
                            that.noData = true;
                            that.$emit("select", "adcode", (new Date()).valueOf());
                            return;
                        }
                        /*var maxLevel = data.data[0].properties.max_level;
                        if (!maxLevel) {
                            maxLevel = 3;
                        }

                        var zoom = d3.zoom()
                            .extent([[0, 0], [that.width, that.height]])
                            .scaleExtent([0.6, maxLevel + 1])
                            .on("zoom", that.zoomed);
                        that.zoom = zoom;

                        that.projection = d3.geoMercator().fitSize([width, height], features);
                        var p2 = that.projection([0, 45]);
                        var p1 = that.projection([0, 0]);
                        var midH = (p1[1] + p2[1])/2.0;
                        that.startX = width / 2.0  - that.width / 2.0;
                        that.startY = midH - that.height / 2.0;
                        document.getElementById("map").innerHTML = "";

                        that.svg = d3.select("#map")
                            .append("svg")
                            .attr("viewBox", [that.startX, that.startY, that.width, that.height])
                            .call(zoom)
                            .append("g")
                            .attr("cursor", "grab");

                        that.svg.append("rect")
                            .attr('width', that.width)
                            .attr('height', that.height)
                            .style('stroke', '#fafafa')
                            .style("stroke-width", "0")
                            .style('fill', 'none');

                        that.$emit("select", data.data[0].properties.adcode, that.timestamp);

                        that.drawMap(data.data, 1, []);
                        */
                        that.setupMap(data.data);
                    } else {
                        that.noData = true;
                        that.loading = false; 
                    }
                }).catch(function (e) {
                    console.log(e);
                    that.loading = false; 
                })
        },

        setupMap: function(data) {
            var that = this;
            var maxLevel = data[0].properties.max_level;
            if (!maxLevel) {
                maxLevel = 3;
            }

            var zoom = d3.zoom()
                .extent([[0, 0], [that.width, that.height]])
                .scaleExtent([0.6, maxLevel + 1])
                .on("zoom", that.zoomed);
            that.zoom = zoom;

            that.projection = d3.geoMercator().fitSize([that.mapWidth, that.mapHeight], that.globalFeature);
            var p2 = that.projection([0, 45]);
            var p1 = that.projection([0, 0]);
            var midH = (p1[1] + p2[1])/2.0;
            that.startX = that.mapWidth / 2.0  - that.width / 2.0;
            that.startY = midH - that.height / 2.0;
            document.getElementById("map").innerHTML = "";

            that.svg = d3.select("#map")
                .append("svg")
                .attr("viewBox", [that.startX, that.startY, that.width, that.height])
                .call(zoom)
                .append("g")
                .attr("cursor", "grab");

            that.svg.append("rect")
                .attr('width', that.width)
                .attr('height', that.height)
                .style('stroke', '#fafafa')
                .style("stroke-width", "0")
                .style('fill', 'none');

            that.$emit("select", data[0].properties.adcode, that.timestamp);

            that.drawMap(data, 1, []);
        }
    },
    created() {
        var mode = localStorage.getItem("mode");
        if (mode) {
            this.mode = mode;
        }
        var detailTheme =  this.$store.getters.getDetailTheme();
        if (detailTheme){
            this.detailTheme = detailTheme
        }
        else{
            this.detailTheme = "default"
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.allIcon{
    width: 100%;
    height: 20px;
    position: absolute;
}

.icon{
    float: right;
    margin-top: 9px;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.cloudIcon{
    background-image: url(../assets/img/cloud.png);
}

.cloudIcon:hover{
    background-image: url(../assets/img/hover/cloud.png);
}

.cloudHover{
    background-image: url(../assets/img/hover/cloud.png);
}

.docIcon{
    background-image: url(../assets/img/doc.png);
}

.docIcon:hover{
    background-image: url(../assets/img/hover/doc.png);
}

.docHover{
    background-image: url(../assets/img/hover/doc.png);
}

.tagIcon{
    background-image: url(../assets/img/tag.png);
}

.tagIcon:hover{
    background-image: url(../assets/img/hover/tag.png);
}

.tagHover{
    background-image: url(../assets/img/hover/tag.png);
}

.fullScreenIcon{
    background-image: url(../assets/img/zoomIn.png);
}

.fullScreenIcon:hover{
    background-image: url(../assets/img/hover/zoomIn.png);
}

.defaultScreenIcon{
    background-image: url(../assets/img/zoomOut.png);
}

.defaultScreenIcon:hover{
    background-image: url(../assets/img/hover/zoomOut.png);
}
.a {
  color: #42b983;
}

.zoom {
    position: fixed; top: 20px; color: rgba(0, 0, 0, 0.65);
}

.zoom-body{ 
    color: rgba(0, 0, 0, 0.65);
    font-size: 20px; 
    margin: 4px;
    background-color: white; 
    border: 1px solid #dddddd;
    box-shadow: 0 0 2px;
}

.zoom-button {
    cursor: pointer;
    color: #9e9e9e;
    font-weight: 600;
}

.zoom-button:hover {
    color: #2196f3;
}

.map{
    width: 100%;
    height: 100%;
    margin: auto;
    position:relative;
}

.title {
    color: rgba(255, 255, 255, 0.65);
    position: fixed;
    padding: 8px;
}

#map {
    width: 100%;
    height: 100%;
}

.loading {
    position: absolute; 
    top: 40%; 
    left: 20%;
    width: 240px; 
    background-color: #ffffff; 
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.75);
}

.no-loading {
    position: absolute; 
    top: 45%; 
    width: 100%; 
    color: rgba(0, 0, 0, 0.65);
}

.mode-list {
    position: absolute;
    bottom: 30px;
    right: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: white;
}

.mode-item {
    display: inline-block;
    padding: 8px;
    color: rgba(0, 0, 0, 0.65);
}

.mode-item-active {
    color: #1890ff;
}

.mode-item:hover {
    color: #1890ff;
}

.tag-menu {
    padding: 8px;
    position: fixed;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.24);
}

.tag-menu-item {
    cursor: pointer;
}

.tag-menu-item:hover {
    color: #1890ff;
}

.data-stats {
    position: absolute; 
    bottom: 6px; 
    left: 10px;
    color: rgba(0, 0, 0, 0.65); 
    font-size: 14px; 
    padding-right: 4px;
}

/deep/ .ant-modal-body {
    padding: 0px;
}
</style>
