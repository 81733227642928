<template>
    <a-col :span="24" style="height: 100% ">
        <a-row style="margin: auto; paddingTop: 14%; max-width: 320px">
            <a-tabs tabPosition="top" defaultActiveKey="account" @change="">
                <a-tab-pane tab="新用户注册" key="account">
                    <a-form class="register-form">  
                        <a-form-item style="margin-bottom: 0px">
                            <a-input placeholder="手机号" v-model='user.phone' rules="[{ required: true, message: '请输入手机号码' }]">
                                <a-icon slot="prefix" type="phone" />
                            </a-input>
                        </a-form-item>

                        <a-form-item style="margin-bottom: 0px">
                            <a-row>
                                <a-col :span="16">
                                    <a-input placeholder="验证码" v-model='user.valcode' rules="[{ required: true, message: '请输入验证码' }]">
                                        <a-icon slot="prefix" type="check-circle" />
                                    </a-input>
                                </a-col>
                                <a-col :span="8">
                                    <a-button 
                                        type="primary" 
                                        @click="sendsms($event)"
                                        :disabled="user.verifyButton">
                                        发送验证码
                                    </a-button>
                                </a-col>
                            </a-row>
                        </a-form-item>
                       
                        <a-form-item style="margin-bottom: 0px">
                            <a-input placeholder="用户名" 
                                v-model='user.name' 
                                v-on:change="checkName()"
                                rules="[{ required: false, message: '请输入用户名'}]">
                            <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,0.25)"/></a-input>
                            <a-alert v-if="user.msgNameCheck != ''" :message="user.msgNameCheck" type="error"/>
                        </a-form-item>

                        <a-form-item style="margin-bottom: 0px">
                            <a-input type="password" placeholder="密码" 
                                v-model='user.passwd' 
                                v-on:change="checkPasswd()"
                                rules="[{ required: false, message: '请输入密码'}]">
                                <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                        </a-form-item>
                        <a-form-item style="margin-bottom: 0px">
                            <a-input id='passwdConfirm' type="password" placeholder="重复一遍密码" 
                                v-model='user.passwdConfirm' rules="[{ required: false, message: '请输入确认密码'}]" 
                                v-on:change="checkPasswd()">
                                <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,0.25)"/>
                            </a-input>
                            <a-alert v-if="user.msgPasswdCheck != ''" :message="user.msgPasswdCheck" type="error"/>
                        </a-form-item>
                        <a-form-item style="margin-bottom: 0px">
                            <a-input id='inviteCode' placeholder="请输入您收到的邀请码，如果有的话" 
                                v-model='user.inviteCode' rules="[{ required: false, message: '请输入您收到的邀请码，如果有的话'}]">
                            </a-input>
                        </a-form-item>
                        <a-form-item style="text-align: left; margin-bottom: 0px">
                            <a-input type="checkbox" :checked="false" @change="checkUA($event)"></a-input>
                            &nbsp;我已阅读并同意<router-link to="/user/ua" target="_blank">《爱助手用户协议》</router-link>
                        </a-form-item> 
                        <a-form-item>
                            <a-button type="primary" @click="verifycode" class="register-form-button" :disabled="!user.uaChecked">注册</a-button>
                            <p style="float: left">已注册用户请&nbsp;&nbsp;</p>
                            <a @click="Login()" style="color: #1890ff; float: left"> 登录</a>
                        </a-form-item>

                    </a-form>
                </a-tab-pane>
            </a-tabs>
        </a-row>
    </a-col>
</template>

<script>
export default {
  name: "Register",
  components: {
  },
  data() {
    return {
      user: {
        phone: '',
        valcode: '',
        verifyButton: false,
        val: "",
        name: '',
        passwd: '',
        passwdConfirm: '',
        inviteCode: '',
        uaChecked: false, 
        msgNameCheck: '', 
        msgPasswdCheck: ''
      }
    }
  },
  methods: {

    Login: function () {
        let that = this;
        that.$router.push('/login');
    },

    sendsms: function(e) {
        let phone = this.user.phone;
        if (!phone) {
           this.$message.info("请输入手机号码");
           return;
        }
        if (phone.split("").length != 11) {
            this.$message.info("手机号码错误");
            return;
        }
        try{
            let d = parseInt(phone);
        } catch(e) {
            this.$message.info("手机号码错误");
            return;
        }
        let postdata = {
            phone: phone
        }
        this.user.verifyButton = true
        let that = this;
        let time = 20
        let timer = setInterval(function ()
        {
            e.target.innerText = time + 's'
            time = time - 1
            if (time == 0)
            {    
                clearInterval(timer)
                e.target.innerText = '获取验证码'
                that.user.verifyButton = false
            }
        }, 1000)
        that.post('/sms/login', postdata)
         .then(function (data) {
             if (data.ret == 200) {
                 that.val = data.data.code;
                 that.$message.success("验证码发送成功");
             } else {
                 that.$message.error("验证码发送失败，请重新发送!");
                 that.user.verifyButton = false
             }
         }).catch(function (e) {
           alert(e);
         });

    },
    checkUA: function(e) { 
      this.user.uaChecked = e.target.checked; 
    }, 
    verifycode: function() {
        localStorage.clear()
        let phone = this.user.phone;
        let valcode = this.user.valcode.trim();
        if (!phone)
        {
            this.$message.info("请输入手机号！");
            return; 
        }
        phone = phone.trim();
        if (phone.length != 11 || !parseInt(phone)) {
            this.$message.info("请输入正确的手机号！");
            return; 
        }
        if (!valcode)
        {
            this.$message.info("请输入验证码！");
            return; 
        }
        if (this.user.msgNameCheck != '')
        {
            this.$message.error(this.user.msgNameCheck);
            return; 
        }
        if (this.user.msgPasswdCheck != '')
        {
            this.$message.error(this.user.msgNameCheck); 
            return;
        }
        let username = phone;
        let name = this.user.name;
        let passwd = this.user.passwd;
        valcode = this.$crypto.SHA256(valcode).toString();
        if (this.val == valcode) {
          let that = this;
          let data = {
            phone: phone,
            code: valcode,
            username: name,
            passwd: this.$crypto.SHA1(passwd).toString(),
            task: "REGISTER",
          }
          if (this.user.inviteCode != "") {
            data["invite_code"] = this.user.inviteCode;
          }
          that.post('register', data)
           .then(function (response) {
             let data = response;
             if (data.ret == 200) {
                that.$message.success("注册成功")
             } else if (data.ret == 5001) {
                 that.$message.error("此用户名已经存在，请重试");
             } else if (data.ret == 5002) {
                 that.$message.error("此手机号码已经注册，请直接使用手机号登录");
             } else {
                 that.$message.error("注册失败");
             }
           }).catch(function (e) {
             alert(e);
           });
            
        } else {
            this.$message.error("验证码错误");
        }
    },

    checkName: function() {
        //console.log("In checkName: " + this.user.name); 
        let name = this.user.name; 
        if (!name || name.length == 0)
        { 
            this.user.msgNameCheck = '';
            return; 
        }

        //user name can not contain the following special characters: 
        var forbiddenUsernameChars = /[\\\^\$\*\? ]/g;
        if (name.search(forbiddenUsernameChars) >= 0)
            this.user.msgNameCheck = "用户名中不能有\、^、$、?、空格等特殊字符"; 
        else
            this.user.msgNameCheck = '';

        return;
    }, 

    checkPasswd: function() {
        let passwd = this.user.passwd;
        let passwdConfirm = this.user.passwdConfirm;

        if (!passwd || passwd.length == 0) 
        {
            this.user.msgPasswdCheck = "密码不能为空"; 
            return; 
        }

        //now check if the passwd meets security rule: a mix of uppercase letters/lowercase letters/digits/special characters: 
        var mustHaves = [ /[a-z]/g, /[A-Z]/g, /[0-9]/g ]; //, /[!@#\$%\^&\*\(\)_\-\+=\?]/g ]; 
        var missingOne = false; 

        for (var m = 0; m < mustHaves.length; m++) 
        {
            if (passwd.search(mustHaves[m]) < 0) 
            {
                //console.log(mustHaves[m] + " is missing from passwd"); 
                missingOne = true; 
                break; 
            }
        }

        if (missingOne){
            this.user.msgPasswdCheck = "密码需同时包含大写字母、小写字母、及数字。"; //、及特殊字符!@#$%^&*()_-+=等组合而成";
            return; 
        } 
        else{
            this.user.msgPasswdCheck = ""; 
        }
        //console.log( passwd + " " + passwdConfirm); 
        if (passwd != passwdConfirm) {
          this.user.msgPasswdCheck = "两次输入的密码不匹配";
          return; 
        }
    },
  }}
</script>
<style>
    .register-form-button {
        width: 100%;
    }
</style>

