<template>
</template>

<script>
  export default {
    name: "Logout",
    components: {
    },
    data() {
      return {
      }
    },
    methods: {
    },
    created() {
      this.$store.commit('setagentkey', "");
      this.$store.commit('clear');
      this.$store.commit('setlogin', false);
      this.$store.commit('setagentkey', "");
      this.$store.commit('settoken', "");
      let that = this;
      setTimeout(function() {
          window.location.href = "/";
      }, 500);
    }
  }
</script>

<style scoped>
</style>
