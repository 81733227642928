<template>
    <div class="chart-panel">
        <div class="chart-body">
            <div id="trending" style="width: 600px; height: 250px;" :style="autoDisplay"></div>
        </div>
    </div>
</template>

<script>
import echarts from 'echarts'

export default {
    name: 'Charts',
    props: {
        trending: {
            type: Object,
            default: function() {
                return {
                    data: [],
                    time: []
                }
            }
        }
    },
    data() {
        return {
            echarts: echarts,
            showChart: true,
            autoDisplay: {"display": "block"}
        }
    },
    mounted() {
        this.drawTrending();
    },
    watch: {
        trending: function(newvalue, oldvalue) {
            this.trending = newvalue;
            this.drawTrending();
        }
    },
    methods: {

        drawTrending: function() {
            let that = this;
            let myChart = this.echarts.init(document.getElementById("trending"), "light");
            myChart.setOption({
                title: {
                    text: "相似文章变化图",
                    textStyle: {
                        fontSize: 14
                    },
                    left: "center"
                },
                grid: {
                  left: '50px',
                  bottom:'50px',
                  right: '50px',
                  top: '30px'
                },
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        align: "left"
                    }
                },
                xAxis: {
                  type: 'category',
                  data: that.trending.time,
                },
                yAxis: {
                  type: 'value',
                  minInterval: 1,
                },
                series: [
                    {
                        data: that.trending.data,
                        type: "line",
                        smooth: true
                    }
                ]
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.chart-panel {
    height: 258px;
    border-radius: 0px 4px 4px 0px;
}

.chart-body {
    margin: auto;
    max-width: 1000px;
    padding-top: 8px;
}

</style>
