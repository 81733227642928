<template>
	<a-col :span="24">
        <loading :active.sync="loading" :can-cancel="true" color="rgb(47, 185, 248)" :opacity="0.1" :is-full-page="true"/>
        <div :class="'article-list topic-' + theme + '-theme-body'" :style="articleHeight">
            <div v-if="!loading && analysisState && detailTheme == 'default'" :class="'analysisContainer topic-' + theme + '-theme-chart'">
                <article-origin :articleAnalysis="originPie" :class="'articleOrigin topic-' + theme + '-theme-chart'" :width="width"/>
                <article-trend :articleAnalysis="trendStack" :class="'articleTrend topic-' + theme + '-theme-chart'" :width="width"/>
                <article-cloud :weightedWords = "frequencyCloud" :class="'articleCloud topic-' + theme + '-theme-chart'" :style="cloudWidth"/>
            </div>
            <div v-if="!loading && analysisState && detailTheme == 'fullScreen'" :class="'analysisContainer topic-' + theme + '-theme-chart'" style="height: 115%;">
                <article-origin :articleAnalysis="originPie" :class="'articleOrigin topic-' + theme + '-theme-chart'" :width="width"/>
                <article-trend :articleAnalysis="trendStack" :class="'articleTrend topic-' + theme + '-theme-chart'" :width="width"/>
                <article-cloud :weightedWords = "frequencyCloud" :class="'articleCloud topic-' + theme + '-theme-chart'" :style="cloudWidth"/>
            </div>
            <a-list item-layout="vertical" :data-source="articles">
                <a-list-item slot="renderItem" key="item.title" slot-scope="item, index"  style="border-top: none; border-bottom: none;"
                    :class="'topic-' + theme + '-theme-article-item'"
                    @mouseenter="showTooltip(index)" @mouseleave="hideTooltip(index)">
                    <div :style="listItemClass(item.use2gen)">
                    <img v-if="item.use2gen" class="attachTag" src="../assets/img/attachCorner.png"></img>
                    <a-list-item-meta description="" >
                        <a-row slot="title" type="flex" align="middle">
                            <a-col v-if="detailTheme == 'default'":span="20">
                                <a :href="item.profile_url" style="font-size: 20px;" target="_blank" :class="'topic-' + theme + '-theme-article-title'">
                                    {{ item.screen_name }}
                                </a>
                                <a-progress
                                    style="width: 64px; margin-right: 24px;"
                                    :stroke-color="{
                                        '0%': '#0BCDC6',
                                        '100%': '#4FF2AA',
                                    }"
                                    :percent="parseFloat((item.score * 100).toFixed(2))"
                                />
                            </a-col>
                            <a-col v-if="detailTheme == 'fullScreen'":span="24">
                                <a :href="item.profile_url" :title="item.screen_name" 
                                    style="font-size: 16px; overflow: hidden; line-height: 24px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1" target="_blank" 
                                    :class="'topic-' + theme + '-theme-article-title'">
                                    {{ item.screen_name }}
                                </a>
                                <!--a-progress
                                    style="width: 64px; margin-right: 24px;"
                                    :stroke-color="{
                                        '0%': '#0BCDC6',
                                        '100%': '#4FF2AA',
                                    }"
                                    :percent="parseFloat((item.score * 100).toFixed(2))"
                                /-->
                            </a-col>
                            <a-col :span="24">
                                <a-progress
                                    style="width: 64px; margin-right: 24px;"
                                    :stroke-color="{
                                        '0%': '#0BCDC6',
                                        '100%': '#4FF2AA',
                                    }"
                                    :percent="parseFloat((item.score * 100).toFixed(2))"
                                />
                                <a-tooltip place="top">
                                    <template slot="title">
                                        <span>发送通知</span>
                                    </template>
                                    <div v-if="item.showTooltip" class="icon sendInfoIcon" @click="showContactPanel(index)"/>
                                </a-tooltip>
                                <a-tooltip place="top">
                                    <template slot="title">
                                        <span>文章趋势</span>
                                    </template>
                                    <div v-if="item.showTooltip" class="icon trendIcon" @click="showStatsPanel(item._id, item.data_source)"/>
                                </a-tooltip>
                                <a-tooltip place="top">
                                    <template slot="title">
                                        <span v-if = "item.showTooltip && !item.use2gen">关联至当前话题节点</span>
                                        <span v-else-if = "item.showTooltip && item.use2gen">与当前话题节点分离</span>
                                    </template>
                                    <div v-if="item.showTooltip && !item.use2gen"  class="icon attachIcon" @click="use2Gen($event, index)"/>
                                    <div v-else-if="item.showTooltip && item.use2gen" class="icon detachIcon" @click="use2Gen($event, index)"/>
                                    <div v-else-if="item.showTooltip" class="icon attachIcon" @click="use2Gen($event, index)"/>
                                </a-tooltip>
                            </a-col>
                        </a-row>
                        <a-avatar slot="avatar" v-if="item.profile_image_url" :src="item.profile_image_url" />
                    </a-list-item-meta>
                    <article-content :article="item"/>
                    </div>
                    <a-row><div :class="'cutOff topic-' + theme + '-theme-body'"></div></a-row>
                </a-list-item>
            </a-list>
            <a-row v-if="noMoreRecords" style="border-top: 1px solid #dcdcdc;">
                <h2 style="color: rgba(128, 128, 128, 0.65); font-size: 16px; padding: 24px 0px;">没有更多数据了</h2>
            </a-row>
            <a-row v-if="!loading && !noMoreRecords" style="padding: 8px;">
                <a-button type="primary" @click="getNextPage()">加载更多</a-button>
            </a-row>
        </div>
        <a-modal v-model="showArticleStats" title="文章趋势" :bodyStyle="{'padding': '0px'}" :footer="null" :width="600" @cancel="closePanel">
            <article-stats :trending="articleStats"/>
        </a-modal>
        <a-modal v-model="showContact" title="发送通知" :bodyStyle="{'padding': '0px'}" :footer="null" :width="600" @cancel="closePanel" 
            :closable="closable">
            <contact :article="contactArticle" @cancel="closePanel" @ok="closePanel"/>
        </a-modal>
	</a-col>
</template>

<style scoped>

.analysisContainer{
    width:100%;
    height:78%;
    border-bottom: 7px solid #F2F2F2;
}

.articleOrigin{
    border-right: 6.5px solid #F2F2F2;
    border-bottom: 6.5px solid #F2F2F2;
    float:left;
    width:30%;
    height:50%;
}

.articleTrend{
    border-bottom: 6.5px solid #F2F2F2;
    float:left;
    height:50%;
}

.articleCloud{
    float:left;
    width:100%;
    height:40%;
    padding-top:20px;
    padding-bottom:20px;
    padding-left: 20px;
    padding-right: 20px;
}

.cutOff{
    height:5px;
    width:100%;
    background:#F2F2F2;
}

.hideAnalysis{
    background-image:url(../assets/img/up.png);
    height:16px;
    width:16px;
    left:0px;
    top:0px;
    background-size:contain;
    background-repeat: no-repeat;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
    cursor:pointer;
}

.hideAnalysis:hover{
    background-image:url(../assets/img/hover/up.png);
}

.searchContent{
    width:70%;
    height:40px;
}

.article-list {
    padding: 0px 0px; 
    overflow-y: scroll;
}

/deep/ .ant-list-item {
    padding: 0px 0px;
}

/deep/ .ant-list-empty-text {
    display: none;
}

/deep/ .ant-list-item-meta{
    width: 100%;
    height: 36px;
}

/deep/ .ant-list-split .ant-list-item{
    border-bottom:1px solid #F2F2F2;
}

/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 4px; 
    padding-left: 40px; 
}

/deep/ a{
    color: #4D4F53;
}

.icon{
    width: 24px;
    height: 24px;
    float: right;
    cursor: pointer;
    margin-left: 4px;
    background-size: contain;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;

}

.sendInfoIcon{
    background-image:url(../assets/img/sendInfo.png)
}

.sendInfoIcon:hover{
    background-image:url(../assets/img/hover/sendInfo.png)
}

.trendIcon{
    background-image:url(../assets/img/trend.png)
}

.trendIcon:hover{
    background-image:url(../assets/img/hover/trend.png)
}

.attachIcon{
    background-image:url(../assets/img/attach.png)
}

.attachIcon:hover{
    background-image:url(../assets/img/hover/attach.png)
}

.detachIcon{
    background-image:url(../assets/img/detach.png)
}

.detachIcon:hover{
    background-image:url(../assets/img/hover/detach.png)
}

.attachTag{
    width: 40px;
    height: 40px;
    position: absolute;
    top: -1px;
    left: -1px;
}

.addTag{
    background: #fff;
    border-style: dashed;
    height: 20px;
    cursor: pointer;
}

.addTag:hover{
    border-color: #4168FF;
}

</style>
<script>

import TimeAgo from "vue2-timeago";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ArticleStats from './ArticleStats.vue';
import Contact from './Contact.vue';
import ArticleOrigin from './ArticleOrigin.vue';
import ArticleTrend from './ArticleTrend.vue';
import ArticleCloud from './ArticleCloud.vue';
import ArticleContent from './ArticleContent.vue';


export default({
    name: "topic-article",
    components: {
        TimeAgo,
        Loading,
        ArticleStats,
        Contact,
        ArticleOrigin,
        ArticleTrend,
        ArticleCloud,
        ArticleContent
    },
    props: {

        adcode: {
            type: String,
            default: function() {
                return "";
            }
        },

        mapId: {
            type: String,
            default: function () {
                return ""
            }
        },

        time: {
            type: Number,
            default: function () {
                return 0
            }
        },

        topicTree: {
            type: Array,
            default: function () {
                return []
            }
        },

        filters: {
            type: Object,
            default: function() {
                return {};
            }
        },

        height: {
            type: Number,
            default: function() {
                return 0;
            }
        },

        width: {
            type: Number,
            default: function() {
                return 0;
            }
        },

        analysisState:{
            type: Boolean,
            default: function(){
                return true;
            }
        },

        topicMind:{
            type: Object,
            default: function(){
                return {};
            }
        },

        force: {
            type: Number,
            default: function(){
                return 0;
            }
        }
    },
	data() {
		return {
            articles: [],
            pagination: {
                onChange: page => {
                },
                pageSize: 20,
            },
            closable:false,
            articleHeight: {},
            loading: false,
            showArticleStats: false,
            showContact: false,
            contactArticle: {},
            currentStatsId: "",
            currentStatsDataSource: "",
            currentIndex: 0,
            showIndex: -1,
            articleStats: {},
            page: 0,
            pageSize: 10,
            noMoreRecords: false,
            refresh: 0,
            topic:"",
            topicText:"",
            addTag: false,
            originPie:{},
            trendStack:{},
            frequencyCloud:[],
            articleExist: false,
            getStats: true,
            cloudWidth:{},
            theme: 'default',
            detailTheme: 'default'
        }
    },
    watch: {

        adcode: function(newValue, oldValue) {
            this.adcode = newValue;
            this.page = 1;
            this.getTopicDocs();
        },

        mapId: function(newValue, oldValue) {
            this.mapId = newValue;
            this.showIndex = -1;
        },

        filters: function(newValue, oldValue) {
            this.filters = newValue;
            this.page = 1;
            this.getTopicDocs();
        },

        force: function(newValue, oldValue) {
            console.log("force changed");
            this.getTopicDocs();
        },

        analysisState: function(newValue,oldValue){
            this.getStats = newValue
            let agentKey = this.$store.getters.getAgentkey();
            if (!agentKey) {
                this.$router.push("/");
                return;
            }
            if(newValue){
                this.loading = true
                let postdata = {
                    task: "GET_TOPIC_DOCS",
                    adcode: this.adcode,
                    uid: this.mapId,
                    from: 0,
                    to: 1,
                    timestamp: this.time,
                    agent_key: agentKey,
                    min_sim_score: this.minSimScore,
                    stats: true,
                    tags: true
                };
                let that = this
                that.post("topic", postdata).then(function(data){
                    that.loading = false
                    if (data.ret == 200) {
                        that.originPie = data.stats.data_source_stats
                        that.trendStack = data.stats.doc_time_stats
                        that.frequencyCloud = data.tags
                    }
                }).catch(function (e) {
                    that.loading = false
                    console.log(e);
                })
            }
        },
    },
	methods: {

        hideAnalysis: function(){
            this.showAnalysis = false
        },

        listItemClass: function(e){
            if(e){
                return "border: 1px solid #4168FF;border-radius:4px;height:auto;width:100%;text-align:left;padding:20px;position:relative;";
            }
            else{
                return "height:auto;width:100%;text-align:left;padding:20px;position:relative;"
            }
        },

        showTooltip: function(index) {
            if (this.showIndex >= 0 && this.articles[this.showIndex]) {
                this.$set(this.articles[this.showIndex], "openInput", false);
                this.$set(this.articles[this.showIndex], "showTooltip", false);
            }
            this.$set(this.articles[index], "showTooltip", true);
            this.showIndex = index;
        },

        hideTooltip: function(index) {
            if (!this.articles[index]["openInput"]) {
                this.$set(this.articles[index], "showTooltip", false);
                this.$set(this.articles[index], "showInput",false);
            }
        },

        showTipStill: function(index) {
            this.$set(this.articles[index], "openInput", true);
        },

        detachDoc: function(){
            this.attachState = true 
            this.detachState = false
        },

        attachDoc: function(){
            this.attachState = false
            this.detachState = true
        },

        findNodeText: function(topic,node_id){
            if(topic.id == node_id){
                this.topicText=topic.text
            } 
            if(topic.children){
                for(var ch = 0;ch < topic.children.length;ch++){
                    this.findNodeText(topic.children[ch],node_id)
                }
            }
        },

        use2Gen: function(e, index) {
            var IdArray = this.mapId.split("-")
            var nodeId = IdArray[1]
            var topic = this.topicMind
            this.findNodeText(topic,nodeId)
            let article = this.articles[index];
            let checked = article["use2gen"];
            if (!checked) {
                checked = true;
            } else {
                checked = false;
            }
            let docId = article._id;
            let dataSource = article.data_source;
            let isLogin = this.$store.getters.isLogin();
            if (!isLogin) {
                this.$router.push("/");
                return;
            }
            let agentKey = this.$store.getters.getAgentkey();
            if (!agentKey) {
                this.$router.push("/");
                return;
            }
            let postdata = {
                task: "ATT_DOC",
                doc_id: docId,
                node_id: this.mapId,
                data_source: dataSource,
                agent_key: agentKey
            };
            if (checked == false) {
                postdata.task = "DEL_DOC";
            }

            let that = this;
            that.post("topic", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        if (checked) {
                            that.$set(that.articles[index], "use2gen", true);
                            that.$set(that.articles[index], "attachState", false);
                            that.$set(that.articles[index], "detachState", true);
                            var attachDoc="当前文档已成功关联到\""+that.topicText+"\"节点上"
                            that.$message.info(attachDoc, 0.8);
                            that.$emit("changeTopicMind",data.data)
                        } else {
                            that.$set(that.articles[index], "attachState", true);
                            that.$set(that.articles[index], "detachState", false);
                            that.$set(that.articles[index], "use2gen", false);
                            var detachDoc="当前文档已成功与\""+that.topicText+"\"节点分离"
                            that.$message.info(detachDoc, 0.8);
                            that.$emit("changeTopicMind",data.data)
                        }
                    } else {
                        that.$message.error("文档操作失败，请重试...", 0.8);
                    }
                }).catch(function(e) {
                    console.log(e);
                    that.$message.error("文档操作失败，请重试...", 0.8);
                });
        },

        treeSelectChange: function(value, label, extra) {
            this.addTag = true
            let id = value;
            let tag = label[0];
            this.selectTag = tag;
            this.selectNodeId = id;
            let article = this.articles[this.currentIndex];
            let docId = article._id;
            let dataSource = article.data_source;
            let agentKey = this.$store.getters.getAgentkey();
            let isLogin = this.$store.getters.isLogin();
            if (!isLogin) {
                this.$router.push("/");
                return;
            }
            if (!agentKey) {
                this.$router.push("/");
                return;
            }
            let postdata = {
                task: "DOC_TAGGING",
                doc_id: docId,
                node_id: id,
                topic_id: this.mapId.substring(0, 20),
                data_source: dataSource,
                agent_key: agentKey,
                tag: tag
            };
            let that = this;
            that.post("topic", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        article["u_tags"] = data.data;
                        article["showInput"] = false;
                        that.$set(that.articles, that.currentIndex, article);
                    } else {
                        that.$message.error("标签添加失败，请重试...");
                    }
                }).catch(function(e) {
                    console.log(e);
                    that.$message.error("标签添加失败，请重试...");
                });
            
        },

        deleteUserTag: function(index, order) {
            this.addTag = true
            let article = this.articles[index];
            let docId = article._id;
            let dataSource = article.data_source;
            let isLogin = this.$store.getters.isLogin();
            if (!isLogin) {
                this.$router.push("/");
                return;
            }
            let agentKey = this.$store.getters.getAgentkey();
            if (!agentKey) {
                this.$router.push("/");
                return;
            }
            let postdata = {
                task: "DEL_DOC_TAG",
                doc_id: docId,
                data_source: dataSource,
                topic_id: this.mapId.substring(0, 20),
                agent_key: agentKey,
                order: order
            };
            let that = this;
            that.post("topic", postdata)
                .then(function(data) {
                    if (data.ret == 200) {
                        that.addTag = false
                        article["u_tags"] = data.data;
                    } else {
                        that.$message.error("标签删除失败，请重试...");
                    }
                }).catch(function(e) {
                    console.log(e);
                    that.$message.error("标签删除失败，请重试...");
                });
        },

        showTagInput: function(index) {
            this.$set(this.articles[this.currentIndex], "showInput", false);
            this.$set(this.articles[index], "showInput", true);
            this.currentIndex = index;
            this.selectTag = "";
            this.selectNodeId = "";
            this.$nextTick(function() {
                this.$refs.input.focus();
            });
        },

        closePanel: function() {
            this.showArticleStats = false;
            this.showContact = false;
        },

        showContactPanel: function(index) {
            this.showContact = true;
            this.contactArticle = this.articles[index];
        },

        showStatsPanel: function(id, dataSource) {
            this.currentStatsId = id;
            this.currentStatsDataSource = dataSource;
            let postdata = {
                task: "GET_DOC_STATS",
                doc_id: this.currentStatsId,
                data_source: this.currentStatsDataSource
            };
            this.loading = true;
            let that = this;
            that.post("topic", postdata).then(function(data) 
                {
                    that.loading = false;
                    if (data.ret == 200) {
                        that.showArticleStats = true;
                        that.articleStats = data.data;
                    } else {
                        that.$message.error("获取数据失败，请重试...");
                    }
                }).catch(function() {
                    that.$message.error("获取数据失败，请重试...");
                    that.loading = false;
                });
        },

        getNextPage: function() {
            this.article = false
            this.page += 1;
            this.getStats = false
            if (!this.noMoreRecords) {
                this.getTopicDocs();
            }
        },

        getTopicDocs: function() {
            let that = this;
            if (this.page == 1) {
                this.articles = [];
                this.noMoreRecords = false;
            }
            let isLogin = this.$store.getters.isLogin();
            if (!isLogin) {
                this.$router.push("/");
                return;
            }
            let agentKey = this.$store.getters.getAgentkey();
            if (!agentKey) {
                this.$router.push("/");
                return;
            }
            let postdata = {
                task: "GET_TOPIC_DOCS",
                adcode: this.adcode,
                uid: this.mapId,
                from: (this.page - 1) * this.pageSize,
                to: this.page * this.pageSize,
                timestamp: this.time,
                agent_key: agentKey,
                min_sim_score: this.minSimScore
            };
            if(this.getStats){
                postdata.stats = true 
                postdata.tags = true
            }
            else{
                postdata.stats = false 
                postdata.tags = false
            }
            for (var field in this.filters) {
                postdata[field] = this.filters[field];
            }
            this.loading = true;
            that.post("topic", postdata).then(function(data) 
                {
                    that.loading = false;
                    if (data.ret == 200) 
                    {
                        that.getStats = that.analysisState
                        if(data.stats){
                            that.originPie = data.stats.data_source_stats
                            that.trendStack = data.stats.doc_time_stats
                            that.frequencyCloud = data.tags
                        }
                        if (data.data.length == 0 && that.page > 1) {
                            that.noMoreRecords = true;
                        } else if (data.data.length == 0 && that.page == 1) {
                            that.noMoreRecords = true;
                            that.$message.warning("没有更多数据了！");
                        } else {
                            if (that.page==1){
                                that.articles=[]
                                for (var i = 0; i < data.data.length; i++) {
                                    that.articles.push(data.data[i]);
                                }
                            }
                            else{
                                for(var i = 0; i < data.data.length; i++){
                                    that.articles.push(data.data[i]);
                                }
                            }
                        }
                        that.articleExist = true
                    }
                }).catch(function (e) {
                    that.loading = false;
                    console.log(e);
                })
        }
    },

    created() {
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        this.theme = theme;
        var detailTheme =  this.$store.getters.getDetailTheme() 
        this.detailTheme = detailTheme;
        if (detailTheme == "fullScreen") {
            this.articleHeight = {"height": (this.height - 64) + "px", "overflow-x": "hidden"};
        } else {
            this.articleHeight = {"height": (this.height - 0) + "px", "overflow-x": "hidden"};
        }
        this.cloudWidth = {"width":(this.width)+"px"}
        let agentKey = this.$store.getters.getAgentkey();
    }
})
</script>

<style lang="less" scoped>
@import "../assets/css/topic.less";
</style>

<style lang="css" scoped>
/deep/ .ant-progress-text {
    color: #AAAAAA;
}
</style>
