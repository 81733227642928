import { MM } from '@/my-mind/my-mind'
import '../assets/css/my-mind.less'

//
// my mind component
// Use example:
// <topic-mind :topic="topic" :width="500" :height="300" :editable="true" @change="" @cancel="" />
//
// props description:
//     @topic (Object)
//         It will use this topic data to show the mind map;
//     @editable (true or false)
//         When set it to true the mind map can edit and draggable;
//         When set it to false the mind map can not edit and draggable, and the mind map will use transform scale to show the hole mind map;
//     @draggable (true or false)
//         When set it to true the mind map can drag and will reomve the transform scale of the mind map
//         When set it to false the mind map can not drag
//     @selectId (string)
//         When set it the node with id will selected
//     @preCommand (string) ["Desc", "Upload", "WhiteList", "BlackList"]
//         When set it will run the command to show the panel, it depent on the selectId
//     @text (string)
//         When set the preCommand "Desc"|"WhiteList"|"BlackList" it should has some text
//     @layout (string) ["map", "graph-left", "graph-right", "graph-top", "graph-bottom", "tree-left", "tree-right"]
//         When set layout it will change the display of the mindmap
//     @width (Number)
//         The mind map width in px
//     @height (Number)
//         The mind map height in px
//         

export default(
{
    name: "topic-mind",

    data(){
        return{
            mm: MM
        }
    },

    props: {

        topic: {
            type: Object,
            default: function() {
                return null;
            }
        },

        editable: {
            type: Boolean,
            default: function() {
                return false;
            }
        },

        draggable: {
            type: Boolean,
            default: function() {
                return false;
            }
        },

        editorShow: {
            type: Boolean,
            default: function() {
                return false;
            }
        },

        selectId: {
            type: String,
            default: function() {
                return "";
            }
        },

        preCommand: {
            type: String,
            default: function() {
                return "";
            }
        },

        text: {
            type: String,
            default: function() {
                return "";
            }
        },

        layout: {
            type: String,
            default: function() {
                return "";
            }
        },

        width: {
            type: Number,
            default: function() {
                return 382;
            }
        },

        height: {
            type: Number,
            default: function() {
                return 223.5;
            }
        },

        editorWidth:{
            type: Number,
            default: function(){
                return 192;
            }
        },

        globalMode:{
            type: String,
            default: function(){
                return "";
            }
        },

        theme:{
            type: String,
            default: function(){
                return "default";
            }
        }
    },

    mounted() {
        this.drawTopicMind(this.$el);
    },

    methods:{

        drawTopicMind: function(container){
            if (this.topic) {
                this.mm.App.init(container, this.width, this.height, this.topic, this.editable, this.editorShow, this.draggable, this.selectId, this.preCommand, this.text, this.layout,this.globalMode);
            }
        },

        save: function() {
            var data = this.mm.App.map.toJSON()
            var data = data.root
            data["topic_id"] = this.mm.topicId;
            data["theme"] = this.mm.theme;
            this.$emit("change", data)
        },

        mouseLeave: function() {
            var data = this.mm.App.map.toJSON()
            var data = data.root
            data["topic_id"] = this.mm.topicId;
            data["theme"] = this.mm.theme;
            this.$emit("mouseleave", data)
        },

        cancel: function() {
            this.$emit("cancel")
        }, 

        help: function(onoff) { 
            this.$emit("help", onoff);  
        },

        topicMindLayout: function(onoff){
            this.$emit("topicMindLayout", onoff); 
        }
    },

    render: function (h) {
        var right=this.editorWidth
        let that = this;
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        if (this.editorShow){
            var container = h(
                    "div",
                    {
                        style: {
                        },
                        class: "mindContainer topic-" + theme + "-theme-topic-bg",
                        on: {
                            "mouseleave": function() {
                                that.mouseLeave();
                            }
                        }
                    }, 
                    [
                        h("div", 
                            {
                                style: {
                                    "position": "fixed",
                                    "width": "60px",
                                    "left": "0px",
                                    "right": "-"+(right*0.94-10)+"px",
                                    "margin": "auto",
                                    "top": "65px",
                                    "z-index": "999",
                                    "border-top-width": "6px",
                                    "border-radius": "4px"
                                },
                                class: "ant-btn ant-btn-primary",
                                on: {
                                    "click": function(){
                                        that.save();
                                    }
                                }
                            },
                            "保存"),
                        h("div", 
                            {
                                style: {
                                    "position": "fixed",
                                    "width": "60px",
                                    "left": "0px",
                                    "right": "-"+(right*0.94-170)+"px",
                                    "margin": "auto",
                                    "top": "65px",
                                    "z-index": "999",
                                    "border-top-width": "6px",
                                    "border-radius": "4px"
                                },
                                class: "ant-btn ant-btn-danger",
                                on: {
                                    "click": function(){
                                        that.cancel();
                                    }
                                }
                            }, 
                            "取消"), 

                         h("div", 
                            {
                                style: {
                                    "position": "fixed",
                                    "width": "60px",
                                    "left": "0px",
                                    "right":  "-"+(right*0.94-330)+"px",
                                    "margin": "auto",
                                    "top": "65px",
                                    "z-index": "999",
                                    "border-top-width": "6px",
                                    "border-radius": "4px",
                                    "border-color": "#40A9FF",
                                    "color": "#40A9FF"
                                },
                                class: "ant-btn ant-btn-help",
                                on: {
                                    "click": function(e){
                                        that.help();
                                    } 
                                }
                            },
                            "帮助"),
                         h("div", 
                            {
                                style: {
                                    "position": "fixed",
                                    "width": "60px",
                                    "left": "0px",
                                    "right": "-"+(right*0.94-490)+"px",
                                    "margin": "auto",
                                    "top": "65px",
                                    "z-index": "999",
                                    "border-top-width": "6px",
                                    "border-radius": "4px",
                                    "border-color": "#40A9FF",
                                    "color": "#40A9FF"
                                },
                                class: "ant-btn ant-btn-help",
                                on: {
                                    "click": function(e){
                                        that.topicMindLayout();
                                    }
                                }
                            },
                            "布局")
                    ]
                );
        }
        else 
        {
            var container = h(
                    "div",
                    {
                        style: {
                        },
                        //class: "mindContainer my-mind-" + that.theme + "-bg",
                        class: "mindContainer topic-" + theme + "-theme-topic-bg",
                    }, 
                    "",
                    []
                );
        }
        return container;
    },
    created() {
        let that = this;
        window.addEventListener("message", function(e) {
            if (e.data && e.data.action) {
                switch (e.data.action) {
                    case "command-cancel":
                        var data = MM.App.map.toJSON()
                        var data = data.root
                        that.$emit("command-cancel", data);
                    break;
                }
            }

        });
    }

})
