<template>
    <div id="pieOrigin" :style="autoDisplay" @mouseenter="showTitle" @mouseleave="showTitle"></div>
</template>

<script>
import echarts from 'echarts'

export default {
    name: 'Charts',
    
    props: {

        articleAnalysis: {
            type: Object,
            default: function() {
                return {
                    data: [],
                    time: []
                }
            }
        },

        analysisState: {
            type: Boolean,
            default: function() {
                return false;
            }
        },

        width:{
            type: Number,
            default: function(){
                return 0;
            }
        }

    },
    
    data() {
        return {
            echarts: echarts,
            autoDisplay: {"display": "block"},
            pieData: "",
            showOriginTitle: false,
            theme: "default"
        }
    },
    mounted() {
        var myChartContainer = document.querySelector('#pieOrigin')
        // 获取自适应的高度和宽度
        let that = this
        var resizeMyChartContainer = function() {
            if (that.width > 500) {
                myChartContainer.style.height = window.innerHeight * 0.40 + 'px';
                myChartContainer.style.width = that.width* 0.30 + 'px';
            } else {
                myChartContainer.style.height = window.innerHeight * 0.18 + 'px';
                myChartContainer.style.width = that.width* 0.99 + 'px';
            }
        };
        // 设置容器高和宽
        resizeMyChartContainer();
        var myChart = echarts.init(myChartContainer);
        let option = {};
        myChart.setOption(option);
        // 自适应高和宽
        window.onresize = function () {
            resizeMyChartContainer();
            myChart.resize();
        }
        this.drawTrending()
    },
    watch: {

        articleAnalysis: function(newvalue, oldvalue) {
            this.trending = newvalue;
            this.drawTrending();
        },

        analysisState: function(){
            this.drawTrending();
        }

    },
    methods: {

        showTitle:function(){
            if(this.showOriginTitle){
                this.showOriginTitle = false
            }
            else{
                this.showOriginTitle = true
            }
        },

        drawTrending: function() {
            this.pieData = this.articleAnalysis.data
            let that = this;
            if (this.pieData && this.pieData.length > 0) {
                let dom = document.getElementById("pieOrigin");
                dom.style.display = "block";
                let myChart = this.echarts.init(document.getElementById("pieOrigin"), "light");
                myChart.setOption({             
                    title: {
                        text: "数据频道",
                        x: "center", 
                        y: "5",

                        textStyle: {
                            fontSize: 18,
                            fontWeight: 'normal',
                            color: '#aaaaaa'
                        },
                        show:true
                  
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {d}%'
                    },
                    color: ['#4168FF','#63DAAB','#657798','#EFBA1C'],
                    series: [{
                        type: 'pie',
                        name: '',
                        radius: ['40%', '60%'],
                        center: ['50%', '50%'],
                        label: {
                            formatter: '{d}%',
                            borderWidth: 1,
                            borderRadius: 4,
                            color: '#aaaaaa',
                            fontFamily: 'PingFangSC-Regular',
                            fontSize: '12px',
                            rich: {
                                a: {
                                    color: '#aaaaaa',
                                    lineHeight: 17,
                                    align: 'center'
                                }
                            }
                        },
                        data: that.pieData
                    }]
                });
            } else {
                let dom = document.getElementById("pieOrigin");
                dom.style.display = "none";
            }
        }
    },
    created() {
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        this.theme = theme;
    }
}
</script>
