<template>
	<div class="container">
        <loading :active.sync="showLoading" 
            :can-cancel="false"
            color="rgb(47, 185, 248)"
            :opacity="0.1"
            :is-full-page="true">
        </loading>
		<div :class="'pageHeader topic-' + theme + '-theme-head'"><div class="headerPanel"> 
            <table padding=0 cellspacing=0 width="100%"><tr>
                <td width="50px">
                    <div :class="'logoIcon topic-' + theme + '-theme-logo'"></div>
                </td> 
                <td width="130px">
                    <div :class="'topicLetterIcon topic-' + theme + '-theme-topic'"></div>
                </td> 
                <td width="410px" align="left"> 
                    <a-input-search
                      type='input'
                      :maxLength=60
                      :class="'topic-' + theme + '-theme-input'"
                      style="padding: 5px; width: 400px; margin-top: 5px; "
                      placeholder="请输入关键词进行话题搜索"
                      v-model="searchWords"
                      @search="toggleSearch"
                      id="search"/>
                </td>
                <td width="*" align="left">
                    <flat-selector style="padding: 0px 0px 4px 0px; width: 290px; height: 32px; margin-top: 5px;" 
                        :xs="24" :sm="24" :md="24" :lg="12" :xl="12" 
                        mode="tags" 
                       :class="'topic-' + theme + '-theme-input'"
                        holder="话题状态过滤" 
                        :items="topicStates" 
                        :defaultvalue="dfltTopicState" 
                        field="states" 
                        @selected='stateFilterChanged'/>
                </td>
                <td width="40px" style="text-align: -webkit-right;">
                    <div class="sudoku" @click="showModeSelect"></div>
                    <div id="modeSelect" style="right: -50px; display: none;" :class="'mode' + ' topic-' + theme + '-theme-topic-bg'" 
                        @mouseleave="hideSelect">
                        <p :class="'modeTitle topic-' + theme + '-theme-item-title'">深色模式</p>
                        <template>
                          <div class="modeSwitch">
                              <a-switch default-checked @change="changeTheme" :defaultChecked="theme=='default'?false:true" />
                          </div>
                        </template>
                    </div>
                </td>
                <td width="40px">
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>新建话题</span>
                        </template>
                        <div class="addNewTopic" style="margin-top:10px;" @click="openTopicEditor()"/>
                    </a-tooltip>
                </td>
                <td width="44px" @click="showLogin">
                    <user-menu v-if="isLogin"/>
                    <a-tooltip v-else="" placement="top">
                        <template slot="title">
                            <span>点击登录</span>
                        </template>
                        <img class="user" src="../assets/img/user.png">
                    </a-tooltip>
                </td>
            </tr></table> 
		</div></div>
		<div
            v-infinite-scroll="getNextPage"
            :infinite-scroll-disabled="isGetAllData"
            :infinite-scroll-distance="10"
            style="padding-top: 66px;"
            :class="'topic-' + theme + '-theme-body'"
        >
            <div :class="'navigation topic-' + theme + '-theme-body'" :style="{'min-height': editorModalHeight + 'px'}">
                <div :key = "refresh" style="text-align: left;" :class="'topic-' + theme + '-theme-body'">
                    <div v-for="(topic, index) in topics" :key="index" style="display: inline-block;">
                        <div :class="topicCardBgc(topic.enabled) + ' topic-' + theme + '-theme-item'" @mouseenter="showTooltip(index)" @mouseleave="hideTooltip(index)">
                            <div v-if="topic.showTooltip">
                                <div v-if="topic.enabled == -1">
                                    <a-tooltip place="top">
                                        <template slot="title">
                                            <span>彻底删除</span>
                                        </template>
                                        <a-popconfirm title="你确定彻底删除该话题么？"
                                            placement="bottom" ok-text="确认" cancel-text="取消"
                                            @confirm="purgeTopic(index,topic)"
                                            @cancel=""
                                        >
                                            <div class="topicIcon purgeIcon" @click="showTipStill(index)"/>
                                        </a-popconfirm>
                                    </a-tooltip>
                                    <a-tooltip place="top">
                                        <template slot="title">
                                            <span>恢复</span>
                                        </template>
                                        <a-popconfirm title="你确定恢复该话题么？"
                                            placement="bottom" ok-text="确认" cancel-text="取消"
                                            @confirm="recycleTopic(index,topic)"
                                            @cancel=""
                                        >
                                            <div class="topicIcon recycleIcon" @click="showTipStill(index)"/>
                                        </a-popconfirm>
                                     </a-tooltip>
                                </div>
                                <div v-else>
                                    <a-tooltip placement="top" v-if="topic.share && topic.share.modify">
                                        <template slot="title">
                                            <span>编辑话题</span>
                                        </template>
                                        <div class="topicIcon editIcon" @click="openTopicEditor(index, topic)"/>
                                    </a-tooltip>
                                    <a-tooltip place="top" v-if="topic.share && topic.share.modify">
                                        <template slot="title">
                                            <span v-if="topic.enabled">停止数据更新</span>
                                            <span v-else>恢复数据更新</span>
                                        </template>
                                        <a-popconfirm v-if="topic.enabled" title="你确定停止数据更新么？" 
                                            placement="bottom" ok-text="确认" cancel-text="取消" 
                                            @confirm="changeState(index,topic)" 
                                            @cancel=""
                                        >
                                            <div class="topicIcon disableIcon" @click="showTipStill(index)"/>
                                        </a-popconfirm>
                                        <a-popconfirm v-else title="你确定恢复数据更新么？" 
                                            placement="bottom" ok-text="确认" cancel-text="取消" 
                                            @confirm="changeState(index,topic)" 
                                            @cancel=""
                                        >
                                            <div class="topicIcon enableIcon" @click="showTipStill(index)"/>
                                        </a-popconfirm>
                                    </a-tooltip>
                                    <a-tooltip place="top" v-if="topic.share && topic.share.modify">
                                        <template slot="title">
                                            <span>删除话题</span>
                                        </template>
                                        <a-popconfirm title="你确定删除该话题么？" 
                                            placement="bottom" ok-text="确认" cancel-text="取消" 
                                            @confirm="delTopic(index, topic.topic_id)" 
                                            @cancel=""
                                        >
                                            <div @click="showTipStill(index)" class="topicIcon deleteIcon" />
                                        </a-popconfirm>
                                    </a-tooltip>
                                    <router-link :to="'/' + topic.topic_id">
                                        <a-tooltip place="top">
                                        <template slot="title">
                                            <span>话题详情</span>
                                        </template>
                                            <div v-if="globalMode=='analysis'&&topic.refresh_time && topic.refresh_time > 0" class="topicIcon enterIcon"/>
                                            <div v-if="globalMode=='modeling'" class="topicIcon enterIcon"/>
                                        </a-tooltip>
                                    </router-link>
                                    <a-tooltip place="top" v-if="topic.share && topic.share.reshare">
                                        <template slot="title">
                                            <span>分享话题给好友</span>
                                        </template>
                                        <div class="topicIcon shareIcon" @click="openTopicShare(index, topic)"/>
                                    </a-tooltip>
                                    <a-tooltip place="top">
                                        <template slot="title">
                                            <span>复制当前话题</span>
                                        </template>
                                        <div class="topicIcon copyIcon" @click="copyTopic(index,topic)"/>
                                    </a-tooltip>
                                </div>
                            </div>
                            <div v-else="">
                               <p class="topicIcon"></p>
                            </div>
                            <div v-if="globalMode == 'analysis' && topic.refresh_time && topic.refresh_time > 0"> 
                                <router-link :to="'/' + topic.topic_id">
                                    <topic-mind :topic="topic.topic" :editable="false" :globalMode="globalMode" :key="globalMode"/>
                                </router-link>
                            </div>
                            <div v-else-if="globalMode == 'modeling'">
                                <router-link :to="'/' + topic.topic_id">
                                    <topic-mind :topic="topic.topic" :editable="false" :globalMode="globalMode" :key="globalMode"/>
                                </router-link>
                            </div>
                            <div v-else> 
                                <topic-mind :topic="topic.topic" :editable="false" style="cursor:auto;" :globalMode="globalMode" 
                                    :key="globalMode"/>
                            </div>
                            <div class="topicTag">
                                <p :class="'topicTitle topic-' + theme + '-theme-item-title'">{{topic.title}}</p>
					            <p :class="'topicDesc topic-' + theme + '-theme-item-desc'">{{topic.content}}</p>
                                <p :class="'topicTime topic-' + theme + '-theme-item-time'">
                                    <span v-if="topic.refresh_time && topic.refresh_time > 0"> 
                                        数据于&nbsp;<time-ago :refresh="60" :datetime="topic.refresh_time" locale="zh_CN" :long="true"></time-ago>更新
                                    </span> 
                                    <span v-else=""> 
                                        数据未更新
                                    </span> 
                                    <span>&nbsp;|&nbsp;话题创建于
                                        <time-ago :refresh="60" :datetime="topic.create_time" locale="zh_CN" :long="true"></time-ago>&nbsp;
                                    </span> 
                                    <span v-if="topic.update_time && topic.update_time > 0"> 
                                        |&nbsp;修改于 <time-ago :refresh="60" :datetime="topic.update_time" locale="zh_CN" :long="true"></time-ago>
                                    </span> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <a-modal v-model="showTopicEditor" title="话题编辑器" 
                    :class="'topic-' + theme + '-theme-editor'"
                    :bodyStyle="{'padding': '0px'}" :footer="null" :width="editorModalWidth" :closable="closable" cancel="closeTopicEditor"> 
                    <topic-editor style="position:fixed; height:auto;width:auto;" 
                        :class="'topic-' + theme + '-theme-editor-body'"
                        @loading="toggleLoading"
                        @updateTopic="updateTopic" @dropTopic="closeTopicEditor" :key="refreshTopicEditor" :topicId="topicIDInEditor" :editorWidth="editorModalWidth" :editorHeight="editorModalHeight">
                    </topic-editor>
                </a-modal>
                <a-modal v-model="showTopicShare" title="分享设置" 
                    :bodyStyle="{'padding': '0px'}" :footer="null" :width="720" :closable="closable">
                    <topic-share @closeShare="closeTopicShare" :key="showTopicShare" :topicId="topicIDInShare"></topic-share>
                </a-modal>
			</div>
            <a-modal v-model="userLogin" title="用户登录" :bodyStyle="{'padding': '0px'}" :footer="null" :width="500" @cancel="closeLogin">
                <user-login @login="UserLogin"/>
            </a-modal>
		</div>
        <div :class="'footer topic-' + theme + '-theme-footer'"><p>京ICP备19033266  © 2020  仝睿科技</p></div>
	</div>
</template>

<script>

import TopicEditor from './TopicEditor.vue'
import TopicShare from './TopicShare.vue'
import {FlatSelector} from './Selector'
import TopicMind from './TopicMind.js'
import TimeAgo from "vue2-timeago";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import infiniteScroll from 'vue-infinite-scroll';
import UserLogin from './Login/Login.vue'
import UserMenu from './UserMenu.vue'
import "../assets/css/topic.less";

export default (
{
    components : {
        TopicEditor,
        TopicShare,
        TopicMind,
        Loading, 
        FlatSelector,
        UserLogin,
        UserMenu,
        TimeAgo
    },

    directives: { infiniteScroll },

	data() {
        return {
            showLoading: false, 
            searchWords: "", 
            showTopicEditor: false,
            editorModalWidth: "",
            editorModalHeight:"",
            showTopicShare: false,
            activeTopics : "",
            topicIDInEditor: "",
            topicIDInShare:"",
            editorIndex: "",
            currentIndex: -1,
            deleteIndex : "",
            topics : [],
            refresh : 1,
            refreshTopicEditor: 1,
            closable : false,
            dfltTopicState: [],  
            searchData: {}, 
            userLogin: false,
            isLogin: false,
            topicStates: [
                {   name: "正常", value: "normal" },  
                {   name: "停更", value: "disabled" },  
                {   name: "已删", value: "deleted" },
                {   name: "全部", value: "all" }  
            ],
            isGetAllData: false,
            currentPage: 0,
            pageSize: 10,
            copyState: false,
            globalMode:"analysis",
            modeValue: ["analysis"],
            global: false,
            theme: "default"
		}
	},

    created(){
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        this.theme = theme;
        let mode = this.getQueryVariable("modeling");
        var modelingState = mode == 'true'? true : false;
        if(modelingState){
            this.$store.commit("changeMode","modeling")
        }
        else{
            this.$store.commit("changeMode","analysis")
        }
        let global = this.getQueryVariable("global");
        this.global = global == 'true' ? true : false;
        if (this.global) {
            this.$store.commit('setGlobal', true);
            localStorage.setItem('global', true);
            this.$store.commit('setlogin', true);
            localStorage.setItem('login', true);
            this.$store.commit('setagentkey', "167b6c4a4e415fdfc65024a01a1d46b38344ab1b");
            localStorage.setItem('agent_key', "167b6c4a4e415fdfc65024a01a1d46b38344ab1b");
            this.$store.commit('settoken', "true");
            localStorage.setItem('access_token', true);
            this.isLogin = true;
            let postdata = {
                "task": "GET_TOPIC_CONFIG"
            };
            var that = this;
            document.body.style.width = "100%";
            that.post('topic', postdata)
            .then(function(data) {
                if(data.ret ==200) {
                    let dataSource = data.data.data_source;
                    that.$store.commit('updateDataSource', data.data.data_source);
                } else {
                }
            }).catch(function(e) {
                console.log(e);
            });
        }
        this.topics = [];
        this.isLogin = localStorage.getItem('login');
        this.editorModalHeight = document.body.clientHeight - 86;
    },

	methods:{

        changeTheme: function(value) {
            var theme = ''
            if(value){
                theme = "dark"
            }
            else{
                theme = "default"
            }
            this.theme = theme;
            this.$store.commit('setTheme', theme);
            this.refresh += 1;
            
        },

        getQueryVariable: function(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
            }
            return 'false';
        },

        showModeSelect: function(){
            var modeSelect = document.querySelector("#modeSelect");
            if(modeSelect.style.display == "none"){
                modeSelect.style.display = "block"
            }
            else{
                modeSelect.style.display = "none"
            }

        },

        hideSelect: function(){
            var modeSelect = document.querySelector("#modeSelect");
            modeSelect.style.display = "none"
        },

        showLogin: function () {
            if (!this.$store.getters.isLogin()) {
                this.userLogin = true;
            }
        },

        closeLogin: function () {
            this.userLogin = false;
        },

        UserLogin: function(state) {
            if (state) {
                this.isLogin = true;
                this.userLogin = false;
            }
        },

        topicCardBgc: function(topicEnabled){
            if (topicEnabled==1){
                return "topicCard"
            }
            else if(topicEnabled==0){
                return "topicCard disabledTopicCard"
            }
            else if(topicEnabled==-1){
                return "topicCard deletedTopicCard"
            }
        },

        toggleLoading: function(onoff){
            this.showLoading  = onoff; 
        }, 

        showTooltip: function(index) {
            if (this.currentIndex >= 0) {
                this.$set(this.topics[this.currentIndex], "showDel", false);
                this.$set(this.topics[this.currentIndex], "showTooltip", false);
            }
            this.$set(this.topics[index], "showTooltip", true);
            this.currentIndex = index;
        },

        hideTooltip: function(index) {
            if (!this.topics[index]["showDel"]) {
                this.$set(this.topics[index], "showTooltip", false);
            }
        },

        showTipStill: function(index) {
            this.$set(this.topics[index], "showDel", true);
        },

        getNextPage: function() {
            if (this.isGetAllData) {
                return;
            }
            this.currentPage += 1;
            this.searchTopic(); 
        },

        stateFilterChanged : function(data, field) {
            this.searchData[field] = data[field]; 
            this.currentPage = 1;
            this.topics = [];
            this.isGetAllData = false;
            this.searchTopic(); 
        },  
        
        toggleSearch: function() {
            this.currentPage = 1;
            this.topics = [];
            this.isGetAllData = false;
            this.searchTopic(); 
        },

        searchTopic: function(kw) {   
            var mode = this.$store.getters.getMode()
            if(!mode){
                this.globalMode = "analysis"
                this.modeValue = ["analysis"]
            }   
            else{
                this.globalMode = mode
                this.modeValue = []
                this.modeValue.push(mode)
            }
            if (this.currentPage <= 1) {
                this.topics = [];
            }
            this.searchData['task'] = "GET_TOPICS"; 
            let agent_key = localStorage.getItem('agent_key');
            let access_token = localStorage.getItem('access_token');
            this.isLogin = localStorage.getItem('login');
            if (!this.isLogin && !this.global) {
                this.userLogin = true;
                return;
            }
            this.searchData['agent_key'] = agent_key; 
            this.searchData["from"] = (this.currentPage - 1) * this.pageSize;
            this.searchData["to"] = this.currentPage * this.pageSize;
            if (kw) 
                this.searchData['keywords'] = kw; 
            else
                this.searchData['keywords'] = this.searchWords; 
            let that = this
            that.showLoading = true; 
            that.post("topic",that.searchData).then(function (res){
                that.showLoading = false; 
                if (res && res.ret == 200 && res.msg == "ok"){
                    if (res.data.length == 0) {
                        that.isGetAllData = true;
                        that.$message.warning("没有更多数据了！", 0.5);
                    } else {
                        for (var i = 0; i < res.data.length; i++) {
                            that.topics.push(res.data[i]);
                        }
                    }
                }
            })
        },

        deleteTopicCount: function(topic){
            delete topic.count
            if(topic.children){
                for(var ch = 0;ch < topic.children.length;ch++){
                    this.deleteTopicCount(topic.children[ch])
                }
            }
        },

        copyTopic: function(index,topic){
            //delete topic_id,create_time,refresh_time,update_time in topic, delete topic_id,count in topic.topic
            let addTopic = {}
            for (var object in topic){
                addTopic[object] = topic[object]
            }
            delete addTopic.topic_id
            delete addTopic.create_time
            delete addTopic.refresh_time
            delete addTopic.update_time
            addTopic["task"] = "ADD_TOPIC"
            var title = topic.title + "(副本)" 
            addTopic["title"] = title
            var topicMind = addTopic.topic
            delete topicMind.topic_id
            this.deleteTopicCount(topicMind)
            addTopic["topic"] = topicMind
            let that = this
            let agent_key = this.$store.getters.getAgentkey();
            this.isLogin = this.$store.getters.isLogin();
            if (!this.isLogin &&  this.global) {
                this.$message.info("您还没有登录!");
                this.userLogin = true;
                return;
            }
            let countData={
                "agent_key" : agent_key,
                "task" : "CNT_TOPIC"
            }
            that.post("topic",countData).then(function(res){
                if (res && res.ret == 200 && res.msg == "ok"){
                    that.activeTopics = res.data.enabled 
                    if (that.activeTopics > 9){
                        that.$message.warning("您的活跃话题数已达到上限！")
                    }
                    else{
                        that.post("topic",addTopic).then(function (res){
                            if(res && res.ret ==200 && res.msg == "ok"){
                                that.currentIndex = -1 
                                that.currentPage = 1;
                                setTimeout(function() {
                                that.searchTopic();
                                }, 800);
                            }
                        })
                    }
                       
                }
            })
        },

        updateTopic: function () {
            this.showTopicEditor = false
            this.currentPage = 1;
            let that = this;
            setTimeout(function() {
                that.searchTopic();
            }, 800);
        },
        
        openTopicEditor : function(index, topic) {
            this.editorModalWidth = document.body.clientWidth*0.95;
            this.editorModalHeight = document.body.clientHeight-120;
            if (index >= 0){
                this.editorIndex = index
                this.topicIDInEditor = topic.topic_id
                this.showTopicEditor = true;
                this.refreshTopicEditor += 1;
            }
            else{
                this.topicIDInEditor = "";
                let agent_key = this.$store.getters.getAgentkey();
                this.isLogin = this.$store.getters.isLogin();
                if (!this.isLogin && !this.global) {
                    this.$message.info("您还没有登录!");
                    this.userLogin = true;
                    return;
                }
                let countData={
                    "agent_key" : agent_key,
                    "task" : "CNT_TOPIC"
                }
                let that=this
                that.post("topic",countData).then(function(res){
                    if (res && res.ret == 200 && res.msg == "ok"){
                        that.activeTopics = res.data.enabled 
                        if (that.activeTopics > 9){
                            that.$message.warning("您的活跃话题数已达到上限！")
                            that.showTopicEditor = false;
                        }
                        else{
                            that.showTopicEditor = true;
                        }
                        that.topicIDInEditor = "";
                        that.refreshTopicEditor += 1;
                   }
                })
            }
        },
        
        openTopicShare : function(index, topic){
            this.topicIDInShare = topic.topic_id
            this.showTopicShare = true;
        },

        closeTopicShare: function(){
            this.showTopicShare = false;
        },

        unEditable : function(){
            this.$message.warning("当前话题已停止数据更新")
        },

        closeTopicEditor : function(e) {
            this.topicIDInEditor = "";
            this.showTopicEditor = false;
            if(e){
                this.updateTopic()
            }
        },

        changeState : function(index,topic){
            if (topic.enabled){
                let that=this
                let postdata = {
                    "topic_id" : topic.topic_id,
                    "enabled" : 0,
                    "task" : "UPD_TOPIC"
                }
                that.showLoading = true; 
                that.post("topic",postdata).then(function(res){
                    that.showLoading = false; 
                    if (res && res.ret == 200 && res.msg == "ok"){
                        that.$set(that.topics[index], "enabled", 0);
                    }
                })
            }
            else{
                this.topicIDInEditor = "";
                let agent_key = this.$store.getters.getAgentkey();
                this.isLogin = this.$store.getters.isLogin();
                if (!this.isLogin && !this.global) {
                    this.$message.info("您还没有登录!");
                    this.userLogin = true;
                    return;
                }
                if (!agent_key) {
                    this.$message.info("您还没有登陆!");
                    return;
                }
                let countData={
                    "agent_key" : agent_key,
                    "task" : "CNT_TOPIC"
                }
                let that=this
                that.showLoading = true;
                that.post("topic",countData).then(function(res){
                    that.showLoading = false;
                    if (res && res.ret == 200 && res.msg == "ok"){
                        that.activeTopics = res.data.enabled 
                        if (that.activeTopics > 9){
                            that.$message.warning("您的活跃话题数已达到上限！")
                            that.showTopicEditor = false;
                        }
                        else{
                            let postdata = {
                                "topic_id" : topic.topic_id,
                                "enabled" : 1,
                                "task" : "UPD_TOPIC"
                            }
                            that.showLoading = true; 
                            that.post("topic", postdata).then(function(res){
                                that.showLoading = false; 
                                if (res && res.ret == 200 && res.msg == "ok"){
                                    that.$set(that.topics[index], "enabled", 1);
                                }
                            })
                        }
                    }    
                })
            }
        },

        delTopic : function(index,topicId) {
            let deleteData = {
                "topic_id" : topicId,
                "task" : "DEL_TOPIC"
            }
            
            var that = this; 

            that.showLoading = true; 
            this.post("topic", deleteData).then( function(res) { 
                that.showLoading = false; 
             
                if (res && res.ret == 200 && res.msg == "ok"){
                    that.$set(that.topics[index], "enabled",-1);
                    that.$set(that.topics[index],"delete",1)
                }
                that.currentIndex = -1 
            }); 
        },

        purgeTopic : function(index,topic){
            let purgeData = {
                "topic_id" : topic.topic_id,
                "task" : "DEL_TOPIC",
                "purge" : "true"
            }
            let that=this
            that.showLoading = true
            this.post("topic",purgeData).then(function(res){
                that.showLoading = false
                if (res && res.ret == 200 && res.msg == "ok"){
                    var topics = that.topics;
                    var newTopics = [];
                    for (var i = 0; i < topics.length; i++) {
                        if (i != index) {
                            newTopics.push(topics[i]);
                        }
                    }
                    that.topics = newTopics;
                    that.refresh += 1;
                    that.currentIndex = -1
                }

            })
        },

        recycleTopic : function(index,topic){
            let agent_key = this.$store.getters.getAgentkey();
            this.isLogin = this.$store.getters.isLogin();
            if (!this.isLogin && !this.global) {
                this.$message.info("您还没有登录!");
                this.userLogin = true;
                return;
            }
            if (!agent_key) {
                this.$message.info("您还没有登陆!");
                return;
            }
            let countData={
                    "agent_key" : agent_key,
                    "task" : "CNT_TOPIC"
            }
            let that=this
            that.showLoading = true;
            that.post("topic",countData).then(function(res){
                that.showLoading = false;
                that.activeTopics = res.data.enabled 
                if (that.activeTopics > 9){
                    that.$message.warning("您的活跃话题数已达到上限！")
                    that.showTopicEditor = false;
                }
                else{
                    let recycleData ={
                        "topic_id": topic.topic_id,
                        "task" : "UPD_TOPIC",
                        "enabled": 1,
                        "deleted": 0
                    }
                    that.showLoading = true
                    that.post("topic",recycleData).then(function(res){
                        that.showLoading = false
                        if (res && res.ret == 200 && res.msg == "ok"){
                            that.$set(that.topics[index], "enabled", 1);
                            that.$set(that.topics[index],"deleted",0);
                        }
                    })
                }

            })
        }
    }
})
</script>


<style lang="less" scoped>
@import "../assets/css/topic.less";
</style>
<style lang="scss" scoped>

@import "../my-mind/css/style.css";
@import "../assets/css/topic.css";

.modeTitle{
    float:left;
}

.modeSelect{
    float:right;
}
.sudoku{
    width: 30px;
    margin-top: 10px;
    height: 30px;
    background-image: url(../assets/img/sudoku.png);
    background-size: contain;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
}
.sudoku:hover{
    background-image: url(../assets/img/hover/sudoku.png);
 
}
.topic-editor-title > .ant-modal-header {
    background-color: red;
}

.analysisModeIcon{
    width: 30px;    
    height: 30px;
    margin: auto;
    margin-top: 10px;
    background-image: url(../assets/img/up.png)
}

.analysisModeIcon:hover{
    background-image: url(../assets/img/up.png)
}

.modelingModeIcon{
    width: 30px;    
    height: 30px;
    margin: auto;
    margin-top: 10px;
    background-image: url(../assets/img/down.png)
}

.modelingModeIcon:hover{
    background-image: url(../assets/img/down.png)
}

.dataModeIcon{
    width: 30px;    
    height: 30px;
    margin: auto;
    margin-top: 10px;
    background-image: url(../assets/img/down.png)
}

.dataModeIcon:hover{
    background-image: url(../assets/img/down.png)
}

.mode{
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    position: fixed;
    z-index: 999;
    margin: auto;
    overflow: hidden;
    border:1px solid #B5B5B5;
    box-shadow:0px 4px 12px 0px rgba(0,0,0,0.24); 
    border-radius: 4px;
    padding: 10px;
    width: 140px;
    height: 45px;
}

.title p{
    float:right;
    margin-top:16px;
    font-size:14px;
    margin-right:5px;
}

.topicList{
    top: 55px;
    padding-bottom: 48px;
    position: absolute;
    margin: 0 auto;
    left: 0px;
    right: 0px;
	background: #F2F2F2;
}

.navigation{
	max-width:1242px;
	margin:0 auto;
}

.topicCard{
	width:392px;
	height:368px;
    margin: 11px;
	border-radius:8px;
    &.cssTransforms {
        transition: all 200ms ease;
        transition-property: box-shadow, transform;
    }   
}

.disabledTopicCard{
    opacity:0.75;
}

.deletedTopicCard{
    opacity:0.45;
}

.topicTag{
    width: 392px;
}

.addNewTopic{
    width: 30px; 
    height: 30px; 
    margin: auto;
    cursor:pointer;
    background-image:url(../assets/img/add.png);
    background-size:contain;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
}

.addNewTopic:hover{
    background-image:url(../assets/img/hover/add.png)

}

.topicIcon{
    height:24px;
    width:24px;
    margin-top: 8px;
    margin-right: 8px;
    margin-bottom: 5px;
    cursor: pointer;
    float: right;
    background-size:contain;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
}

.purgeIcon{
    background-image:url(../assets/img/purge.png)
}

.purgeIcon:hover{
    background-image:url(../assets/img/hover/purge.png)
}

.recycleIcon{
    background-image:url(../assets/img/recycle.png)
}

.recycleIcon:hover{
    background-image:url(../assets/img/hover/recycle.png)
}

.editIcon{
    background-image:url(../assets/img/edit.png)
}

.editIcon:hover{
    background-image:url(../assets/img/hover/edit.png)
}

.disableIcon{
    background-image:url(../assets/img/disable.png)
}

.disableIcon:hover{
    background-image:url(../assets/img/hover/disable.png)
}

.enableIcon{
    background-image:url(../assets/img/enable.png)
}

.enableIcon:hover{
    background-image:url(../assets/img/hover/enable.png)
}

.deleteIcon{
    background-image:url(../assets/img/delete.png)
}

.deleteIcon:hover{
    background-image:url(../assets/img/hover/delete.png)
}

.enterIcon{
    background-image:url(../assets/img/enter.png);
}

.enterIcon:hover{
    background-image:url(../assets/img/hover/enter.png);
}

.shareIcon{
    background-image:url(../assets/img/share.png);
}

.shareIcon:hover{
    background-image:url(../assets/img/hover/share.png);
}

.copyIcon{
    background-image:url(../assets/img/copy.png);
}

.copyIcon:hover{
    background-image:url(../assets/img/hover/copy.png);
}

.topic p{
	margin-left:24px;
	text-align:left;
}

.topicTitle{
    position: absolute;
	height: 17px;
	font-size: 17px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	/*color: #404040;*/
	line-height: 17px;
	margin-top: 22px;
	margin-left: 12px;
}

.topicDesc{
    width: 360px;
    position: absolute;
    overflow: hidden; 
    text-align: left; 
	height:30px;
	font-size:13px;
	font-family:PingFangSC-Regular, PingFang SC;
	font-weight:400;
	line-height:15px;
	margin-top:46px;
	margin-left:12px;
	margin-right:22px;
}

.topicTime{
    position: absolute;
	height: 12px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	line-height: 12px;
	float: left;
	margin-top: 80px;
    margin-left: 12px;
}

</style> 

<style scoped> 

.mind{
	width:368px;
	height:240px;
	border:1px aquamarine solid;
}

.mindContainer{
    width:380px;
    overflow: hidden;
    margin-left: 5px; 
    margin-right: 5px; 
    position:relative;
}

/deep/ .ant-input-affix-wrapper .ant-input-suffix{ 
    right: 20px; 
}

/deep/ .ant-modal{
    top:55px;
}

/deep/ .ant-message-notice-content { 
    background: lightyellow; 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}

/deep/ .tooltip { 
    border-radius: 8px; 
}

/deep/ .v-time-ago__text {
    font-size: 12px; 
}

/deep/ .ant-list-empty-text {
    display: none;
}

/deep/ .ant-modal-header{
    padding-left: 15px;
}

</style>
