<template>
    <a-row v-if="topicData != null">
        <div @mouseenter="showTooltip" @mouseleave="hideTooltip" :class="mindBgc(enabled)">
            <div @mouseenter="showIconStill" id="layoutSelect" @click="hideSelect" :style="layoutStyle" 
                :class="'layout'+' topic-'+theme+'-theme-topic-bg'">
                <button value="map" @click="changeLayout('map')"><p :class="'topic-' + theme + '-theme-item-title'">经典脑图</p></button>
                <span></span>
                <div class="genericName"><p :class="'topic-' + theme + '-theme-item-title'">图状</p></div>
                <button value="graph-right" @click="changeLayout('graph-right')">
                    <p :class="'topic-' + theme + '-theme-item-title'">子节点在右</p>
                </button>
                <button value="graph-left" @click="changeLayout('graph-left')">
                    <p :class="'topic-' + theme + '-theme-item-title'">子节点在左</p>
                </button>
                <button value="graph-bottom" @click="changeLayout('graph-bottom')">
                    <p :class="'topic-' + theme + '-theme-item-title'">子节点在下</p>
                </button>
                <button value="graph-top" @click="changeLayout('graph-top')">
                    <p :class="'topic-' + theme + '-theme-item-title'">子节点在上</p>
                </button>
                <span></span>
                <div label="树状" class="genericName"><p :class="'topic-' + theme + '-theme-item-title'">树状</p></div>
                <button value="tree-right" @click="changeLayout('tree-right')">
                    <p :class="'topic-' + theme + '-theme-item-title'">子节点向右摆</p>
                </button>
                <button value="tree-left" @click="changeLayout('tree-left')" class="bottom" >
                    <p :class="'topic-' + theme + '-theme-item-title'">子节点向左摆</p>
                </button>
                <span></span>
                <div label="主题颜色" class="genericName">
                    <p :class="'topic-' + theme + '-theme-item-title'">脑图主题颜色</p>
                </div>
                <button @click="changeMindColor('default')">
                    <p :class="'topic-' + theme + '-theme-item-title'">蓝色系</p>
                </button>
                <button @click="changeMindColor('gold')">
                    <p :class="'topic-' + theme + '-theme-item-title'">金色系</p>
                </button>
                <button @click="changeMindColor('green')">
                    <p :class="'topic-' + theme + '-theme-item-title'">绿色系</p>
                </button>
                <button @click="changeMindColor('red')">
                    <p :class="'topic-' + theme + '-theme-item-title'">红色系</p>
                </button>
                <button @click="changeMindColor('purple')">
                    <p :class="'topic-' + theme + '-theme-item-title'">紫色系</p>
                </button>
                <button @click="changeMindColor('pink')">
                    <p :class="'topic-' + theme + '-theme-item-title'">粉色系</p>
                </button>
            </div>
            <div style="width: 100%; height: 40px;">
                <div v-if="showIcon">
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>脑图布局</span>
                        </template>
                        <div v-if="selectDown" class="topicIcon downIcon" @click="openSelect"/>
                        <div v-else class="topicIcon upIcon" @click="hideSelect"/> 
                    </a-tooltip>
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>编辑话题</span>
                        </template>
                        <div class="topicIcon editIcon" @click="toggleShowEditor"/>
                    </a-tooltip>
                    <a-tooltip place="top">
                        <template slot="title">
                            <span v-if="this.enabled">停止数据更新</span>
                            <span v-else>恢复数据更新</span>
                        </template>
                        <a-popconfirm v-if="this.enabled" title="你确定停止数据更新么？" 
                            placement="bottom" ok-text="确认" cancel-text="取消" 
                            @confirm="changeState" 
                            @cancel=""
                        >
                            <div class="topicIcon disableIcon" @click="showTipStill"/>
                        </a-popconfirm>
                        <a-popconfirm v-else title="你确定恢复数据更新么？" 
                            placement="bottom" ok-text="确认" cancel-text="取消" 
                            @confirm="changeState" 
                            @cancel=""
                        >
                            <div class="topicIcon enableIcon" @click="showTipStill"/>
                       </a-popconfirm>
                    </a-tooltip>
                    <a-tooltip place="top">
                        <template slot="title">
                            <span>分享话题给好友</span>
                        </template>
                        <div class="topicIcon shareIcon" @click="changeShareState"/>
                    </a-tooltip>
                    <a-tooltip place="top">
                        <template slot="title">
                            <span>合并关联文档</span>
                        </template>
                        <div class="topicIcon genWordIcon" @click="genDoc"/>
                    </a-tooltip>
                    <a-tooltip place="top">
                        <template slot="title">
                            <span>刷新分类数据</span>
                        </template>
                        <div @click="syncMap" class="topicIcon refreshIcon"/>
                    </a-tooltip>
                </div>
            </div>
            <topic-mind :topic="topicData" :editable="editable" :draggable="draggable" :selectId="selectId" :width="width" :height="height" 
                :layout="layout" :key="refreshMind" :globalMode="globalMode"/>
        </div>
    </a-row>
</template>
<script>

import TopicMind from './TopicMind.js'

export default({
    name: "mind-wrapper",
    data(){
        return {
            selectDown: true,
            topicData: this.topic,
            editable: false,
            draggable: true,
            refresh: 0,
            refreshMind: 1,
            showIcon: false,
            showEnabled: "",
            layout: "",
            showSelect: false,
            globalMode: "",
            layoutStyle:{"display": "none"},
            theme: ""
        }
    },
    components : {
        TopicMind
    },
    props: {

        topic: {
            type: Object,
            default: function() {
                return {
                    "id": "id",
                    "text": "text",
                    "notes": "",
                    "layout": "map"
                }
            }
        },

        height: {
            type: Number,
            default: function() {
                return 0;
            }
        },

        width: {
            type: Number,
            default: function() {
                return 0;
            }
        },

        Id:{
            type:String,
            default:function(){
                return "";
            }
        },

        enabled: {
            type:Number,
            default:function(){
                return 1;
            }
        },

        selectId: {
            type: String,
            default: function() {
                return "";
            }
        }
    },
    watch: {
        selectId: function(newValue, oldValue) {
            this.selectId = newValue;
        }
    },
    created() {
        var theme = localStorage.getItem("theme");
        if (!theme) {
            theme = "default";
        }
        this.theme = theme;


        var globalMode = this.$store.getters.getMode()

        if(globalMode==""){
            this.globalMode = "analysis"
        }
        else{
            this.globalMode = globalMode
        } 
        this.draggable = true;
        var that = this;

        window.addEventListener("message", function(e) {
            if (e.data && e.data.action) {
                switch (e.data.action) {
                    case "clickContent":
                        that.$emit("changeMap", e.data.value);
                    break;
                }
            }

        });
    },
    methods: {

        changeMindLayout: function(map,layout){
            map.layout = layout
            if(map && map.children){
                for(var i=0;i < map.children.length; i++){
                    this.changeMindLayout(map.children[i],layout)
                }
            }
            this.topicData = map
        },

        changeMindColor: function(color){
            var topicMind = this.topicData
            topicMind.theme = color
            let changeTopicColor = {
                "topic_id" : this.Id,
                "topic" : topicMind,
                "task" : "UPD_TOPIC",
                "theme" : color
            }
            let that=this
            this.post("topic",changeTopicColor).then(function(res){
                if (res && res.ret == 200 && res.msg == "ok"){
                    that.topicData.theme = color
                    that.refreshMind += 1
                } else {
                    that.$message.error("脑图更新设置失败")
                }
            }).catch(function(e) {
                that.$message.error("error")
            });
        },

        changeLayout: function(e){
            var layout = e
            var map = this.topicData
            this.changeMindLayout(map,layout)
            let changeTopic = {
                "topic_id" : this.Id,
                "topic" : this.topicData,
                "task" : "UPD_TOPIC"
            }
            let that=this
            this.post("topic",changeTopic).then(function(res){
                if (res && res.ret == 200 && res.msg == "ok"){
                     that.layout = e
                     that.refreshMind += 1
                } else {
                    that.$message.error("脑图更新设置失败")
                }
            }).catch(function(e) {
                that.$message.error("error")
            });

        },

        openSelect: function(){
            var select=document.querySelector("#layoutSelect")
            select.style.display = ""
            this.layoutStyle = {"display":"block","height":this.height - 15+"px","overflow-y":"auto"}
            this.selectDown = false
        },

        hideSelect: function(){
            var select = document.querySelector("#layoutSelect")
            select.style.display = "none"
            this.selectDown = true
        },

        showIconStill: function(){
            this.showIcon = true;
        },

        genDoc: function() {
            window.location.href = this.BACKEND_API_PREFIX + "download/" + this.Id;
        },

        syncMap: function() {
            if(this.globalMode == "analysis"){
                let that=this
                let changeState = {
                    "topic_id" : that.Id,
                    "sync_type" : 1,
                    "task" : "UPD_TOPIC"
                }
                that.showLoading = true; 
                that.post("topic",changeState).then(function(res){
                    that.showLoading = false; 
                    if (res && res.ret == 200 && res.msg == "ok"){
                        that.$message.info("数据已经开始更新")
                    } else {
                        that.$message.error("数据更新设置失败")
                    }
                }).catch(function(e) {
                    that.$message.error("数据更新设置失败")
                });
            }
            else{
                let that=this
                let changeState = {
                    "topic_id" : that.Id,
                    "task" : "UPD_TOPIC",
                    "trained": 0
                }
                that.showLoading = true; 
                that.post("topic",changeState).then(function(res){
                    that.showLoading = false; 
                    if (res && res.ret == 200 && res.msg == "ok"){
                        that.$message.info("数据开始训练")
                    } else {
                        that.$message.error("数据训练设置失败")
                    }
                }).catch(function(e) {
                    that.$message.error("数据训练设置失败")
                    console.log(e)
                });
            }
        },

        mindBgc: function(){
            if(this.enabled){
                return "enabledBgc";
            }
            else{
                return "disabledBgc";
            }
        },

        showTooltip: function(){
            this.showIcon = true
            this.showEnabled = false
        },

        hideTooltip: function(){
            if(!this.showEnabled){
                this.showIcon = false
            }
            if(!this.showSelect){
                var select = document.querySelector("#layoutSelect")
                select.style.display = "none"
            }
            this.selectDown = true
        },

        showTipStill: function(){
            this.showEnabled = true
        },

        toggleShowEditor: function(){
            this.$emit("openEditor")
        },

        changeShareState : function(){
            this.$emit("openShare")
        },

        changeState: function(){
            if (this.enabled){
                let that=this
                let changeState = {
                    "topic_id" : that.Id,
                    "enabled" : 0,
                    "task" : "UPD_TOPIC"
                }
                that.showLoading = true; 
                let isLogin = this.$store.getters.isLogin();
                if (!isLogin) {
                    this.$router.push("/");
                    return;
                }
                let agentKey = this.$store.getters.getAgentkey();
                if (!agentKey) {
                    this.$router.push("/");
                    return;
                }
                that.post("topic",changeState).then(function(res){
                    that.showLoading = false; 
                    if (res && res.ret == 200 && res.msg == "ok"){
                        that.enabled = 0
                    }
                })
            }
            else{
                this.topicIDInEditor = "";
                let isLogin = this.$store.getters.isLogin();
                if (!isLogin) {
                    this.$router.push("/");
                    return;
                }
                let agentKey = this.$store.getters.getAgentkey();
                if (!agentKey) {
                    this.$router.push("/");
                    return;
                }
                let countData={
                    "agent_key" : agentKey,
                    "task" : "CNT_TOPIC"
                }
                let that=this
                that.showLoading = true;
                that.post("topic",countData).then(function(res){
                    that.showLoading = false;
                    if (res && res.ret == 200 && res.msg == "ok"){
                        that.activeTopics = res.data.enabled 
                        if (that.activeTopics > 9){
                            that.$message.warning("您的活跃话题数已达到上限！")
                            that.showTopicEditor = false;
                        }
                        else{
                            let changeState = {
                                "topic_id" : that.Id,
                                "enabled" : 1,
                                "task" : "UPD_TOPIC"
                            }
                            that.showLoading = true; 
                            that.post("topic",changeState).then(function(res){
                                that.showLoading = false; 
                                if (res && res.ret == 200 && res.msg == "ok"){
                                    that.enabled = 1
                                }
                            })
                        }
                    }    
                })
            }
        }
    }
});
</script>
<style scoped>

.layout{
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    float: left;
    position: absolute;
    z-index: 999;
    //border-color: #829CFF;
    right: -110px;
    top: 35px;
    width:110px;
    overflow: hidden;
    border:1px solid #B5B5B5;
    box-shadow:0px 4px 12px 0px rgba(0,0,0,0.24); 
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    //border:1px solid #829CFF;
}

.layout span{
    display: block;
    border-top: 1px solid #E9E9E9;
    width: 90px;
    margin-top: 8px;
    margin-left: 10px;
}

.layout button{
    display:block;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 3px 6px;
    font-size: 15px;
    width: 110px;
    text-align: left;
    height: 22px;
}

.layout button:focus{
    outline:0;
}

.layout button:hover{
    background:#4168FF;
}

.layout button:hover p {
        color: #FFFFFF;
}

.layout p{
    float: left;
    height: 11px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 11px;
    margin-top: 2px;
    margin-left: 8px;
}

.genericName{
    background-color: transparent;
	border: none;
	margin: 0;
	padding: 3px 6px;
	font-size: 15px;
	width: 140px;
	text-align: left;
    height: 22px;
}

.genericName p{
    float: left;
    height: 11px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 700;
    line-height: 11px;
    margin-top: 8px;
    margin-left: 8px;
    margin-bottom: 4px;
}

select::-ms-expand { 
    display: none; 
}

select {
    border: solid 1px #000;
    appearance:none;
    -moz-appearance:none;
    -webkit-appearance:none;
    padding-right: 0px;
}

.downIcon{
     background-image:url(../assets/img/down.png)
}

.downIcon:hover{
    background-image:url(../assets/img/hover/down.png)
}

.upIcon{
     background-image:url(../assets/img/up.png)
}

.upIcon:hover{
    background-image:url(../assets/img/hover/up.png)
}

.editIcon{
    background-image:url(../assets/img/edit.png)
}

.editIcon:hover{
    background-image:url(../assets/img/hover/edit.png)
}

.disableIcon{
    background-image:url(../assets/img/disable.png)
}

.disableIcon:hover{
    background-image:url(../assets/img/hover/disable.png)
}

.enableIcon{
    background-image:url(../assets/img/enable.png)
}

.enableIcon:hover{
    background-image:url(../assets/img/hover/enable.png)
}
.shareIcon{
    background-image:url(../assets/img/share.png)
}

.shareIcon:hover{
    background-image:url(../assets/img/hover/share.png)
}

.genWordIcon{
    background-image:url(../assets/img/genWord.png)
}

.genWordIcon:hover{
    background-image:url(../assets/img/hover/genWord.png)
}

.refreshIcon{
    background-image:url(../assets/img/refresh.png)
}

.refreshIcon:hover{
    background-image:url(../assets/img/hover/refresh.png)
}

.topicIcon{
    float: right;
    height: 24px;
    width: 24px;
    margin-top: 9px;
    margin-left: 0px;
    margin-right: 8px;
    margin-bottom: 6px;
    cursor: pointer;
    background-size:contain;
    background-repeat: no-repeat;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-position: center;
    z-index: 999999;
}

.enabledBgc{
    opacity: 1;
}

.disabledBgc{
    opacity: 0.7;
}

.mindContainer{
    overflow: hidden;
    position:relative;
}

</style>
